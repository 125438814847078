<div class="d-flex">
    <div class="p-3">
        <p>Last 3 Reports:</p>
        <div class="p-3">
            <p>System #123456<br/> Service Report 06/01/2023 11:05 AM</p>
            <p>System #123468<br/> Service Report 06/03/2023 2:30 PM</p>
            <p>System #123472<br/> Service Report 06/03/2023 4:15 PM</p>
        </div>
    </div>
    <div class="p-3">
        <p>Customer Contacts: </p>
        <div class="p-3">
            <p>Acme Water (555) 555 -8988</p>
            <p>Tundra Schools (459) 888-2238</p>
        </div>
    </div>
    
</div>
<div class="graph-box">
    <app-trends-graph></app-trends-graph>
</div>
    
