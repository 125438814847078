import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListDashboardsRoutingModule } from './list-dashboards-routing.module';
import { ListDashboardsComponent } from './list-dashboards.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { ViewDashboardModule } from '../view-dashboard/view-dashboard.module';

@NgModule({
  declarations: [ListDashboardsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ListDashboardsRoutingModule,
    ViewDashboardModule,
  ],
  exports: [ListDashboardsComponent],
})
export class ListDashboardsModule {}
