const QueryParamsTestValueTypeFull = `full {
    allOperatorLogUsers
    allServiceRepUsers
    highAlarm {
      limit
      message
    }
    highControl {
      limit
      message
    }
    lowAlarm {
      limit
      message
    }
    lowControl {
      limit
      message
    }
    veryHighAlarm {
      limit
      message
    }
    veryLowAlarm {
      limit
      message
    }
  }`;

export { QueryParamsTestValueTypeFull };
