import { gql } from 'apollo-angular';

export const getContentQuery = gql`
  query AppSetting {
    appSetting {
      ... on AppSettings {
        content {
          id
          welcomeContent
        }
      }
      ... on NotFoundError {
        message
        code
      }
      ... on ConflictError {
        message
        code
      }
      ... on NotAllowed {
        message
        code
      }
      ... on InvalidParametersError {
        message
        code
      }
    }
  }
`;

export const getWelcomeContentQuery = gql`
  query Content($accountSettingId: Float!) {
    accountSetting(id: $accountSettingId) {
      ... on AccountSettings {
        appSettings {
          id
          content {
            id
            welcomeContent
          }
        }
      }
    }
  }
`;

export const getAccountSettingsQuery = gql`
  query Query($accountSettingId: Float!) {
    accountSetting(id: $accountSettingId) {
      ... on AccountSettings {
        blacklistedDomains
        emailSubject
        id
        lockout
        marketingEmail
        ordersEmail
        passwordRotation
        supportEmail
        emailSubject
      }
    }
  }
`;

export const getAccountStatisticsQuery = gql`
  query Query($accountId: Int!) {
    account(id: $accountId) {
      appSettings {
        statistics {
          labReports
          operatorLogs
          salesReps
          serviceReports
          systemAdmins
          totalClientUsers
          totalClients
          totalControllers
          totalCustomerUsers
          totalCustomers
          visitReports
        }
        licensing {
          salesReps
          systemAdmins
          totalClients
        }
        customization {
          alertMessage
          alertType
          css
          js
        }
        configuration {
          enableVideo
          enableAliquot
          enableAPI
          defaultLoader
          customerAccountNumber
        }
      }
    }
  }
`;

export const getAccountImagesQuery = gql`
  query AttachmentsByFolder($locationId: Int!, $location: AttachmentLocation!) {
    attachmentsByFolder(locationId: $locationId, location: $location) {
      ... on Attachment {
        description
        extension
        file
        id
        locationId
        locationType
      }
      ... on InvalidParametersError {
        code
        message
      }
      ... on NotAllowed {
        code
        message
      }
      ... on NotFoundError {
        code
        message
      }
    }
  }
`;

export const updateWelcomeContentMutation = gql`
  mutation EditContent($editContentId: Float!, $data: ContentInput!) {
    editContent(id: $editContentId, data: $data) {
      ... on Content {
        welcomeContent
      }
    }
  }
`;
export const updateAccountSettingsMutation = gql`
  mutation UpdateAccountSettings(
    $data: AccountSettingInput!
    $updateAccountSettingsId: Float!
  ) {
    updateAccountSettings(id: $updateAccountSettingsId, data: $data) {
      ... on AccountSettings {
        blacklistedDomains
        emailSubject
        lockout
        marketingEmail
        ordersEmail
        passwordRotation
        supportEmail
      }
    }
  }
`;
export const updateLicensingMutation = gql`
  mutation UpdateLicensing($data: LicensingInput!, $updateLicensingId: Float!) {
    updateLicensing(data: $data, id: $updateLicensingId) {
      ... on Licensing {
        totalClients
        systemAdmins
        salesReps
      }
    }
  }
`;
export const updateCustomizationMutation = gql`
  mutation UpdateCustomization(
    $data: CustomizationInput!
    $updateCustomizationId: Float!
  ) {
    updateCustomization(data: $data, id: $updateCustomizationId) {
      ... on Customization {
        alertMessage
        alertType
        css
        js
      }
    }
  }
`;
export const updateConfigurationMutation = gql`
  mutation Mutation($data: ConfigurationInput!, $updateConfigurationId: Float!) {
    updateConfiguration(data: $data, id: $updateConfigurationId) {
      ... on Configuration {
        enableAPI
        enableAliquot
        enableVideo
        defaultLoader
        customerAccountNumber
      }
    }
  }
`;

export const uploadAttachmentMutation = gql`
  mutation UploadAttachment($data: FileInput!) {
    uploadAttachment(data: $data) {
      ... on Attachment {
        description
        extension
        file
        id
        locationType
        locationId
      }
      ... on InvalidParametersError {
        message
        code
      }
      ... on NotAllowed {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
export const deleteAttachment = gql`
  mutation UploadAttachment($deleteAttachmentId: Int!) {
    deleteAttachment(id: $deleteAttachmentId)
  }
`;
