import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GQLResponse } from 'src/app/core/services/graphql/graphql.apollo.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { System } from 'src/app/features/systems/models/system.model';
import { SystemService } from 'src/app/features/systems/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class EntitySelectService {
  constructor(
    private graphService: GraphqlService,
    private systemService: SystemService
  ) {}

  getSystemAndParents(systemId: string | number): Promise<System | null> {
    return lastValueFrom(
      this.graphService.query<{ system: System }>({
        query: this.systemService.queries.getSystemAndParents,
        variables: {
          systemId: Number(systemId),
        },
      })
    )
      .then((res: GQLResponse<{ system: System }>) => {
        if (res.data && res.data.system) {
          const system = res.data.system as any;
          if (system.code) {
            return null;
          }

          return system as System;
        } else {
          return null;
        }
      })
      .catch((er) => {
        return er;
      });
  }
}
