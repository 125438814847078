import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable, of } from 'rxjs';
import { Dictionary } from 'src/app/core/models/dictionary/dictionary.model';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { AccountStaticsTestData } from 'src/app/data/account-statistics';
import { getAccountImagesQuery } from 'src/app/features/app-settings/graphql/settings.graphql';
import { attachmentByFolder } from 'src/app/features/app-settings/models/account-images.model';
import { AccountSettings, QPAccountSettings } from '../../models/account-settings.model';
import { Account } from '../../models/account.model';
import { APSObject } from 'src/app/types/globals.types';
import { gql } from 'apollo-angular';
import { GQLResponse } from 'src/app/core/services/graphql/graphql.apollo.service';

@Injectable({
  providedIn: 'root',
})
class AccountService {
  appSettings: any = {};
  activeAccountId: number;

  /**
   * @description BehaviorSubject stores AccountSettings
   */
  accountSettings: BehaviorSubject<AccountSettings> = new BehaviorSubject(
    {} as AccountSettings
  );
  companyLogos: BehaviorSubject<Dictionary<any>> = new BehaviorSubject({});

  constructor(
    private graphService: GraphqlService,
    private errorService: ErrorService
  ) {}

  getAccountStatistics(): Observable<GQLResponse<{ statistics: any }>> {
    return of({ data: { statistics: AccountStaticsTestData.statistics } });
  }

  getAccountImages(accountId?: number): Promise<any> {
    if (accountId) {
      this.activeAccountId = accountId;
    }

    const p = lastValueFrom(
      this.graphService
        .query<{ attachmentsByFolder: attachmentByFolder[] }>({
          query: getAccountImagesQuery,
          variables: {
            locationId: this.activeAccountId,
            location: 'ACCOUNT_IMAGES',
          },
        })
        .pipe(
          map((res: GQLResponse<{ attachmentsByFolder: attachmentByFolder[] }>) => {
            if (res.data && res.data.attachmentsByFolder) {
              const accountImages = res.data.attachmentsByFolder;
              const imageObj: APSObject = {};
              if (accountImages && accountImages.length) {
                accountImages.forEach((image: attachmentByFolder) => {
                  const images = accountImages?.filter(
                    (img: attachmentByFolder) => img.description === image.description
                  );
                  // If there are multiple images with the same description, we need to select the most recent one
                  if (images.length > 1) {
                    let selectedImage: attachmentByFolder = images[0];
                    for (let i = 1; i < images.length; i++) {
                      if (images[i].id > selectedImage.id) {
                        selectedImage = images[i];
                      }
                    }
                    imageObj[selectedImage.description] = selectedImage;
                  } else {
                    imageObj[image.description] = image;
                  }
                });
              }

              this.companyLogos.next(imageObj as Dictionary<any>);
              //add to the storage to faster access and avoid multiple calls
              return imageObj;
            } else {
              return null;
            }
          })
        )
    );

    p.catch((error: any) => {
      this.errorService.log(error, true);
    });

    return p;
  }

  getAccountSettings(accountId?: number): Promise<AccountSettings> {
    if (accountId) {
      this.activeAccountId = accountId;
    }

    const p = lastValueFrom(
      this.graphService
        .query<{ account: Account }>({
          query: gql`query AccountSettings($accountId: Int!) {
        account(id: $accountId) {
          ${QPAccountSettings}
          email
          id
          licenses
          name
          status
        }
      }`,
          variables: {
            accountId: this.activeAccountId,
          },
        })
        .pipe(
          map((result: GQLResponse<{ account: Account }>) => {
            let response = {};
            if (result.data && result.data.account && result.data.account.appSettings) {
              const appSettings = result.data.account.appSettings; // Top-Level name is confusing
              this.accountSettings.next(appSettings.accountSettings);
              response = appSettings.accountSettings;
            } else {
              this.errorService.log(result.data || result.error, true);
            }

            return response as AccountSettings;
          })
        )
    );

    p.catch((error: any) => {
      this.errorService.log(error, true);
    });

    return p;
  }
}

export { AccountService };
