<div class="system-nav">
  <div *ngIf="mode === SystemNavModes.Input">
    <form class="input-box d-flex" [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'System Id' | translate }}</mat-label>
        <input
          autocomplete="on"
          (click)="errorState = false"
          matInput
          type="text"
          formControlName="systemInput"
        />
      </mat-form-field>
      <div class="ml-3 mt-3">
        <button
          mat-flat-button
          class="action-button"
          [disabled]="!form.valid"
          type="submit"
        >
          Go
        </button>
      </div>
    </form>
    <div class="history pod mb-2 d-flex flex-column" *ngIf="systemHistory.length">
      <mat-label class="mb-3">Recently Viewed:</mat-label>
      <div class="d-flex flex-row-reverse justify-content-around mt-2">
        <button
          class="mr-1 system-button"
          mat-button
          *ngFor="let item of systemHistory"
          (click)="navigateToSystem(item.id)"
        >
          #{{ item.id }} - {{ item.name | slice:0:50 }}
        </button>
      </div>
    </div>
    <div class="alert alert-danger" *ngIf="errorState">
      <span>This system cannot be loaded. Please try another ID.</span>
    </div>
  </div>
  <div class="favorited" *ngIf="mode === SystemNavModes.FavoriteSelect">
    <mat-form-field>
      <mat-label>{{ 'Select Favorited System' | translate }}:</mat-label>
      <mat-select required (selectionChange)="update($event)" placeholder="None">
        <mat-option *ngFor="let item of favorites" [value]="item">
          <span class="d-flex justify-content-between align-items-center">
            <span>{{ item.name | snakeToTitleCase | titlecase }}</span>
            <div class="favorite-action-button">
              <button
                class="entity-button"
                matTooltip="View"
                mat-icon-button
                (click)="viewFavoriteSystem(item.id); $event.stopPropagation()"
              >
                <i class="fa fa-duotone fa-arrow-right-from-bracket fa-lg"></i>
              </button>
              <button
                class="entity-button"
                matTooltip="Remove from favorites"
                mat-icon-button
                (click)="removeFavorite(item.id); $event.stopPropagation()"
              >
                <i class="fa fa-trash fa-lg"></i>
              </button>
            </div>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
