<div>
  <button
    mat-icon-button
    class="notifications-icon-button"
    [matMenuTriggerFor]="notifications"
  >
    <mat-icon matBadge="{{ (messages$ | async)?.messages?.length || '' }}"
      >notifications</mat-icon
    >
  </button>
</div>
<mat-menu
  #notifications="matMenu"
  class="notifications-menu"
  [ngClass]="{ 'has-notifications': (messages$ | async)?.messages?.length || 0 > 0 }"
>
  <div
    mat-menu-item
    [routerLink]="['notifications']"
    *ngFor="let message of (messages$ | async)?.messages"
  >
    <span>{{ message.name }}</span>
  </div>
</mat-menu>
