import { Directive } from '@angular/core';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { System } from '../../features/systems/models/system.model';
import { AppService } from '../services/app/app.service';

@Directive({
  selector: '[system-node]',
})
class SystemNode {
  constructor(
    private component: BreadcrumbsComponent,
    private appService: AppService
  ) {
    this.appService.viewState.system.subscribe((system: System | null) => {
      if (system) {
        // Add System Breadcrumb
        this.component.defaultNodes.unshift({
          label: system.name,
          path: '/app/system',
        });
      }
    });
  }
}

export { SystemNode };
