import { InjectionToken } from '@angular/core';
// @ts-ignore
export type APSObject = Record<string, any> | any | undefined;
export const APS_OBJECT_TOKEN = new InjectionToken('APS_OBJECT_TOKEN');

export function apsObjectFactory(): APSObject {
  return {};
}

export interface CustomThemes {
  primary: string;
  secondary: string;
}
