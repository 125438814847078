import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AppService, StorageKeyToken } from 'src/app/core/services/app/app.service';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { lastValueFrom } from 'rxjs';
import { DomainValidation, defaultThemeObject } from 'src/app/gql/auth/domain.queries';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  httpClient: HttpClient;

  buttonDisable = false;
  defaultTheme: DomainValidation;
  isLoggedIn = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    const loginStatus = this.authService.quickCheckStatus();
    if (loginStatus.hasSession && loginStatus.userFound && loginStatus.user) {
      const token = localStorage.getItem(StorageKeyToken);
      if (token) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    } else {
      this.isLoggedIn = false;
    }
    try {
      this.defaultTheme = JSON.parse(
        localStorage.getItem('aps-theme') || '{}'
      ) as DomainValidation;
      if (!this.defaultTheme.themes) {
        this.defaultTheme = defaultThemeObject;
      }
    } catch {
      this.defaultTheme = defaultThemeObject;
    }
  }

  ngOnInit(): void {
    this.appService.sidenavToggle.emit(false);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loginForm.markAsDirty();
    }, 300);
  }

  login() {
    const vars = this.loginForm.getRawValue();

    this.errorService.clear();
    this.buttonDisable = true;
    lastValueFrom(this.authService.getLogin(vars))
      .then(() => {
        this.buttonDisable = false;
      })
      .catch((e) => {
        this.buttonDisable = false;
        this.errorService.log(e);
      });
  }
}
