import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AppService } from '../app/app.service';
import { ErrorService } from '../error/error.service';
import {
  ApolloService,
  GQLDocument,
  GQLRequestOptions,
  GQLResponse,
  GQLVariables,
} from './graphql.apollo.service';

export interface GraphResponse<T> {
  data: T;
  errors?: any;
  error?: any;
}

export interface GraphRequestOptions {
  query?: string;
  mutation?: string;
  variables?: { [key: string]: any };
  isPublic?: boolean;
}

export type FetchQueryFunction = <T>(
  query: GQLDocument,
  variables?: GQLVariables
) => Promise<T>;

@Injectable({ providedIn: 'root' })
export class GraphqlService {
  constructor(
    // @ts-ignore
    private http: HttpClient,
    // @ts-ignore
    private appService: AppService,
    // @ts-ignore
    private errorService: ErrorService,
    private apolloService: ApolloService
  ) {}

  public fetchQuery<T>(query: GQLDocument, variables?: GQLVariables): Promise<T> {
    return this.apolloService.fetchQuery(query, variables);
  }

  public fetchMutation<T>(mutation: GQLDocument, variables?: GQLVariables): Promise<T> {
    return this.apolloService.fetchMutation(mutation, variables);
  }

  public query<T>(options: GQLRequestOptions): Observable<GQLResponse<T>> {
    // console.log('QUERY OPTIONS', options);
    return this.apolloService.query(options);
  }

  public mutate<T>(options: GQLRequestOptions): Observable<GQLResponse<T>> {
    // console.log('MUTATE OPTIONS', options);
    return this.apolloService.mutate(options);
  }
}
