import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { AppService } from 'src/app/core/services/app/app.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { ClientService } from 'src/app/feature-modules/clients/services/client.service';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { CustomerService } from 'src/app/feature-modules/customers/services/customer.service';
import { Building } from '../../features/buildings/models/building.model';
import { BuildingService } from '../../features/buildings/services/building.service';
import { Facility } from '../../features/facilities/models/facility.model';
import { FacilityService } from '../../features/facilities/services/facility.service';
import { System } from '../../features/systems/models/system.model';
import { SystemService } from '../../features/systems/services/system.service';
import { EntitySubjects } from '../entity-select/models/entity-subjects.model';
import { FilterByEntityService } from './services/filter-by-entity.service';
import { APSObject } from 'src/app/types/globals.types';
import { GQLResponse } from 'src/app/core/services/graphql/graphql.apollo.service';
import { sortArray } from 'src/app/helpers/string.helpers';
import { Router } from '@angular/router';

interface EntitySelectFieldControl {
  client: boolean;
  customer: boolean;
  facility: boolean;
  building: boolean;
  system: boolean;
}

export const StorageKeyFilterEntities = 'APS-Filter-Entities';
// -----------

@Component({
  selector: 'app-filter-by-entity',
  templateUrl: './filter-by-entity.component.html',
  styleUrls: ['./filter-by-entity.component.scss'],
})
export class FilterByEntityComponent implements OnInit {
  @Output()
  onEntityChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onCustomerChange: EventEmitter<any> = new EventEmitter();

  /** TODO - deprecate ?  */
  fieldControl: EntitySelectFieldControl = {
    client: true,
    customer: true,
    facility: true,
    building: true,
    system: true,
  };

  selections: EntitySubjects;

  dataOptions = {
    client: <EntityOption[]>[],
    customer: <EntityOption[]>[],
    facility: <EntityOption[]>[],
    building: <EntityOption[]>[],
    system: <EntityOption[]>[],
  };

  // User Type control
  preset = {
    client: false,
    customer: false,
  };

  errors: any[] = [];

  // Dynamic labels
  labels = {
    initial: 'labels.client',
    initialSelect: 'actions.select-client',
  };

  subs: Subscription[] = [];

  currentURL: string = "";

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private clientService: ClientService,
    private customerService: CustomerService,
    private facilityService: FacilityService,
    private buildingService: BuildingService,
    private systemService: SystemService,
    private graphService: GraphqlService,
    private filterByEntityService: FilterByEntityService,
    private router: Router
  ) {
    this.selections = this.filterByEntityService.entitySelections;
  }

  ngOnInit(): void {
    this.checkUserAccess();
    this.currentURL = this.router.url;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  async checkUserAccess(): Promise<void> {
    const authStatus = this.authService.status.getValue();
    const user = authStatus.user;
    const permissions = user?.permission;
    const hasCustomerAccess = permissions?.administration.customerUsersAccess !== 'NONE';
    const hasClientAccess = permissions?.administration.clientUsersAccess !== 'NONE';
    if (!hasCustomerAccess) {
      this.fieldControl.customer = false;
    }
    if (!hasClientAccess) {
      this.fieldControl.client = false;
    }

    if (user) {
      if (user.customer?.id && hasCustomerAccess) {
        this.preset.client = true;
        this.preset.customer = true;
        await this.setUserClient(authStatus);
        await this.setUserCustomer(authStatus);
      } else if (user.client?.id && hasClientAccess) {
        this.preset.client = true;
        await this.setUserClient(authStatus);
      } else {
        await this.loadClients();
      }
    } else {
      this.resetClient();
    }
  }

  async checkCookiesSelection(): Promise<void> {
    const selections = JSON.parse(localStorage.getItem(StorageKeyFilterEntities) || '{}');

    if (selections) {
      if (selections.customer) {
        try {
          const customer: Customer = await this.customerService.getCustomerWithEntities(selections.customer);
          if (customer) {
            const client = customer.client as Client;
            if (client) {
              await this.onClientSelect(client);
              await this.onCustomerSelect(customer);
            }
          }
        } catch (error) {
          this.errors.push(error);
        }
      } else if (selections.client) {
        await this.loadClient(selections.client);
      }
    }
  }

  // Allow external components to reset the component values via AppService
  enableResetTrigger() {
    const sub1 = this.appService.quickNavReset
      .asObservable()
      .subscribe((keys: string[]) => {
        this.reset(keys);
      });
    this.subs.push(sub1);
  }

  saveFilterEntities(): void {
    const values: APSObject = {};
    Object.keys(this.selections).map((key: string) => {
      values[key] = this.selections[key].getValue()?.id;
    });
    localStorage.setItem(StorageKeyFilterEntities, JSON.stringify(values));
  }

  async loadClients(): Promise<void> {
    try {
      const clients = await this.clientService.getClients();
      this.dataOptions.client = sortArray(clients, 'name', 'asc');

      let selectedClient = this.selections.client.getValue();
      const clientIsExistInCurrentAccount = clients.find((client) => client.id === selectedClient?.id);
      if (!clientIsExistInCurrentAccount) {
        this.selections.client.next(null);
        this.selections.customer.next(null);
      }
      selectedClient = this.selections.client.getValue();
      this.dataOptions.customer = sortArray(this.selections.client?.getValue()?.customers || [], 'name', 'asc');
      this.onCustomerSelect(this.selections.customer?.getValue());
      if (selectedClient || this.dataOptions.client.length === 1) {
        this.onClientSelect(selectedClient || this.dataOptions.client[0]);
      }
      await this.checkCookiesSelection();
    } catch (error) {
      this.errors.push(error);
    }
  }

  async setUserClient(status: AuthStatus): Promise<void> {
    const id = status.user?.client?.id;
    if (id) {
      await this.loadClients();
      await this.checkCookiesSelection();
    }
  }

  async setUserCustomer(status: AuthStatus): Promise<void> {
    const id = status.user?.customer?.id;
    if (id) {
      await this.loadCustomer(id);
      await this.loadFacilities(id);
      await this.checkCookiesSelection();

      // customer user has no access to client users
      this.fieldControl.client = false;
    }
  }

  resetFieldControls(): void {
    this.fieldControl = {
      client: true,
      customer: true,
      facility: true,
      building: true,
      system: true,
    };
  }

  /* --- Data Loading functions --- */

  async loadClient(id: number): Promise<void> {
    try {
      const client: Client = await this.clientService.getClientWithEntities(id);
      this.dataOptions.customer = sortArray(client.customers || [], 'name', 'asc');
      if (client.id !== this.selections.client.getValue()?.id) {
        this.selections.client.next(client);
        let selectedCustomer = this.selections.customer.getValue();
        const customerIsExistInCurrentAccount = client.customers?.find((customer) => customer.id === selectedCustomer?.id);
        if (!customerIsExistInCurrentAccount) {
          this.selections.customer.next(null);
        }
        selectedCustomer = this.selections.customer.getValue();
        if (this.dataOptions.customer.length === 1 || selectedCustomer) {
          await this.loadCustomer((selectedCustomer?.id as number) || this.dataOptions.customer[0].id);
        }
        this.saveFilterEntities();
      }
    } catch (error: any) {
      this.errors.push(error);
    }
  }

  async loadCustomer(id: number): Promise<void> {
    try {
      const customer: Customer = await this.customerService.getCustomerWithFacilities(id);

      if (customer) {
        customer.facilities = sortArray(customer.facilities || [], 'name', 'asc');
        if (customer.id !== this.selections.customer.getValue()?.id) {
          this.selections.customer.next(customer);
          this.saveFilterEntities();
        }
      }
    } catch (error) {
      this.errors.push(error);
    }
  }

  async loadFacilities(customerId: number): Promise<void> {
    try {
      const res: GQLResponse<{ facilitys: Facility[] }> = await lastValueFrom(
        this.graphService.query<{ facilitys: Facility[] }>({
          query: this.facilityService.queries.list,
          variables: { customerId, idList: [] },
        })
      );
      this.dataOptions.facility = sortArray(res.data.facilitys, 'name', 'asc');
    } catch (error) {
      this.errors.push(error);
    }
  }

  async loadBuildings(facilityId: number): Promise<void> {
    try {
      const res: GQLResponse<{ buildings: APSObject }> = await lastValueFrom(
        this.graphService.query<{ buildings: any[] }>({
          query: this.buildingService.queries.list,
          variables: { facilityId, idList: [] },
        })
      );
      this.dataOptions.building = sortArray(res.data.buildings || [], 'name', 'asc');
    } catch (error) {
      this.errors.push(error);
    }
  }

  async loadSystems(buildingId: number): Promise<void> {
    try {
      const res: GQLResponse<{ systems: System[] }> = await lastValueFrom(
        this.graphService.query<{ systems: System[] }>({
          query: this.systemService.queries.list,
          variables: { buildingId, idList: [] },
        })
      );
      this.dataOptions.system = sortArray(res.data.systems || [], 'name', 'asc');

      if (this.dataOptions.system.length === 1 && this.fieldControl.system) {
        this.onSystemSelect(this.dataOptions.system[0]);
      }
    } catch (error) {
      this.errors.push(error);
    }
  }

  /* --- Dropdown Select Handlers --- */

  async onClientSelect(value: EntityOption | null): Promise<void> {
    if (!value) {
      this.resetClient();
      return;
    }
    // const current = this.appService.subjects.client.getValue();
    const current = this.selections.client.getValue();
    if (current || this.dataOptions.client.length === 1) {
      this.reset([
        'customer',
        'facility',
        'building',
        'system',
      ]);
    }
    await this.loadClient(value.id);
  }

  async onCustomerSelect(value: EntityOption | null): Promise<void> {
    if (!value) {
      this.resetCustomer();
      return;
    }

    const current = this.appService.subjects.customer.getValue();
    if (current) {
      this.reset(['facility', 'building', 'system']);
    }
    await this.loadCustomer(value.id);
  }

  onFacilitySelect(value: Facility | null): void {
    if (!value) {
      this.resetFacility();
      return;
    }
    const current = this.appService.subjects.facility.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['building', 'system']);
    }
    this.appService.setFacility(value as Facility);
    this.saveFilterEntities();
    this.loadBuildings(value.id);
  }

  onBuildingSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetBuilding();
      return;
    }

    const current = this.appService.subjects.building.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['system']);
    }
    this.appService.setBuilding(value as Building);
    this.saveFilterEntities();
    this.loadSystems(value.id);
  }

  onSystemSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetSystem();
    } else {
      this.appService.setSystem(value as System);
      this.saveFilterEntities();
    }
  }

  /* --- State Control functions --- */

  reset(keys: string[]) {
    keys.forEach((key) => {
      this.selections[key]?.next(null);
    });
    this.onEntityChange.emit(keys);
    this.saveFilterEntities();
  }

  resetClient() {
    this.reset([
      'client',
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetCustomer() {
    this.reset([
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetFacility() {
    this.reset(['facility', 'building', 'system']);
  }

  resetBuilding() {
    this.reset(['building', 'system']);
  }

  resetSystem() {
    this.reset(['system']);
  }

}
