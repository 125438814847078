import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from 'src/app/core/services/error/error.service';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnInit {
  errors$: Observable<any[]>;

  constructor(private errorService: ErrorService) {
    this.errors$ = this.errorService.allErrors$;
  }

  ngOnInit(): void {}
}
