import { Directive, TemplateRef, ViewChild } from '@angular/core';

@Directive({
  selector: '[viewMode]',
})
export class ViewModeDirective {
  @ViewChild('viewMode') tpl: TemplateRef<any>;

  constructor() {}
}
