<div id="main_loading_screen" class="main-loading-screen">
  <div class="main-loading-wrapper">
    <div class="main-loading-caption">
      <svg
        class="loading"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 574.558 120"
        enable-background="new 0 0 574.558 120"
        xml:space="preserve"
      >
        <defs>
          <pattern
            id="water"
            width=".25"
            height="1.1"
            patternContentUnits="objectBoundingBox"
          >
            <path
              fill="#fff"
              d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,1z"
            />
          </pattern>

          <text
            id="text"
            transform="matrix(1 0 0 1 -8.0684 116.7852)"
            font-size="161.047"
          >
            Aliquot
          </text>

          <mask id="text_mask">
            <use x="0" y="0" xlink:href="#text" opacity="1" />
          </mask>
        </defs>

        <use x="0" y="0" xlink:href="#text" />

        <rect
          class="water-fill"
          mask="url(#text_mask)"
          fill="url(#water)"
          x="-400"
          y="0"
          width="1600"
          height="120"
        />
      </svg>
    </div>
  </div>
</div>

<mat-sidenav-container>
  <mat-sidenav
    mode="side"
    position="start"
    [(opened)]="menuToggle"
    [ngClass]="[
      menuMinimize ? 'closed' : 'opened',
      menuExpand ? 'expanded' : '',
      'deep-gradient'
    ]"
  >
    <div class="logo-container mr-md-auto">
      <img *ngIf="logoPath" [src]="logoPath" class="logo" alt="Company Logo" />
    </div>

    <app-sidenav
      (onMenuToggle)="onMenuToggle($event)"
      (onMenuExpand)="onMenuExpand($event)"
    ></app-sidenav>
  </mat-sidenav>
  <div
    *ngIf="loggedIn"
    class="topnav container-fluid"
    [ngClass]="{ 'not-logged-in': !loggedIn }"
  ></div>
  <mat-sidenav-content
    [ngClass]="[
      menuToggle && !menuMinimize ? 'opened' : 'closed',
      hasMenu ? 'hasMenu' : ''
    ]"
  >
    <div class="custom-overlay" *ngIf="menuExpand" (click)="menuExpand = false"></div>
    <div
      cdkScrollable
      class="main container-fluid"
      [ngClass]="{ 'not-logged-in': !loggedIn }"
    >
      <app-nav *ngIf="loggedIn"></app-nav>
      <!-- Entity Select -->
      <div class="row aps-sticky-top" [hidden]="!showEntitySelect">
        <div class="col-sm">
          <app-entity-select></app-entity-select>
        </div>
      </div>
      <div
        id="customAlert"
        *ngIf="selectedTheme != 0 && curPage == '/\login' && alertText.trim() != ''"
        [ngClass]="['alert', 'alert-dismissable', 'alert-' + selectedTheme]"
      >
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div [innerHTML]="alertText"></div>
      </div>
      <div class="aps-body" [ngClass]="{ 'not-logged-in': !loggedIn }">
        <div class="col-sm main-outlet" [ngClass]="{ 'not-logged-in': !loggedIn }">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
