import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { User } from '../../users/models/user.model';
import { APSObject, APS_OBJECT_TOKEN } from 'src/app/types/globals.types';
import { gql } from 'apollo-angular';
import {
  GQLDocument,
  GQLResponse,
} from 'src/app/core/services/graphql/graphql.apollo.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  customers: BehaviorSubject<Customer[]> = new BehaviorSubject([] as Customer[]);
  customers$ = this.customers.asObservable();

  queries: {
    getCustomers: GQLDocument;
    getCustomer: GQLDocument;
    getCustomerAndFacilities: GQLDocument;
    getCustomerWithEntities: GQLDocument;
  } = {
    getCustomers: gql`
      query Customers($idList: [Int!]!) {
        customers(idList: $idList) {
          ... on Customer {
            id
            name
          }
          ... on NotFoundError {
            message
            code
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
        }
      }
    `,
    getCustomer: gql`
      query Customer($customerId: Int!) {
        customer(id: $customerId) {
          ... on Customer {
            active
            accountNumber
            address {
              city
              id
              region
              street1
              street2
              street3
              street4
              unit
              zipCode
              country
            }
            addressFormat
            client {
              id
              name
              address {
                id
                region
                street1
                street2
                country
                city
                zipCode
              }
              timezone {
                id
              }
              legalStatement
              contactInfo {
                phoneFormat
                phoneNumber
              }
            }
            contactInfo {
              fax
              faxExt
              phoneFormat
              phoneNumber
              phoneNumberExt
            }
            id
            lat
            lon
            name
            prospect
            showCountryOnRep
            tag1
            tag2
            tag3
            timezone {
              id
              name
              offset
              abbreviation
            }
          }
        }
      }
    `,
    getCustomerAndFacilities: gql`
      query Customer($customerId: Int!) {
        customer(id: $customerId) {
          ... on Customer {
            active
            accountNumber
            address {
              city
              id
              region
              street1
              street2
              street3
              street4
              unit
              zipCode
            }
            addressFormat
            facilities {
              id
              name
              createdBy
            }
            contactInfo {
              fax
              faxExt
              phoneFormat
              phoneNumber
              phoneNumberExt
            }
            id
            name
            timezone {
              id
              name
              offset
              abbreviation
            }
          }
        }
      }
    `,
    getCustomerWithEntities: gql`
      query Customer($customerId: Int!) {
        customer(id: $customerId) {
          ... on Customer {
            id
            name
            client {
              id
              name
            }
            facilities {
              id
              name
              buildings {
                id
                name
              }
            }
          }
          ... on NotFoundError {
            message
            code
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
        }
      }
    `,
  };

  mutations: any = {
    createCustomer: gql`
      mutation CreateCustomer($data: CustomerInput!, $addressData: AddressInput) {
        manageCustomer(data: $data, addressData: $addressData) {
          ... on Customer {
            id
            name
          }
          ... on NotFoundError {
            code
            message
          }
          ... on NotAllowed {
            code
            message
          }
          ... on InvalidParametersError {
            code
            message
          }
          ... on ConflictError {
            message
            code
          }
        }
      }
    `,
    manageCustomer: gql`
      mutation ManageCustomer(
        $data: CustomerInput!
        $addressData: AddressInput
        $manageCustomerId: Float
      ) {
        manageCustomer(data: $data, addressData: $addressData, id: $manageCustomerId) {
          ... on Customer {
            id
            name
          }
          ... on NotFoundError {
            code
            message
          }
          ... on NotAllowed {
            code
            message
          }
          ... on InvalidParametersError {
            code
            message
          }
        }
      }
    `,
  };

  constructor(
    //@ts-ignore
    @Inject(APS_OBJECT_TOKEN) private REMOVE_AFTER_REFACTOR: APSObject,
    private graphService: GraphqlService
  ) {}

  getCustomers(idList: number[]) {
    return lastValueFrom(
      this.graphService
        .query<{ customers: Customer[] }>({
          query: this.queries.getCustomers,
          variables: {
            idList: idList,
          },
        })
        .pipe(
          map((res: GQLResponse<{ customers: Customer[] }>) => {
            let ret: any;
            if (res.data && res.data.customers) {
              ret = res.data.customers;
            }
            return ret;
          })
        )
    );
  }

  getCustomerWithFacilities(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomerAndFacilities,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ customer: Customer }>) => {
            let ret: any = {};
            if (res.data && res.data.customer) {
              ret = res.data.customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomer(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomer,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ customer: Customer }>) => {
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.customer) {
              ret = res.data.customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomerWithEntities(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomerWithEntities,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ customer: Customer }>) => {
            let ret: any;
            if (res.data && res.data.customer) {
              const customer = res.data.customer;
              ret = customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomerAccess(id: number): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<any>({
          query: gql`
            query Query($customerId: Float!) {
              customerAccess(customerId: $customerId) {
                ... on User {
                  id
                  firstName
                  lastName
                  status
                }
              }
            }
          `,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ customerAccess: User[] }>) => {
            let ret: any;
            if (res.data && res.data.customerAccess) {
              ret = res.data.customerAccess;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteCustomer: any }>({
          mutation: gql`
            mutation DeleteCustomer($deleteCustomerId: Float!) {
              deleteCustomer(id: $deleteCustomerId)
            }
          `,
          variables: {
            deleteCustomerId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ deleteCustomer: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteCustomer) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
