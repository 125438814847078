enum ShortDateFormat {
  'M_D_YYYY' = 'M_D_YYYY',
  'D_M_YYYY' = 'D_M_YYYY',
  'YYYY_M_D' = 'YYYY_M_D',
}

enum LongDateFormat {
  'MONTH_DAY_COMMA_YEAR' = 'MONTH_DAY_COMMA_YEAR',
  'MONTH_DAY_YEAR' = 'MONTH_DAY_YEAR',
  'DAY_MONTH_YEAR' = 'DAY_MONTH_YEAR',
  'YEAR_MONTH_DAY' = 'YEAR_MONTH_DAY',
}

export { ShortDateFormat, LongDateFormat };
