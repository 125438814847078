import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { TrendsGraphModule } from './trends-graph/trends-graph.module';
import { InventoryGraphModule } from './inventory-graph/inventory-graph.module';
import {
  FusionKey,
  FusionPath,
} from 'src/app/core/constants/fusion-key/fusion-key.constant';

FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TrendsGraphModule,
    InventoryGraphModule,
    FusionChartsModule,
  ],
  //exports:[ FusionChartsModule ]
})
export class GraphsModule {
  constructor() {
    FusionCharts.options.scriptBaseUri = FusionPath;
    //FusionCharts.addDep(Line2d);
    const o = FusionCharts.options as any;
    o.license({
      key: FusionKey,
      creditLabel: false,
    });
  }
}
