import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { environment } from 'src/environments/environment';
import { StorageKeyToken } from '../app/app.service';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { cachePolicy } from './apollo.cache.policies';
import { APSObject } from 'src/app/types/globals.types';

loadDevMessages();
loadErrorMessages();

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const authLink = setContext((_, { headers, ...context }) => {
          const token = localStorage.getItem(StorageKeyToken);
          const isPublic = context['isPublic'] || false;
          const authHeaders = isPublic
            ? {}
            : {
                Authorization: token ? `Bearer ${token}` : '',
              };

          return {
            headers: {
              ...headers,
              ...authHeaders,
              'X-Apollo-Request': 'true',
            },
          };
        });

        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        // Because the current system is using dynamic assertion in GQL queries we have
        // to filter out some __typename stuff that is normally handled by apollo
        // we will need to revist what is happening with these queries/mutations and remove this
        // logic.

        const removeTypenamesFromVariables = (obj: any): any => {
          if (obj === null || obj === undefined) {
            return obj;
          }

          if (Array.isArray(obj)) {
            return obj.map(removeTypenamesFromVariables);
          } else if (typeof obj === 'object') {
            if (obj.constructor === Object) {
              const newObj: APSObject = {};
              for (const key of Object.keys(obj)) {
                if (key !== '__typename') {
                  newObj[key] = removeTypenamesFromVariables(obj[key]);
                }
              }
              return newObj;
            }
          }

          return obj;
        };

        const cleanTypenameLink = new ApolloLink((operation, forward) => {
          if (
            operation.query.definitions.some(
              (def) => def.kind === 'OperationDefinition' && def.operation === 'mutation'
            )
          ) {
            operation.variables = removeTypenamesFromVariables(operation.variables);
          }
          return forward(operation);
        });

        const redirectIfTokenNullLink = new ApolloLink((operation, forward) => {
          const token = localStorage.getItem(StorageKeyToken);
          const isLoggedIn = localStorage.getItem('is-logged-in');
          // Check if token is expired
          const base64Url = token && token.split('.')[1];
          const base64 = base64Url && base64Url.replace('-', '+').replace('_', '/');
          const expDate = base64 && JSON.parse(window.atob(base64)).exp;
          const isExpired = Math.floor(new Date().getTime() / 1000) >= expDate;
          if (isLoggedIn && (!token || isExpired)) {
            const event = new CustomEvent('user:session:expired', {
              detail: {
                message: 'Session Expired',
              },
            });
            document.dispatchEvent(event);
          }
          return forward(operation);
        });

        const link = ApolloLink.from([
          errorLink,
          cleanTypenameLink,
          redirectIfTokenNullLink,
          authLink,
          httpLink.create({ uri: `${environment.apiBase}/graphql` }),
        ]);

        return {
          cache: new InMemoryCache({
            ...cachePolicy,
          }),
          link,
          connectToDevTools: true,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
            mutate: {
              fetchPolicy: 'no-cache',
            },
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphApolloModule {}
