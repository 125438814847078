import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  @Output() filesDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') background = '#ffffff';

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#e2eefd';
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#ffffff';
  }

  // Drop Event
  @HostListener('drop', ['$event']) public drop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#ffffff';
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }

  constructor() {}
}
