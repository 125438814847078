import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { EntitySubjects } from 'src/app/components/entity-select/models/entity-subjects.model';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import {
  User,
  UserType,
  UserStatus,
} from 'src/app/feature-modules/users/models/user.model';
import { Building } from '../../../features/buildings/models/building.model';
import { Facility } from '../../../features/facilities/models/facility.model';
import { System } from '../../../features/systems/models/system.model';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { gql } from 'apollo-angular';
import { GQLResponse } from 'src/app/core/services/graphql/graphql.apollo.service';

export type UserQueryOptions = {
  customer?: Customer | null;
  client?: Client | null;
  userId: number;
};

export const QueryParamsUser = `
  id
  firstName
  lastName
  mi
  email
  type
  status
  phoneNumber
  passwordResetRequired
  language
  permission {
    id
    name
  }
  phoneNumber
  phoneFormat
  startPage
  closingSigImage
  closingSigStr
`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  filterSelections: EntitySubjects = {
    client: new BehaviorSubject<Client | null>(null),
    customer: new BehaviorSubject<Customer | null>(null),
    facility: new BehaviorSubject<Facility | null>(null),
    building: new BehaviorSubject<Building | null>(null),
    system: new BehaviorSubject<System | null>(null),
  };

  queries = {
    usersList: gql`
      query UserQuery($all: Boolean) {
        users(all: $all) {
          ... on User {
            client {
              id
            }
            id
            firstName
            lastName
            locked
            language
            mi
            email
            phoneNumber
            type
            status
            permission {
              id
              name
            }
          }
        }
      }
    `,

    getUsers: gql`
      query Users($all: Boolean) {
        users(all: $all) {
          ... on User {
            id
            type
            firstName
            lastName
            email
            client {
              id
              name
            }
            customer {
              id
              name
            }
            userAccess {
              accessAll
              buildingIds
              customerIds
              facilityIds
            }
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
    getUserAccess: gql`
      query UserAccess($userId: Float!) {
        userAccess(userId: $userId) {
          ... on UserAccess {
            accessAll
            buildingIds
            customerIds
            facilityIds
          }
          ... on NotFoundError {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    filterUsers: gql`
      query FilterUsers($customerId: Float, $clientId: Float) {
        filterUsers(customerId: $customerId, clientId: $clientId) {
          ... on OperationSuccess {
            success
          }
          ... on User {
            id
            firstName
            email
            lastName
            locked
            status
            phoneNumber
            permission {
              id
              name
            }
            type
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
  };

  mutations = {
    createUser: gql`
      mutation CreateUser($data: CreateUserInput!) {
        createUser(data: $data) {
          ... on OperationSuccess {
            success
          }
          ... on User {
            id
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
    editPassword: gql`
      mutation EditPassword($password: String!, $editPasswordId: Float!) {
        editPassword(password: $password, id: $editPasswordId) {
          ... on OperationSuccess {
            success
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
    forgotPassword: gql`
      mutation Mutation($email: String!) {
        forgotPassword(email: $email) {
          ... on User {
            email
          }
          ... on OperationSuccess {
            success
          }
        }
      }
    `,
    deactivate: gql`
      mutation EditUser($data: CreateUserInput!, $editUserId: Float!) {
        editUser(data: $data, id: $editUserId) {
          ... on OperationSuccess {
            message
            success
          }
          ... on NotFoundError {
            code
          }
          ... on ForbiddenError {
            code
          }
          ... on InvalidParametersError {
            code
          }
          ... on ConflictError {
            code
          }
          ... on UnprocessableEntityError {
            code
          }
          ... on User {
            id
            status
          }
        }
      }
    `,
    manageUser: gql`
      mutation EditUser(
        $data: CreateUserInput!
        $editUserId: Float!
        $closingSigImage: FileInput
      ) {
        editUser(data: $data, id: $editUserId, closingSigImage: $closingSigImage) {
          ... on User {
            id
            status
            closingSigImage
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
    resetPassword: gql`
      mutation Mutation($newPassword: String!, $currentPassword: String!) {
        resetPassword(newPassword: $newPassword, currentPassword: $currentPassword) {
          ... on OperationSuccess {
            success
          }
          ... on InvalidParametersError {
            code
            message
          }
          ... on ForbiddenError {
            code
            message
          }
          ... on NotFoundError {
            code
            message
          }
          ... on ConflictError {
            code
            message
          }
          ... on UnprocessableEntityError {
            code
            message
          }
        }
      }
    `,
    editUserAccess: gql`
      mutation EditSingleUserAccess($userId: Float!, $data: UserAccessInput!) {
        editSingleUserAccess(userId: $userId, data: $data) {
          ... on UserAccess {
            accessAll
            buildingIds
            customerIds
            facilityIds
            id
          }
          ... on NotFoundError {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    editMultipleUserAccess: gql`
      mutation EditMultipleUserAccess(
        $addAccessList: [Int!]
        $removeAccessList: [Int!]
        $buildingId: Float
        $facilityId: Float
        $customerId: Float
      ) {
        editMultipleUserAccess(
          addAccessList: $addAccessList
          removeAccessList: $removeAccessList
          buildingId: $buildingId
          facilityId: $facilityId
          customerId: $customerId
        ) {
          ... on UserAccess {
            id
          }
          ... on NotFoundError {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    unlockUser: gql`
      mutation Mutation($unlockUserId: Float!) {
        unlockUser(id: $unlockUserId) {
          ... on OperationSuccess {
            success
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on ForbiddenError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on UnprocessableEntityError {
            message
            code
          }
        }
      }
    `,
    deleteUser: gql`
      mutation Mutation($deleteUserId: Float!) {
        deleteUser(id: $deleteUserId)
      }
    `,
    deleteSignature: gql`
      mutation DeleteSignature {
        deleteSignature {
          closingSigImage
        }
      }
    `,
  };

  constructor(
    private graphService: GraphqlService,
    private errorService: ErrorService
  ) {}

  generateUserQuery(opts: UserQueryOptions) {
    let queryStart = `query User($userId: Int!) {
      user(id: $userId) {
        ... on User {
          ${QueryParamsUser}
        `;
    let queryEnd = `}
      }
    }`;
    if (opts.client && opts.client.id) {
      queryStart += `
      client {
        id
        name
      }
      `;
    }

    if (opts.customer && opts.customer.id) {
      queryStart += `
      customer {
        id
        name
      }
      `;
    }

    return gql`
      ${queryStart}
      ${queryEnd}
    `;
  }

  getUser(userId: number): Promise<User> {
    return lastValueFrom(
      this.graphService
        .query<{ user: User }>({
          query: gql`query User($userId: Int!) {
            user(id: $userId) {
              ... on User {
                ${QueryParamsUser}
              }
            }
          }`,
          variables: {
            userId: userId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ user: User }>) => {
            let ret = {} as User;
            if (res.errors) {
              //throw res.errors;
              this.errorService.log(res.errors, true);
            }
            if (res.data && res.data.user) {
              ret = res.data.user;
            }
            return ret;
          })
        )
    );
  }

  getUserDynamic(opts: UserQueryOptions): Promise<User> {
    const query = this.generateUserQuery(opts);
    return lastValueFrom(
      this.graphService
        .query<{ user: User }>({
          query: query,
          variables: {
            userId: opts.userId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ user: User }>) => {
            let ret = {} as User;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.user) {
              ret = res.data.user;
            }
            return ret;
          })
        )
    );
  }

  getUsers(all: boolean): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<{ users: User[] }>({
          query: this.queries.getUsers,
          variables: {
            all,
          },
        })
        .pipe(
          map((res: GQLResponse<{ users: User[] }>) => {
            let ret = [] as User[];
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.users) {
              ret = res.data.users;
            }
            return ret;
          })
        )
    );
  }

  getClientUsers(clientId: number): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<{ filterUsers: User[] }>({
          query: this.queries.filterUsers,
          variables: {
            customerId: null,
            clientId: clientId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ filterUsers: User[] }>) => {
            let ret = [] as User[];
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.filterUsers) {
              ret = res.data.filterUsers;
            }
            return ret;
          })
        )
    );
  }

  getCustomerUsers(customerId: number, clientId: number): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<{ filterUsers: User[] }>({
          query: this.queries.filterUsers,
          variables: {
            customerId,
            clientId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ filterUsers: User[] }>) => {
            let ret = [] as User[];
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.filterUsers) {
              ret = res.data.filterUsers;
            }
            return ret;
          })
        )
    );
  }

  getGlobalUsers(): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<{ filterUsers: User[] }>({
          query: this.queries.filterUsers,
          variables: {
            customerId: null,
            clientId: null,
          },
        })
        .pipe(
          map((res: GQLResponse<{ filterUsers: User[] }>) => {
            let ret = [] as User[];
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.filterUsers) {
              ret = res.data.filterUsers;
            }
            return ret;
          })
        )
    );
  }

  getAvailableUserTypes(user: User): UserType[] {
    const userType: keyof typeof UserType = user.type;
    let result: UserType[] = [];

    if (userType === UserType.ALIQUOT_ADMIN || userType === UserType.GLOBAL) {
      result = [
        UserType.GLOBAL,
        UserType.CLIENT,
        UserType.CUSTOMER,
      ];
    } else if (userType === UserType.ACCOUNT_ADMIN) {
      result = [UserType.GLOBAL, UserType.CLIENT, UserType.CUSTOMER];
    } else if (userType === UserType.CLIENT) {
      result = [UserType.CUSTOMER];
    }

    return result;
  }

  getAvailableUserTypeFilters(user: User): UserType[] {
    const userType: keyof typeof UserType = user.type;
    let result: UserType[] = [];

    if (userType === UserType.ALIQUOT_ADMIN) {
      result = [UserType.ACCOUNT_ADMIN, UserType.GLOBAL];
    } else if (userType === UserType.ACCOUNT_ADMIN) {
      result = [UserType.GLOBAL];
    } else if (userType === UserType.GLOBAL) {
      result = [UserType.GLOBAL, UserType.CLIENT];
    } else if (userType === UserType.CLIENT) {
      result = [UserType.CLIENT];
    } else if (userType === UserType.CUSTOMER) {
      result = [UserType.CUSTOMER];
    }

    return result;
  }

  deactivate(id: number, status: UserStatus): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ editUser: any }>({
          mutation: this.mutations.deactivate,
          variables: {
            data: {
              status: status,
            },
            editUserId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ editUser: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.editUser) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteUser: any }>({
          mutation: this.mutations.deleteUser,
          variables: {
            deleteUserId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ deleteUser: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteUser) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }

  deleteSignature(): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteSignature: User }>({
          mutation: this.mutations.deleteSignature,
        })
        .pipe(
          map((res: GQLResponse<{ deleteSignature: User }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteSignature) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }

  editMultipleUserAccess(vars: any, callback: () => void): Promise<void> {
    return lastValueFrom(
      this.graphService.mutate<{ editMultipleUserAccess: any }>({
        mutation: this.mutations.editMultipleUserAccess,
        variables: vars,
      })
    ).then((res: GQLResponse<{ editMultipleUserAccess: any }>) => {
      if (res.data.editMultipleUserAccess) {
        callback();
      }
    });
  }
}
