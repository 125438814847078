const QPPermissionValues = `
  administration {
    myAccountAccess
    changePasswordAccess
    customTagsAcceses
    copyCustomerAccess
    autoCustomerAccess
    globalUsersAccess
    globalUsersCreate
    clientUsersAccess
    clientUsersCreate
    customerUsersAccess
    customerUsersCreate
    userTemplatesAccess
    userTemplatesCreate
    userGroupsAccess
    userGroupsCreate
    systemTypesAccess
    testTypesAcccess
    controllerAccess
    controllerCreate
    adminCenter
    couponTypesAccess
  }    
  miscellaneous {
    globalAttachmentsAccess
    reportPhrasesAccess
    orderRequestAccess
    masterProductListAccess
    alarmHistoryAcccess
    alarmContactsAccess
    customerContactsAccess
    notificationContactsAccess
    managementReportsAccess
    testScheduleReportsAccess
  }
  navigation {
    buildingAccess
    buildingAttachments
    buildingCreate
    clientAccess
    clientAttachments
    clientCreate
    componentAccess
    componentAttachments
    componentCreate
    customerAccess
    customerAttachments
    customerCreate
    facilityAccess
    facilityAttachments
    facilityCreate
    facilityInternalNotesAccess
    systemAccess
    systemAttachments
    systemCreate
    testAccess
    testCreate
  }
  reports {
    inventoryReportAccess
    serviceReportAccess
    serviceReportOnSiteTime
    serviceReportDriveTime
    serviceReportDataOverride
    serviceReportSetup
    visitReportAccess
    visitReportOnSiteTime
    visitReportDriveTime
    visitReportDataOverride
    visitReportSetup
    operatorLogAccess
    operatorLogDataOverride
    operatorLogSetup
    labReportAccess
    labReportDateOverride
    labReportSetup
  }`;

export { QPPermissionValues };
