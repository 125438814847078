import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordResetRequiredRoutingModule } from './password-reset-required-routing.module';
import { PasswordResetRequiredComponent } from './password-reset-required.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [PasswordResetRequiredComponent],
  imports: [CommonModule, ComponentsModule, PasswordResetRequiredRoutingModule],
})
export class PasswordResetRequiredModule {}
