<mat-card class="small-card pad-top login">
    <h2>{{ 'features.select-account.title' | translate }}</h2>
    
        <mat-form-field appearance="outline">
            <mat-label class="user-type-label">{{ 'features.select-account.my-accounts' | translate }}:</mat-label>
            <mat-select required [compareWith]="selectCompare" [(ngModel)]="accountSelection">
                <mat-option *ngFor="let item of accounts" [value]="item">{{ item.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        
        <app-error-list></app-error-list>
    
        <div class="text-right">
            <button class="login-btn" [disabled]="!accountSelection" (click)="submit()" (keypress.enter)="submit()" mat-raised-button  color="primary">Select</button>
        </div>
</mat-card>