import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { User } from 'src/app/feature-modules/users/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  options: any[] = [];
  profileOption = {
    icon: 'fa-user',
    label: 'titles.my-profile',
    path: '/app/users/profile',
  };
  adminOption = {
    icon: '',
    label: 'titles.admin-center',
    path: '/app/admin-center',
  };
  accountOptions = {
    icon: 'fa-arrows-left-right',
    label: 'Switch Account',
    path: '/select-account',
    pathVariables: {
      switch: true,
    },
  };

  user: User = {} as User;

  version = environment.version || '';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.setItemsByUserRole();
  }

  setItemsByUserRole() {
    const status = this.authService.status.getValue();

    if (status.user) {
      this.user = status.user;

      if (this.user.permission.administration) {
        if (this.user.permission.administration.myAccountAccess != 'NONE') {
          this.options.push(this.profileOption);
        }
      }

      if (this.user.accounts && this.user.accounts?.length > 1) {
        this.options.push(this.accountOptions);
      }
    }
  }
}
