import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrendsGraphComponent } from './trends-graph.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FusionChartsModule } from 'angular-fusioncharts';

@NgModule({
  declarations: [TrendsGraphComponent],
  imports: [CommonModule, ComponentsModule, FusionChartsModule],
  exports: [TrendsGraphComponent],
})
export class TrendsGraphModule {}
