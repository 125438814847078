import { APSObject } from 'src/app/types/globals.types';

export const cachePolicy = {
  typePolicies: {
    Query: {
      fields: {
        reportTemplates: {
          merge(existing = [], incoming: APSObject) {
            return [...existing, ...incoming];
          },
        },
      },
    },
    Client: {
      fields: {
        address: {
          merge(existing: APSObject, incoming: APSObject) {
            const merged = { ...existing, ...incoming };
            return merged;
          },
        },
        contactInfo: {
          merge(existing: APSObject, incoming: APSObject) {
            if (!incoming) {
              return existing;
            }

            if (Array.isArray(existing)) {
              return [...existing, incoming];
            }

            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
};
