import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {
  TimeFilterOptions,
  TimeFilterOption,
} from 'src/app/core/constants/time-filter-options/time-filter-options.constant';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ShortFormatForDatePicker } from 'src/app/core/services/app/shortDateFormatDataPicker.service';
interface TimeRangeSelectionEvent {
  from?: string;
  to?: string;
  timeOption?: any;
}

@Component({
  selector: 'app-time-ranges',
  templateUrl: './time-ranges.component.html',
  styleUrls: ['./time-ranges.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useClass: ShortFormatForDatePicker }],
})
class TimeRangesComponent implements OnInit {
  @Input() label: string;
  @Input() showCalendar: boolean;
  @Input() selected: number;
  @Input() width: number;
  @Output() onSelect: EventEmitter<TimeRangeSelectionEvent> = new EventEmitter();

  toggleCustomDates = false;

  timeFilterOptions: Array<TimeFilterOption> = TimeFilterOptions;

  from: TimeRangeSelectionEvent['from'];
  to: TimeRangeSelectionEvent['to'];
  selection: TimeFilterOption;
  
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.selection = TimeFilterOptions[this.selected || 0];
    this.changeDetectorRef.detectChanges();
    this.toggleCustomDates = this.showCalendar || this.selection.value == 0;
    if (this.selection.value != 0) {
        this.from = this.selection.fromDate;
        this.to = this.selection.toDate;
      // const event: TimeRangeSelectionEvent = {
      //   timeOption: this.selection.value,
      // };
      }
  }

  dateSelected() {
    if (this.from && this.to) {
      const event: TimeRangeSelectionEvent = {
        from: this.from,
        to: this.to,
      };
      this.onSelect.emit(event);
    }
    this.selection = TimeFilterOptions[TimeFilterOptions.length - 1];
    //this.toggleCustomDates = e.value.value == 0;
  }

  dateRangeSelected(e: MatSelectChange) {
    this.toggleCustomDates = this.showCalendar || e.value.value == 0;
    if (e.value.value != 0) {
      this.from = e.value.fromDate;
      this.to = e.value.toDate;
    }
    // if (!this.toggleCustomDates) {
    const event: TimeRangeSelectionEvent = {
      from: this.from,
      to: this.to,
    };
    this.onSelect.emit(event);

    // }
  }

  selectCompare(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id === c2.id;
  }
}

export { TimeRangesComponent, TimeRangeSelectionEvent };
