import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateStore } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatCommonModule,
  MatLineModule,
  MAT_DATE_LOCALE,
  RippleGlobalOptions,
  MAT_RIPPLE_GLOBAL_OPTIONS,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkListboxModule } from '@angular/cdk/listbox';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CustomerNode } from '../core/directives/customer-node.directive';
import { EntityListComponent } from './entity-select/entity-list/entity-list.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';
import { FeatureToggleComponent } from './feature-toggle/feature-toggle.component';
import { SpeedBumpDialog } from './speed-bump/speed-bump.components';
import { SystemNode } from '../core/directives/system-node.directive';
import { PromptComponent } from './prompt/prompt.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StickyHeaderComponent } from './sticky-header/sticky-header.component';
import { FocusDirective } from '../core/directives/focus/focus.directive';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { SnakeToTitleCasePipe } from './pipes/snakeToTitle';
import { ErrorListComponent } from './error-list/error-list.component';
import { EditInPlaceModule } from './edit-in-place/edit-in-place.module';
import { KebabPipe } from './pipes/kebabCase';
import { PdfUploadComponent } from './pdf-upload/pdf-upload.component';
import { AddressSearchComponent } from './address-search/address-search.component';

import { enUS } from 'date-fns/locale';
import { DragAndDropDirective } from '../core/directives/drag-and-drop.directive';
import { CKEditorModule } from 'ckeditor4-angular';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { GmapModule } from './gmap/gmap.module';
import { TimeInputComponent } from './time-input/time-input.component';
import { TimeRangesComponent } from './time-ranges/time-ranges.component';
import { DialogSystemNavComponent } from './entity-select/dialog-system-nav/dialog-system-nav.component';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UserAssignmentComponent } from './user-assignment/user-assignment.component';
import { DialogConfirmModule } from './dialog-confirm/dialog-confirm.module';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { PhoneNumberPipe } from './pipes/phoneNumber';
import { FilterByEntityComponent } from './filter-by-entity/filter-by-entity.component';

const materialLibs = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatCommonModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatLineModule,
  MatListModule,
  MatMenuModule,
  MatDateFnsModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatTreeModule,
  MatTooltipModule,
  ScrollingModule,
  DragDropModule,
  CdkScrollableModule,
  CdkListboxModule,
];

const components = [
  AddressSearchComponent,
  BreadcrumbsComponent,
  CommentBoxComponent,
  DialogSystemNavComponent,
  DragAndDropDirective,
  EntityListComponent,
  EntitySelectComponent,
  ErrorListComponent,
  FeatureToggleComponent,
  FileUploadComponent,
  FilterByEntityComponent,
  PdfUploadComponent,
  PdfUploadComponent,
  PromptComponent,
  SpeedBumpDialog,
  StickyHeaderComponent,
  SignaturePadComponent,
  TextEditorComponent,
  TimeInputComponent,
  TimeRangesComponent,
  UserAssignmentComponent,
];

const directives = [
  SystemNode,
  CustomerNode,
  FocusDirective,
  DragAndDropDirective,
];

const pipes = [SnakeToTitleCasePipe, KebabPipe, PhoneNumberPipe];

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    CommonModule,
    TranslateModule.forChild(), // ANY CONFIG seems work
    //SocketIoModule.forRoot(socketConfig),
    RouterModule.forChild([]),
    FormsModule,
    EditInPlaceModule,
    GmapModule,
    DialogConfirmModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    CKEditorModule,
    FontAwesomeModule,
    ...materialLibs,
  ],
  exports: [
    TranslateModule,
    FormsModule,
    EditInPlaceModule,
    ReactiveFormsModule,
    ToastrModule,
    HttpClientModule,
    CKEditorModule,
    FontAwesomeModule,
    GmapModule,
    RouterModule,
    ...components,
    ...directives,
    ...materialLibs,
    ...pipes,
    //SocketIoModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    TranslateStore, // NEEDED for TESTS
  ],
})
export class ComponentsModule {}
