const QPAppSettings = `appSettings {
    configuration {
      id
      enableVideo
      enableAliquot
      enableAPI
      defaultLoader
      customerAccountNumber
    }
    content {
      welcomeContent
      id
    }
    customization {
      alertType
      alertMessage
      css
      id
      js
    }
    id
    imagesId
    licensing {
      id
      notes
      salesReps
      systemAdmins
      totalClients
    }
  }`;

type AppSettingsConfiguration = {
  id: number;
  enableVideo: boolean;
  enableAliquot: boolean;
  enableAPI: boolean;
  defaultLoader: string;
  customerAccountNumber: string;
};

type AppSettingsContent = {
  welcomeContent: string;
  id: number;
};

type AppSettingsCustomization = {
  alertType: number;
  alertMessage: string;
  css: string;
  id: any;
  js: string;
};

type AppSettingsLicensing = {
  id: number;
  notes: string;
  salesReps: any[];
  totalClients: string | number;
};

type AppSettings = {
  configuration: AppSettingsConfiguration;
  content: AppSettingsContent;
  customization: AppSettingsCustomization;
  liscensing: AppSettingsLicensing;
  id: number;
};

export {
  QPAppSettings,
  AppSettings,
  AppSettingsConfiguration,
  AppSettingsContent,
  AppSettingsCustomization,
  AppSettingsLicensing,
};
