import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app/app.service';
import { AuthService } from 'src/app/core/auth/auth/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private appService: AppService
  ) {
    this.appService.resetQuickNav([
      'client',
      'customer',
      'facility',
      'building',
      'system',
    ]);
    this.appService.entityStore.reset();
    this.authService.logout();
    this.appService.sidenavToggle.emit(false);
  }

  ngOnInit(): void {}
}
