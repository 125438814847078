import { APSObject, CustomThemes } from '../types/globals.types';

export const loadingScreen = (show: boolean) => {
  const loadingDom: Element | null = document.getElementsByClassName('loading-screen')[0];

  if (loadingDom) {
    if (show) {
      loadingDom.classList.add('show-loading');
    } else {
      loadingDom.classList.remove('show-loading');
      loadingDom.classList.add('hide-loading');
    }
  } else {
    console.error('Loading screen element not found');
  }
};

export const isObjectSync = (item: APSObject) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeObjectSync = (target: APSObject, ...sources: APSObject): APSObject => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObjectSync(target) && isObjectSync(source)) {
    for (const key in source) {
      if (isObjectSync(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeObjectSync(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeObjectSync(target, ...sources);
};

export const cssCase = (value: APSObject) => {
  return value.replace(
    /[A-Z]/g,
    (match: APSObject, offset: APSObject) => (offset > 0 ? '-' : '') + match.toLowerCase()
  );
};

const capitalizeString = (value: APSObject) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const buildInlineCSS = (themeObject: APSObject) => {
  const outObj = mergeObjectSync({}, themeObject);

  const propertiesToArray = (obj: APSObject) => {
    if (obj && obj.hasOwnProperty('colors')) {
      Object.keys(obj.colors).forEach((key) => {
        obj.colors[`color${capitalizeString(key)}`] = obj.colors[key];
        delete obj.colors[key];
      });
    }

    if (obj && obj.hasOwnProperty('scroll')) {
      Object.keys(obj.colors).forEach((key) => {
        obj.colors[`${capitalizeString(key)}`] = obj.colors[key];
        delete obj.colors[key];
      });
    }

    const paths = (obj = {}, _head = ''): APSObject => {
      return Object.entries(obj).reduce((outCSS, [key, value]) => {
        let fullPath: APSObject = `--${cssCase(key)}:${value}`;
        let fullValue: APSObject = value;

        return isObjectSync(fullValue)
          ? outCSS.concat(paths(fullValue, fullPath))
          : outCSS.concat(fullPath);
      }, []);
    };

    return paths(obj);
  };

  return `${propertiesToArray(outObj).join(';')};`;
};

export const writeCustomTheme = (theme: CustomThemes) => {
  const internalTheme: APSObject = {
    colors: {
      ...theme,
    },
  };
  const apsJsBaseCSS = document.getElementById('aps_white_label');
  if (!apsJsBaseCSS) {
    const globalStyleElement = document.createElement('style');
    globalStyleElement.id = 'aps_white_label';
    globalStyleElement.textContent = `:root { ${buildInlineCSS(internalTheme).replace(
      /\n|\t/g,
      ' '
    )} }`;

    document.head.appendChild(globalStyleElement);
  } else {
    apsJsBaseCSS.textContent = `:root { ${buildInlineCSS(internalTheme).replace(
      /\n|\t/g,
      ' '
    )} }`;
  }
};
