import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexModule } from './index/index.module';
// import { NotFoundModule } from './not-found/not-found.module';
import { LoginModule } from './login/login.module';
import { SupportModule } from './support/support.module';
import { ComponentsModule } from '../components/components.module';
import { PasswordResetModule } from './password-reset/password-reset.module';
import { PasswordResetRequiredModule } from './password-reset-required/password-reset-required.module';
import { LogoutModule } from './logout/logout.module';
import { AccountModule } from './account/account.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComponentsModule,
    IndexModule,
    LoginModule,
    LogoutModule,
    PasswordResetModule,
    PasswordResetRequiredModule,
    SupportModule,
    AccountModule,
    //NotFoundModule, // Must be last
  ],
})
export class PublicModule {}
