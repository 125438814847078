import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { StorageKeySystemHistory } from 'src/app/core/constants/cookies/storage-system-history.constant';
import { AppService, SystemHistoryStore } from 'src/app/core/services/app/app.service';
import { System } from 'src/app/features/systems/models/system.model';
import { EntityOption } from '../models/entity-option.model';
import { EntitySelectService } from '../services/entity-select.service';
import { ToastrService } from 'ngx-toastr';
import { FavoritesService } from 'src/app/core/services/favorites/favorites.service';

enum SystemNavModes {
  Input,
  FavoriteSelect,
}

@Component({
  selector: 'app-dialog-system-nav',
  templateUrl: './dialog-system-nav.component.html',
  styleUrls: ['./dialog-system-nav.component.scss'],
})
class DialogSystemNavComponent implements OnInit {
  favorites: EntityOption[] = [];
  //systemInputId = '';
  mode: SystemNavModes;

  form = new FormGroup({
    systemInput: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(1|[1-9][0-9]*)$/),
    ]),
  });

  errorState = false;

  SystemNavModes = SystemNavModes;

  systemHistory: SystemHistoryStore[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialigRef: MatDialogRef<DialogSystemNavComponent>,
    private appService: AppService,
    private entitySelectService: EntitySelectService,
    private favoritesService: FavoritesService,
    private toastr: ToastrService
  ) {
    this.favoritesService.userFavorites.subscribe((favorites: EntityOption[]) => {
      this.favorites = favorites || [];
    });
  }

  ngOnInit(): void {
    this.checkMode();
    this.getHistoryFromCookie();
  }

  checkMode() {
    this.mode = this.data.mode;
    switch (this.mode) {
      case SystemNavModes.Input:
        break;
      case SystemNavModes.FavoriteSelect:
        //this.loadFavorites();
        this.favorites = this.data.favorites;
        break;
    }
  }

  getHistoryFromCookie() {
    this.systemHistory = this.appService.getCookie(StorageKeySystemHistory);
  }

  ngAfterViewInit() {}

  update(event: MatSelectChange) {
    this.navigateToSystem(Number(event.value.id));
  }

  onSubmit() {
    this.navigateToSystem(Number(this.form.controls.systemInput.value));
  }

  navigateToSystem(systemId: string | number) {
    this.entitySelectService
      .getSystemAndParents(systemId)
      .then((system: System | null) => {
        if (system) {
          const history = this.appService.trackHistory(system);
          this.appService.setCookie(StorageKeySystemHistory, history);
          this.appService.systemNavigationState.next(system);
          this.dialigRef.close(true);
        } else {
          this.errorState = true;
          this.form.controls.systemInput.patchValue('');
        }
      })
      .catch(() => {});
  }

  removeFavorite(id: any) {
    let vars = {
      removeSystemId: id,
    };
    
    this.favoritesService.manageSystemFavs(vars)
      .then((res) => {
        if (res) {
          this.toastr.success('The System was removed from your favorites.');
        } else {
          this.toastr.error('The System was not removed from your favorites.'); // TODO:
        }
        this.favoritesService.getFavorites();
      })
      .catch((err) => {
        this.toastr.error(err);
      });
  }

  viewFavoriteSystem(id: number) {
    this.navigateToSystem(id);
  }
}

export { DialogSystemNavComponent, SystemNavModes };
