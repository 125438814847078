import { Dictionary } from 'src/app/core/models/dictionary/dictionary.model';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { Building } from 'src/app/features/buildings/models/building.model';
import { Facility } from 'src/app/features/facilities/models/facility.model';
import { System } from 'src/app/features/systems/models/system.model';

export class AppEntityStore {
  private _lookupClients: Dictionary<Client[]> = {};
  private _lookupCustomers: Dictionary<Customer[]> = {};
  private _lookupFacilities: Dictionary<Facility[]> = {};
  private _lookupBuildings: Dictionary<Building[]> = {};
  private _lookupSystems: Dictionary<System[]> = {};

  constructor() {}

  /**
   * @description Lookup Client by AccountId
   * @example this.appService.entityStore.lookupCustomer[client.id];
   */
  get lookupClients() {
    return this._lookupClients;
  }

  /**
   * @description Lookup Customer by ClientID
   * @example this.appService.entityStore.lookupCustomer[client.id];
   */
  get lookupCustomers() {
    return this._lookupCustomers;
  }

  /**
   * @description Lookup Facilities by CustomerID
   * @example this.appService.entityStore.lookupFacilitie[customer.id];
   */
  get lookupFacilities() {
    return this._lookupFacilities;
  }

  /**
   * @description Lookup Buildings by FacilityID
   * @example this.appService.entityStore.lookupBuildings[facility.id];
   */
  get lookupBuildings() {
    return this._lookupBuildings;
  }

  /**
   * @description Lookup Systems by BuildingID
   * @example this.appService.entityStore.lookupSystems[building.id];
   */
  get lookupSystems() {
    return this._lookupSystems;
  }

  reset() {
    this._lookupClients = {};
    this._lookupCustomers = {};
    this._lookupFacilities = {};
    this._lookupBuildings = {};
    this._lookupSystems = {};
  }

  addClients(accountId: number, clients: Client[]) {
    this._lookupClients[accountId] = clients;
  }

  addCustomers(clientId: number, customers: Customer[]) {
    this._lookupCustomers[clientId] = customers;
  }

  addFacilities(customerId: number, facilities: Facility[]) {
    this._lookupFacilities[customerId] = facilities;
  }

  addBuildings(facilityId: number, buildings: Building[]) {
    this._lookupBuildings[facilityId] = buildings;
  }

  addSystems(buildingId: number, systems: System[]) {
    this._lookupSystems[buildingId] = systems;
  }
}
