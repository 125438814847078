import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToTitleCase',
})
export class SnakeToTitleCasePipe implements PipeTransform {
  transform(value: string, ..._args: any[]) {
    return (value && value.split('_').join(' ')) || '';
  }
}
