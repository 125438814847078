const AccountStaticsTestData = {
  statistics: {
    allow: true,
    canedit: true,
    clientusers: 63,
    customerusers: 1645,
    domainname: 'alpha2.eservicereport.com',
    globalusers: 20,
    lastsalesreplogin: '2022-11-11T12:36:21.980',
    lastsavedservicereport: '2022-12-13T09:21:05',
    lastsysadminlogin: '2022-10-21T16:38:35.770',
    salesreps: 52,
    sessionId: 'e410d19c-675c-42d6-86fa-c0bf5f328a57',
    settings: [
      {
        accountlockoutminutes: 30,
        affiliateHTML: '',
        allowapi: true,
        allowtemplates: true,
        aquser: true,
        autosavefrequencyminutes: 0.5,
        blacklistdomains: '',
        css: "/* -------------------- LANDING PAGE SETTINGS Template=1 BG=9 -------------------- */\r\n@import url('//design.eservicereport.com/art/css/9/template_1.css');\r\n/* -------------------- END LANDING PAGE SETTINGS Template=1 BG=9 -------------------- */",
        custReportsName: 'Flex Reports',
        customer: 'C0845',
        dashboard: false,
        defaultLoader: '1',
        defaultddashboardsettings: '',
        enableVideo: true,
        esrappname: 'Alpha2',
        esrlite: false,
        esrreleasedate: '12-31-2019',
        esrversion: '3.3.5',
        esrversionname: 'Lasso',
        gaid: 'GTM-MJD4KPS                                       ',
        homepage: '',
        itemsperpage: 12,
        javascipt: '',
        licensingnotes:
          '7/27/2022 - Added 1 license per Mike.(GAC)\r\nAs of 2/28/2022 - 54 licenses for US div., and 43 licenses for UK div. (GAC)\r\n2/28/2022 - Added 5 license for UK, per Mark(GAC)\r\n1/24/2022 - Added 2 licenses for the UK group per Mark.(GAC)\r\n01/24/19 - Mike Kondolf gave us the approval to add another 10 licenses to US client\r\n5/6/15 - US has 50 Users and RMC UK has increase up to 20, 1 user for US demo.\r\n9/15/14 - US increased to 50 User licenses, UK should have 10 users\r\n4-30-12 UK has 10 Reps, US has 40 Reps',
        locnumber: 9314,
        logoGraph: '/files/artwork/logo_graph.png',
        loginMsgText: 'Welcome to Aliquot!',
        loginMsgType: 1,
        logoCompany: '/files/artwork/logo_company.png',
        logoFooter: '/files/artwork/logo_footer.png',
        logoHome: '/files/artwork/logo_home.png',
        logoImages: '/files/artwork/logo_images.png',
        logoLogin: '/files/artwork/logo_login.png',
        logoReport: '/files/artwork/logo_report.png',
        //
        marketingEmail: '',
        maxnumberadmins: 8,
        maxnumberaq: 97,
        maxnumbersalesreps: 98,
        maxnumberclients: 8,
        passwordrotationdays: -1,
        ordersemail: 'qatesting@aquaphoenixsci.com',
        showupdatemsg: false,
        siteIconPng: '/files/artwork/FavIcon.png',
        siteIconIco: '/files/artwork/FavIcon.ico',
        subjectText: '[@SystemName] [@ReportType] ([@ReportDate], [@ReportTime])',
        supportemail: 'qatesting@aquaphoenixsci.com',
        updatemsg:
          'This site will be down today at <strong>7:00 PM</strong> CDT Tuesday <strong>June 9th</strong> for Website Maintenance. <br />This will last for up to 60 minutes.',
      },
    ],
    sysadmins: 6,
    timeoutstyle: '70',
    totalapireports: 1973,
    labReports: 30,
    operatorLogs: 150,
    salesReps: 100,
    serviceReports: 250,
    systemAdmins: 5,
    totalClientUsers: 100,
    totalClients: 3,
    totalControllers: 10,
    totalCustomerUsers: 300,
    totalCustomers: 30,
    visitReports: 50,

    websiteid: '93f0e48f-7007-4d00-80f4-3ab50c53dc6b',
  },
};

export { AccountStaticsTestData };
