import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class LocalStorageService {
    
    getStorage(key: string): unknown | null{
        let returnData = null;
        let data = localStorage.getItem(key);
        if(typeof data != "object"){
            returnData = JSON.parse(data);
        }
        return returnData;
    }

    setStorage(key: string, value: string | unknown ): void{
        if (typeof value == "string"){
            localStorage.setItem(key, value);
        } else{
            let val = JSON.stringify(value);
            localStorage.setItem(key, val);
        }
    }

    removeStorage(key: string): void{
        localStorage.removeItem(key);
    }
  }