import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { Customer } from '../../customers/models/customer.model';
import { UserPermissions } from 'src/app/core/models/permissions/permissions.model';

enum UserStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}

enum UserType {
  'ALIQUOT_ADMIN' = 'ALIQUOT_ADMIN',
  'ACCOUNT_ADMIN' = 'ACCOUNT_ADMIN',
  'GLOBAL' = 'GLOBAL',
  'CUSTOMER' = 'CUSTOMER',
  'CLIENT' = 'CLIENT',
}

enum StartPage {
  'HOME' = 'HOME',
  'FAVORITES' = 'FAVORITES',
}

class User {
  id: number;
  accounts?: any[];
  accountId?: number;
  closingSigImage?: string;
  closingSigStr?: string;
  client?: Partial<Client>;
  customer?: Partial<Customer>;
  email: string;
  firstName: string;
  language: string;
  lastName: string;
  mi?: string;
  password?: string;
  passwordConfirmation?: string;
  passwordResetRequired?: boolean;
  permission: UserPermissions;
  phoneFormat: string;
  phoneNumber: string;
  startPage: keyof typeof StartPage;
  status: keyof typeof UserStatus;
  timezone: string;
  type: keyof typeof UserType;
  userAccess?: {
    accessAll: boolean;
    buildingIds: number[];
    customerIds: number[];
    facilityIds: number[];
    id: number;
    user: User;
  };

  constructor() {}

  static getFullName(user: User): string {
    return user.firstName != 'Admin'
      ? user.firstName + (user.mi ? ' ' + user.mi + '.' : '') + ' ' + user.lastName
      : '';
  }
}

export { User, UserStatus, UserType, StartPage };
