import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {
  @Output()
  onAccept = new EventEmitter<any>();

  @ViewChild('signature')
  canvasEl: { nativeElement: HTMLCanvasElement };

  signaturePad: SignaturePad;

  _signatureSVG: string;
  _signatureImage: string;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.canvasEl) {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.signaturePad.addEventListener('onEnd', this.drawComplete as any);
      this.signaturePad.addEventListener('onBegin', this.drawStart as any);
    }
  }

  @Input()
  set signatureSVG(val: string) {
    this._signatureSVG = val;
  }

  @Input()
  set signatureImage(val: string) {
    this._signatureImage = val;
  }

  accept() {
    this._signatureSVG = this.signaturePad.toSVG();
    this._signatureImage = this.signaturePad.toDataURL();
    this.onAccept.emit({
      svg: this._signatureSVG,
      image: this._signatureImage,
    });
  }

  drawComplete(_event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    //console.log('Completed drawing', event);
    // //console.log(this.signaturePad.toDataURL());
  }

  drawStart(_event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    //console.log('Start drawing', event);
  }
}
