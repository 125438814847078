/**
 * Expose services to facilitate Cypress E2E testing using the Application Actions pattern
 *
 * See: https://www.cypress.io/blog/2019/01/03/stop-using-page-objects-and-start-using-app-actions
 */

import { Injectable } from '@angular/core';
import { GraphqlService } from './core/services/graphql/graphql.service';
import { AuthService } from './core/auth/auth/auth.service';
import { ClientService } from './feature-modules/clients/services/client.service';
import { CustomerService } from './feature-modules/customers/services/customer.service';
import { FacilityService } from './features/facilities/services/facility.service';
import { BuildingService } from './features/buildings/services/building.service';
import { SystemService } from './features/systems/services/system.service';

declare global {
  interface Window {
    Cypress?: any;
    appServices?: { [key: string]: any };
  }
}

@Injectable({ providedIn: 'root' })
export class CypressServices {
  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private customerService: CustomerService,
    private facilityService: FacilityService,
    private buildingService: BuildingService,
    private sytemService: SystemService,
    private graphqlService: GraphqlService
  ) {
    if (window.Cypress) {
      window.appServices = {
        authService: this.authService,
        clientService: this.clientService,
        customerService: this.customerService,
        facilityService: this.facilityService,
        buildingService: this.buildingService,
        systemService: this.sytemService,
        graphqlService: this.graphqlService,
      };
    }
  }
}
