<div style="margin: 0px; padding: 0px">
  <div class="entity-select-wrapper container-fluid m-top-6" [ngClass]="viewMode">
    <div class="flex-row">
      <div class="quick-action-header">
        <div class="section-flex">
          <div>
            <div class="section-header m-top-0">
              <div>{{ 'Location' | translate }}</div>
            </div>
            <div class="section-sub-header">Set your current location.</div>
          </div>
        </div>
      </div>
      <div class="quick-actions">
        <span
          class="tab action-button"
          tabindex="0"
          (click)="openSystemNavigation(SystemNavModes.Input)"
          matTooltip="{{ 'actions.input-system-id' | translate }}"
        >
          <i class="fa fa-sharp fa-keyboard"></i>
          <span>System</span>
        </span>
        <span
          class="tab action-button"
          tabindex="0"
          (click)="openSystemNavigation(SystemNavModes.FavoriteSelect)"
          matTooltip="{{ 'actions.select-from-favorites' | translate }}"
        >
          <i class="fa fa-sharp fa-star"></i>
          <span>Favorites</span>
        </span>
      </div>
    </div>
    <span class="msg" *ngIf="loadingMessage">{{ loadingMessage }}</span>
    <div class="d-flex flex-wrap entity-list-selects">
      <app-entity-list
        id="entity-select-client"
        *ngIf="fieldControl.client"
        [selected]="selections.client | async"
        [options]="dataOptions.client"
        [selectLabel]="labels.initialSelect"
        [label]="labels.initial"
        [path]="'client'"
        [createPerm]="permissions.clientCreate"
        [accessPerm]="permissions.clientAccess"
        (onSelect)="onClientSelect($event)"
        (onRemove)="removeClient($event)"
      ></app-entity-list>

      <app-entity-list
        id="entity-select-customer"
        *ngIf="fieldControl.customer"
        [disabled]="!(selections.client | async)"
        [selected]="selections.customer | async"
        [options]="dataOptions.customer"
        [selectLabel]="'actions.select-customer'"
        [label]="'labels.customer'"
        [path]="'customer'"
        [createPerm]="permissions.customerCreate"
        [accessPerm]="permissions.customerAccess"
        (onSelect)="onCustomerSelect($event)"
        (onRemove)="removeCustomer($event)"
      ></app-entity-list>

      <app-entity-list
        id="entity-select-facility"
        *ngIf="fieldControl.facility"
        [disabled]="!(selections.customer | async)"
        [selected]="selections.facility | async"
        [options]="dataOptions.facility"
        [selectLabel]="'actions.select-facility'"
        [label]="'labels.facility'"
        [path]="'facility'"
        [createPerm]="permissions.facilityCreate"
        [accessPerm]="permissions.facilityAccess"
        (onSelect)="onFacilitySelect($event)"
        (onRemove)="removeFacility($event)"
      ></app-entity-list>

      <app-entity-list
        id="entity-select-building"
        *ngIf="fieldControl.building"
        [disabled]="!(selections.facility | async)"
        [selected]="selections.building | async"
        [options]="dataOptions.building"
        [selectLabel]="'actions.select-building'"
        [label]="'labels.building'"
        [path]="'building'"
        [createPerm]="permissions.buildingCreate"
        [accessPerm]="permissions.buildingAccess"
        (onSelect)="onBuildingSelect($event)"
        (onRemove)="removeBuilding($event)"
      ></app-entity-list>

      <app-entity-list
        id="entity-select-system"
        *ngIf="fieldControl.system"
        [disabled]="!(selections.building | async)"
        [selected]="selections.system | async"
        [options]="dataOptions.system"
        [selectLabel]="'actions.select-system'"
        [label]="'labels.system'"
        [path]="'system'"
        [createPerm]="permissions.systemCreate"
        [accessPerm]="permissions.systemAccess"
        (onSelect)="onSystemSelect($event)"
        (onRemove)="removeSystem($event)"
      ></app-entity-list>
    </div>
  </div>
</div>
