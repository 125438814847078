import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { GlobalOptions } from 'src/app/core/models/global-options/global-options.model';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { System } from 'src/app/features/systems/models/system.model';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { EntitySubjects } from 'src/app/components/entity-select/models/entity-subjects.model';
import { EntitySelections } from 'src/app/components/entity-select/models/entity-selections.model';

import { Building } from 'src/app/features/buildings/models/building.model';
import { BreadcrumbNode } from 'src/app/components/breadcrumbs/breadcrumb-node.model';
import { FormGroup } from '@angular/forms';
import { Facility } from 'src/app/features/facilities/models/facility.model';
import { EntityChain } from '../../models/entity-chain/entity-chain.model';
import { format } from 'date-fns';
import { StorageKeyEntities } from 'src/app/components/entity-select/entity-select.component';
import { StorageKeySystemHistory } from '../../constants/cookies/storage-system-history.constant';
import { AppEntityStore } from 'src/app/components/entity-select/models/entity-store.model';
import { APSObject } from 'src/app/types/globals.types';

export const StorageKeyToken = 'APS-TOKEN';
export const StorageKeyUser = 'APS-USER';
export const StorageKeyUserPermissions = 'APS-USER-PERMISSIONS';
export type StorageKeyUserType = typeof StorageKeyUser;

export interface SystemHistoryStore {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  subjects: EntitySubjects = {
    client: new BehaviorSubject<Client | null>(null),
    customer: new BehaviorSubject<Customer | null>(null),
    facility: new BehaviorSubject<Facility | null>(null),
    building: new BehaviorSubject<Building | null>(null),
    system: new BehaviorSubject<System | null>(null),
  };

  viewState: EntitySelections = {
    client: this.subjects.client.asObservable(),
    customer: this.subjects.customer.asObservable(),
    facility: this.subjects.facility.asObservable(),
    building: this.subjects.building.asObservable(),
    system: this.subjects.system.asObservable(),
  };

  systemNavigationState = new BehaviorSubject<System | null>(null);

  globalOptions = new BehaviorSubject<GlobalOptions>({} as GlobalOptions);
  globalOptions$ = this.globalOptions.asObservable();

  entityStore = new AppEntityStore();

  static defaultDateFormat = 'MM/dd/yyyy'; //Later replace with default location format
  static defaultTimeFormat = 'hh:mm a'; //Later replace with default location format

  defaultPaths = {
    listUsers: '/app/users',
    newUser: '/app/users/new',
  };
  paths = {
    listUsers: '/app/users',
    newUser: '/app/users/new',
  };

  systemId = '';

  navigationState: Navigation | null = null;
  returnPath = '';

  quickNavReset = new EventEmitter();
  sidenavToggle = new EventEmitter();
  loadingToggle = new EventEmitter();

  clientRefresh = new EventEmitter();
  customerRefresh = new EventEmitter();
  facilityRefresh = new EventEmitter();
  buildingRefresh = new EventEmitter();
  systemRefresh = new EventEmitter();
  languageChange = new EventEmitter();

  breadcrumbs: BreadcrumbNode[] = [];

  toggleSpinner = false;

  systemHistory: SystemHistoryStore[] = [];

  constructor(
    public router: Router,
    private http: HttpClient
  ) {}

  static deepCopy<T>(obj: NonNullable<T>): T {
    const stringified = JSON.stringify(obj);
    return JSON.parse(stringified);
  }

  static saveSVG(svgEl: Element, name: string) {
    if (name.substring(name.length, name.length - 4) !== '.svg') {
      name = name + '.svg';
    }

    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  static deconstructSystem(system: System): EntityChain {
    const building = system.building;
    const facility = building?.facility;
    const customer = facility?.customer;
    const client = customer?.client;

    let response: any = {};

    response = {
      system,
      building,
      facility,
      customer,
      client,
    };

    return response;
  }

  static destroyEditor(editor: any): void {
    if (editor && editor.instances) {
      for (const editorInstance in editor.instances) {
        if (editor.instances[editorInstance]) {
          try {
            editor.instances[editorInstance].destroy();
          } catch (e) {}
        }
      }
    }
  }

  /*need to go to global functions*/
  static formatDateTime(date: string) {
    return (
      format(new Date(date), this.defaultDateFormat) +
      ' ' +
      format(new Date(date), this.defaultTimeFormat)
    );
  }

  static formatTime(date: string) {
    return format(new Date(date), this.defaultTimeFormat);
  }

  static formatDate(date: string) {
    return format(new Date(date), this.defaultDateFormat);
  }

  static convertSVGToPNG(encodedData: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `data:image/svg+xml;base64,` + encodedData;
  
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL('image/png');
        resolve(pngDataUrl);
      };
  
      img.onerror = function () {
        reject(new Error('Failed to load SVG image'));
      };
    });
  }

  resetEntities() {
    const keys = [
      'client',
      'customer',
      'facility',
      'building',
      'system',
    ];
    keys.forEach((key) => {
      this.subjects[key]?.next(null);
    });
    setTimeout(() => {
      this.saveEntities();
    });
  }

  saveEntities() {
    const values: APSObject = {};
    Object.keys(this.subjects).map((key: string) => {
      values[key] = this.subjects[key].getValue()?.id;
    });
    const d: Date = new Date();
    const expireMinutes = 30;
    d.setTime(d.getTime() + expireMinutes * 60 * 1000);
    localStorage.setItem(StorageKeyEntities, JSON.stringify(values));
  }

  setClient(client: Client | null) {
    this.subjects.client.next(client);
    if (client) {
      this.breadcrumbs = [];
      // Add Client Breadcrumb
      this.breadcrumbs.unshift({
        label: client?.name ?? '',
        path: '/app/client',
      });
      this.paths.newUser = `/app/users/client/${client.id}/new`;
    } else {
      this.paths.newUser = this.defaultPaths.newUser;
    }
  }

  setCustomer(customer: Customer | null) {
    this.subjects.customer.next(customer);
    if (customer) {
      // Add Customer Breadcrumb
      this.breadcrumbs = [];
      this.breadcrumbs.unshift({
        label: customer.name,
        path: '/app/customer',
      });
      this.paths.newUser = `/app/users/customer/${customer.id}/new`;
    } else {
      this.paths.newUser = this.defaultPaths.newUser;
    }
  }

  setFacility(facility: Facility | null) {
    this.subjects.facility.next(facility);
  }

  setBuilding(building: Building | null) {
    this.subjects.building.next(building);
  }

  setSystem(system: System | null) {
    this.subjects.system.next(system);
  }

  navigateAndReturn(navigation: string, path: string[]) {
    this.returnPath = navigation;
    this.router.navigate(path);
  }

  returnNavigation(): void {
    this.router.navigate([this.returnPath]);
  }

  resetQuickNav(keys: string[]) {
    this.quickNavReset.emit(keys);
  }

  getGlobalOptions(): Observable<GlobalOptions> {
    return this.http.get('/api/global-options').pipe(
      map((val) => {
        const options = val as GlobalOptions;
        this.globalOptions.next(options);
        return options;
      })
    );
  }

  getBreadcrumbs(): Observable<BreadcrumbNode[]> {
    return combineLatest([
      this.viewState.client,
      this.viewState.customer,
      this.viewState.facility,
      this.viewState.building,
      this.viewState.system,
    ]).pipe(
      map(
        ([
          client,
          customer,
          facility,
          building,
          system,
        ]) => {
          const set = [];
          if (client) set.push(client);
          if (customer) set.push(customer);
          if (facility) set.push(facility);
          if (building) set.push(building);
          if (system) set.push(system);
          return set.map((item) => {
            return { label: (item && item.name) || '' } as BreadcrumbNode;
          });
        }
      )
    );
  }

  showSpinner() {
    this.toggleSpinner = true;
    this.loadingToggle.emit(true);
  }

  hideSpinner() {
    this.toggleSpinner = false;
    this.loadingToggle.emit(false);
  }

  getDirtyValues(form: FormGroup) {
    const dirtyValues: any = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  public getUserData(key: string) {
    return JSON.parse(localStorage.getItem(StorageKeyUser) || '{}')?.[key];
  }

  getCookie(cookieKey: string): any {
    const res = JSON.parse(localStorage.getItem(cookieKey) || '[]');
    if (res) {
      return res;
    }
  }

  setCookie(cookieKey: string, val: any) {
    localStorage.setItem(cookieKey, JSON.stringify(val));
  }

  trackHistory(system: System): SystemHistoryStore[] {
    const systemId = Number(system.id);
    const existing = this.systemHistory.find((obj) => obj.id === systemId);
    this.systemHistory = this.getCookie(StorageKeySystemHistory);

    this.systemHistory.push({
      id: systemId,
      name: system.name,
    });

    if (existing) {
      const i = this.systemHistory.indexOf(existing);
      this.systemHistory.splice(i, 1);
    }

    if (this.systemHistory.length > 3) {
      this.systemHistory.shift();
    }

    return this.systemHistory;
  }
}
