<div class="row">
  <div class="col-sm-auto">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'labels.address' | translate }}:</mat-label>
      <input
        required
        matInput
        id="pacInput"
        [formControl]="addressString"
        type="text"
        autocomplete="off"
      />
    </mat-form-field>
    <button class="ml-2" type="button" mat-button (click)="toggles.edit = !toggles.edit">
      {{ (toggles.edit ? 'actions.hide' : 'actions.edit') | translate }}
    </button>
  </div>
  <div class="col-sm"></div>
</div>

<div *ngIf="toggles.edit" class="pod">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'labels.address' | translate }} 1:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['street1']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'labels.address' | translate }} 2:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['street2']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'labels.address' | translate }} 3:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['street3']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'labels.address' | translate }} 4:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['street4']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'Unit' | translate }}:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['unit']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'City' | translate }}:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['city']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ regionLabel | translate }}:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['region']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'Country' | translate }}:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['country']" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'Zip Code' | translate }}:</mat-label>
    <input matInput type="text" [formControl]="addressForm.controls['zipCode']" />
  </mat-form-field>
</div>
