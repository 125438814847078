import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs';

import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { User, UserType } from 'src/app/feature-modules/users/models/user.model';
import {
  AppService,
  StorageKeyToken,
  StorageKeyUser,
  StorageKeyUserPermissions,
} from '../../services/app/app.service';
import { ErrorService } from '../../services/error/error.service';
import { GraphqlService } from '../../services/graphql/graphql.service';
import { Router } from '@angular/router';

import { QPPermissionValues } from '../../queries/permissions/permissions.query';
import { StorageKeyEntities } from 'src/app/components/entity-select/entity-select.component';
import { APSObject } from 'src/app/types/globals.types';
import { Apollo, gql } from 'apollo-angular';
import {
  ApolloService,
  GQLResponse,
} from '../../services/graphql/graphql.apollo.service';
import { ToastrService } from 'ngx-toastr';
import { StorageKeyFilterEntities } from 'src/app/components/filter-by-entity/filter-by-entity.component';

export interface AuthResponse {
  token: string;
  user: User;
  code?: string;
  intercomHash?: string;
}

const authPayload = `... on AuthPayload {
  token
  intercomHash
  user {
    email
    firstName
    id
    language
    accounts {
      id
    }
    lastName
    language
    passwordResetRequired
    startPage
    status
    type
    phoneNumber
    phoneFormat
    client {
      id
      name
    }
    customer {
      id
      name
    }
    closingSigImage
    closingSigStr
    permission {
      id
      name
      locked
      description
      ${QPPermissionValues}
    }
    userAccess {
      accessAll
      buildingIds
      customerIds
      facilityIds
    }
  }
}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  status = new BehaviorSubject<AuthStatus>({
    userFound: false,
    hasSession: false,
  });
  status$ = this.status.asObservable();

  onLogin = new EventEmitter<AuthStatus>();
  onLogout = new EventEmitter<AuthStatus>();

  constructor(
    private appService: AppService,
    private errorService: ErrorService,
    private router: Router,
    private graphService: GraphqlService,
    private apollo: Apollo,
    private apolloService: ApolloService,
    private toastrService: ToastrService
  ) {
    this.handleUserLoggedOut = this.handleUserLoggedOut.bind(this);
    document.addEventListener(
      'user:session:expired',
      this.handleUserLoggedOut as EventListener
    );
  }

  ngOnDestroy(): void {
    document.removeEventListener(
      'user:session:expired',
      this.handleUserLoggedOut as EventListener
    );
  }

  getLogin(vars: { email: string; password: string }) {
    return this.apolloService
      .mutate<GQLResponse<{ login: AuthResponse }>>({
        mutation: gql`
        mutation Login($password: String!, $email: String!) {
          login(password: $password, email: $email) {
            ${authPayload}
            ... on UnauthorizedError {
              message
              code
            }
            ... on ForbiddenError {
              message
              code
            }
          }
        }`,
        variables: vars,
        isPublic: true,
      })
      .pipe(
        map((res: APSObject) => {
          if (res.data?.login && !res.data.login.code) {
            const login = res.data.login;
            localStorage.setItem(StorageKeyToken, login.token);
            this.setUser(login.user);
            this.checkAccounts(login);
            return login;
          } else {
            this.errorService.log(res.data?.login);
          }
        }),
        catchError((e) => {
          this.errorService.log(e, true);
          throw e;
        })
      );
  }

  checkRole(roles: string[]) {
    const status = this.status.getValue();
    const allow = !!roles.find((r) => r === status.user?.type);
    return allow;
  }

  getUser(): User | null {
    const status = this.status.getValue();
    const user = status.user;

    return user || null;
  }

  // To Do - Deprecate the name 'check' and use 'getPermissions' instead
  getPermissions() {
    const status = this.status.getValue();
    const permissions = status.user?.permission;

    if (typeof permissions === 'undefined') {
      throw new Error('Permissions Undefined');
    }

    return permissions;
  }

  getPermission(accessParam: string): string {
    let res = '';
    const user = this.status.getValue().user;
    const permissions: APSObject = user?.permission;

    if (permissions && accessParam) {
      const params = accessParam.split('.');
      if (params.length === 2) {
        const permCat = permissions[params[0]];
        const perm = permCat[params[1]] ?? '';

        res = perm;
      }
    }
    return res;
  }

  unsetPasswordResetRequired() {
    const status = this.status.getValue();
    const user = status.user;
    if (user) {
      user.passwordResetRequired = false;
      // Updates the current auth state, ensuring the app has the up-to-date status
      this.status.next(status);
    } else {
      const error = new Error('Error unsetting password-reset-required');
      this.errorService.log(error);
    }
  }

  quickCheckStatus() {
    const userString = localStorage.getItem(StorageKeyUser) || 'undefined';
    const userPermsString = localStorage.getItem(StorageKeyUserPermissions);
    const token = localStorage.getItem(StorageKeyToken);

    let user: User;
    let permission;

    const status = {
      userFound: false,
      hasSession: false,
      user: {} as User,
    };

    if (userPermsString && userPermsString !== 'undefined') {
      permission = JSON.parse(userPermsString);
    }

    if (userString && userString !== 'undefined') {
      user = JSON.parse(userString) as User;
      if (permission) {
        user.permission = permission;
      }

      if (user && token && token !== 'undefined') {
        status.userFound = true;
        status.hasSession = true;
        status.user = user;
      }
    }

    return status;
  }

  checkEnv() {
    const userString = localStorage.getItem(StorageKeyUser) || 'undefined';
    const userPermsString = localStorage.getItem(StorageKeyUserPermissions);
    const token = localStorage.getItem(StorageKeyToken);

    let user: User;
    let permission;

    const status = {
      userFound: false,
      hasSession: false,
      user: {} as User,
    };

    if (userPermsString && userPermsString !== 'undefined') {
      permission = JSON.parse(userPermsString);
    }

    if (userString && userString !== 'undefined') {
      user = JSON.parse(userString) as User;
      if (permission) {
        user.permission = permission;
      } else {
        this.logout();
      }

      if (user && token && token !== 'undefined') {
        status.userFound = true;
        status.hasSession = true;
        status.user = user;

        setTimeout(() => {
          let isLoginPage = false;
          if (this.router.url.toLowerCase() === '/login') {
            isLoginPage = true;
          }
          this.login({ user, token } as AuthResponse, isLoginPage);
        });
      }
    }
  }

  checkAccounts(res: AuthResponse) {
    const user = res.user;
    const hasSingleAccount = user.accounts?.length == 1;

    if (hasSingleAccount) {
      user.accountId = user.accounts?.at(0).id;
      this.login(res, true);
    } else {
      this.loadAccounts(user);
    }
  }

  setUser(user: User) {
    const userLite = {
      ...user,
    } as any;
    const permissions = {
      ...user.permission,
    };
    delete userLite.permission;

    localStorage.setItem(StorageKeyUser, JSON.stringify(userLite));
    localStorage.setItem(StorageKeyUserPermissions, JSON.stringify(permissions));
  }

  login(res: AuthResponse, forward: boolean = false) {
    const token: string = res.token as string;
    const user: User = res.user as User;
    const intercomHash: string = res.intercomHash as string;
    const forwardPath = '/app/home';

    localStorage.setItem('is-logged-in', 'true');
    this.setCookie(StorageKeyToken, token);
    this.setUser(res.user);

    const status: AuthStatus = {
      userFound: true,
      hasSession: true,
      intercomHash,
      user,
    };

    this.status.next(status);
    this.onLogin.emit(status);
    if (forward) {
      this.router.navigate([forwardPath]);
    }
  }

  loadAccounts(user: User) {
    const accountPath = '/select-account';
    if (user) {
      if (user.type == UserType.ALIQUOT_ADMIN) {
        lastValueFrom(
          this.graphService.query({
            query: gql`
              query AllAccounts {
                allAccounts {
                  id
                  name
                }
              }
            `,
          })
        )
          .then((res: any) => {
            if (res.data && res.data.allAccounts) {
              const accounts = res.data.allAccounts;
              // Populate User Accounts
              user.accounts = res.data.allAccounts;
              this.status.next({
                userFound: true,
                hasSession: true,
                user,
              });
              if (accounts.length === 1) {
                //user.accountId = accounts?.at(0).id;
                this.chooseAccount(accounts[0].id, user);
              } else {
                this.router.navigate([accountPath]);
              }
            }
          })
          .catch((e) => {
            console.log('loadAccounts error: ', e);
          });
      }
    }
  }

  chooseAccount(accountId: number, user: User) {
    lastValueFrom(
      this.graphService.mutate({
        mutation: gql`mutation SelectAccount($userId: Float!, $accountId: Float!) {
          selectAccount(userId: $userId, accountId: $accountId) {
            ${authPayload}
          }
        }`,
        variables: {
          accountId,
          userId: user.id,
        },
      })
    ).then((res: any) => {
      if (res.data.selectAccount) {
        const newStatus = res.data.selectAccount as AuthResponse;
        newStatus.user.accountId = accountId;
        if (user.accounts) {
          newStatus.user.accounts = [...user.accounts];
        }
        this.login(newStatus, true);
      }
    });
  }

  logout() {
    const status = {
      hasSession: false,
      userFound: false,
      user: undefined,
    };
    this.status.next(status);
    this.onLogout.emit(status);
    localStorage.removeItem(StorageKeyUser);

    localStorage.removeItem(StorageKeyToken);
    localStorage.removeItem(StorageKeyUserPermissions);
    localStorage.removeItem(StorageKeyEntities);
    localStorage.removeItem(StorageKeyFilterEntities)
    localStorage.removeItem('is-logged-in');
    this.apolloService.unsubscribe.next();
    this.apolloService.unsubscribe.complete();
    this.apollo.client.clearStore();
    this.closeIntercom();
    this.appService.systemNavigationState.next(null);
  }

  handleUserLoggedOut(_event: CustomEvent): void {
    this.logout();
    this.router.navigate(['login']);
    this.toastrService.error('Your session has expired. Please log in again.');
    this.appService.loadingToggle.next(false);
    this.appService.entityStore.reset();
  }

  setCookie(key: APSObject, token: APSObject) {
    localStorage.setItem(key, token);
  }

  closeIntercom() {
    if (window['Intercom']) {
      // Close user intercom session and reboot an anonymous session
      window['Intercom']('shutdown');
      window['intercomSettings'] = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'cjw9ii1v',
      };
      window['Intercom']('boot', {
        app_id: 'cjw9ii1v',
      });
    }
  }
}
