import { AppSettings, QPAppSettings } from './app-settings.model';

const QPAccountSettings = `appSettings {
    accountSettings {
      id
      ${QPAppSettings}
      blacklistedDomains
      emailSubject
      lockout
      marketingEmail
      ordersEmail
      passwordRotation
      supportEmail
    }
}`;

type AccountSettings = {
  id: number;
  appSettings: AppSettings;
  blacklistedDomains: string;
  emailSubject: string;
  lockout: string;
  marketingEmail: string;
  ordersEmail: string;
  passwordRotation: string;
  supportEmail: string;
};

export { AccountSettings, QPAccountSettings };
