enum AppLanguages {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  SPANISH = 'SPANISH',
}

const LanguageKeys: { [val: string]: string } = {
  ENGLISH: 'en_us',
  FRENCH: 'fr',
  SPANISH: 'es',
};

const LanguagesSupported = ['en-us, fr, es'];

export { AppLanguages, LanguageKeys, LanguagesSupported };
