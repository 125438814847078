import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SupportRoutingModule],
})
export class SupportModule {}
