import { gql } from 'apollo-angular';
import { APSObject } from 'src/app/types/globals.types';

export const defaultThemeObject: DomainValidation = {
  accountImages: [
    {
      createdAt: '2024-04-07T23:27:21.962Z',
      description: 'logoCompany',
      extension: 'image/jpeg',
      file: '/assets/images/aliquot-logo.png',
      id: -1,
      locationId: -1,
      locationType: 'ACCOUNT_IMAGES',
    },
  ],
  apiURL: '',
  domain: '',
  environment: 'prod',
  protocol: 'aliquot',
  themes: `{
    "primary": "#0a84ff",
    "primaryLight": "rgb(0, 122, 255)",
    "primaryDark": "rgb(2, 99, 197)",
  
    "secondary": "rgb(14, 14, 14)",
    "secondaryLight": "rgb(77, 77, 77)",
    "secondaryDark": "rgb(0, 0, 0)"
  }
  `,
};

export interface DomainValidationResponse {
  domainValidation: DomainValidation;
}

export interface DomainValidation {
  domain: APSObject;
  apiURL: APSObject;
  protocol: APSObject;
  environment: APSObject;
  themes: APSObject;
  accountImages: AccountImage[];
  mainLogo?: APSObject;
  loginLogo?: APSObject;
}

export interface AccountImage {
  createdAt: APSObject;
  description: APSObject;
  extension: APSObject;
  file: APSObject;
  id: APSObject;
  locationId: APSObject;
  locationType: APSObject;
}

export const DOMAIN_VALIDATION_QUERY = gql<
  { data: DomainValidationResponse },
  { domain: string }
>`
  query DomainValidation($domain: String!) {
    domainValidation(domain: $domain) {
      apiURL
      domain
      environment
      protocol
      themes
      accountImages {
        createdAt
        description
        extension
        file
        id
        locationId
        locationType
      }
    }
  }
`;
