import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { GraphqlService } from '../graphql/graphql.service';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  userFavorites = new BehaviorSubject<EntityOption[]>([]);

  constructor(private graphService: GraphqlService) {}

  getFavorites() {
    return lastValueFrom(
      this.graphService.query({
        query: gql`
          query SystemFavorite {
            systemFavorite {
              ... on SystemFavorites {
                id
                systems {
                  id
                  name
                }
              }
            }
          }
        `,
      })
    ).then((res: any) => {
      if (res.data.systemFavorite) {
        this.userFavorites.next(res.data.systemFavorite.systems);
        return res.data.systemFavorite.systems;
      }
    });
  }

  async manageSystemFavs(data: any) {
    const res = await lastValueFrom(
      this.graphService
        .mutate<{ manageSystemFavs: any; }>({
          mutation: gql`
            mutation ManageSystemFavs($data: SystemFavInput!) {
              manageSystemFavs(data: $data) {
                ... on SystemFavorites {
                  systems {
                    name
                    id
                  }
                }
              }
            }
          `,
          variables: {
            data: data,
          },
        })
    );
    let ret = false;
    if (res.errors) {
      throw res.errors;
    }
    if (res.data && res.data.manageSystemFavs) {
      ret = true;
    }
    return ret;
  }
}
