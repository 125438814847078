import { Component, OnInit } from '@angular/core';
import { BreadcrumbNode } from 'src/app/components/breadcrumbs/breadcrumb-node.model';

@Component({
  selector: 'app-view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.scss'],
})
export class ViewDashboardComponent implements OnInit {
  breadcrumbs: BreadcrumbNode[] = [
    {
      label: 'titles.my-dashboards',
      path: '/dashboards',
    },
    {
      label: 'titles.dashboards.view',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
