import { Injectable } from "@angular/core";
import { LocalStorageService } from "./localStorage.service";
import { LongDateFormat, ShortDateFormat } from "src/app/feature-modules/clients/constants/date-format.constant";

@Injectable({
    providedIn: 'root',
  })
  export class ShortlongDateFormatClientService {

    selectedFormateClient?:any = null;
    constructor(
        private localStorageService : LocalStorageService
    ){}

    getShortDateFormatAsPerClientSelection():string{
        let returnFormate = "MM/dd/yyyy";
        this.selectedFormateClient = this.localStorageService.getStorage('client_selected_long_short_Date_Format');

        if(this.selectedFormateClient && this.selectedFormateClient.hasOwnProperty("short")){
            switch (this.selectedFormateClient?.short) {
                case ShortDateFormat.M_D_YYYY:
                    returnFormate = 'MM/dd/yyyy';
                    break;
                  case ShortDateFormat.D_M_YYYY:
                    returnFormate = 'dd/MM/yyyy';
                    break;
                  case ShortDateFormat.YYYY_M_D:
                    returnFormate = 'yyyy/MM/dd';
                    break;
                  default:
                    returnFormate = 'MM/dd/yyyy'
                    break;
            }
        }
        return returnFormate;
    }
    getLongDateFormatAsPerClientSelection(longDateFormate?:unknown | null):string{
        let returnFormate = "LLL d, yyyy";
        this.selectedFormateClient;

        if(!!longDateFormate){
            this.selectedFormateClient = longDateFormate;
        } else{
            this.selectedFormateClient = this.localStorageService.getStorage('client_selected_long_short_Date_Format');
            if(this.selectedFormateClient && this.selectedFormateClient.hasOwnProperty("long")){
                this.selectedFormateClient = this.selectedFormateClient?.long;
            }
        }

        if(this.selectedFormateClient){
            switch (this.selectedFormateClient) {
                case LongDateFormat.MONTH_DAY_YEAR:
                    returnFormate = 'LLL d, yyy';
                    break;
                  case LongDateFormat.DAY_MONTH_YEAR:
                    returnFormate = 'd LLL, yyy';
                    break;
                  case LongDateFormat.MONTH_DAY_COMMA_YEAR:
                    returnFormate = 'LLL d, yyy';
                    break;
                  case LongDateFormat.YEAR_MONTH_DAY:
                    returnFormate = 'yyy, LLLL d';
                    break;
                  default:
                    returnFormate = 'LLL d, yyyy'
                    break;
            }
        }
        return returnFormate;
    }
  }