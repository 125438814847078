import { Injectable } from '@angular/core';
import { DocumentNode, TypedDocumentNode } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { APSObject } from 'src/app/types/globals.types';

export type GQLDocument = DocumentNode | TypedDocumentNode<unknown, APSObject>;
export type FetchQueryFunction = <T>(
  query: GQLDocument,
  variables?: GQLVariables
) => Promise<T>;

export interface GQLResponse<T> {
  data: T;
  errors?: any;
  error?: any;
}

export type GQLVariables = { [key: string]: APSObject };
export interface GQLRequestOptions {
  query?: GQLDocument;
  mutation?: GQLDocument;
  variables?: GQLVariables;
  useCache?: boolean;
  isPublic?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApolloService {
  constructor(private apollo: Apollo) {}

  public unsubscribe = new Subject<void>();
  // Simplified fetch for queries to get rid of the pipe(map) that
  // we have everywhere.
  // this.apollo.fetchQuery<{ res: ResponseType }>(
  //   `
  //     query someGQLQUERY($id: Int!) { }
  //   `,
  //   { id: id }
  // ).then(res => {
  //   console.log(res);
  // }).catch(error => {
  //   console.error(error);
  // });
  fetchQuery<T>(query: GQLDocument, variables?: GQLVariables): Promise<T> {
    return lastValueFrom(
      this.query<T>({ query, variables: variables }).pipe(
        map((response) => {
          if (response.data) {
            return { ...response.data };
          } else {
            throw new Error('No response from the Aliquot Server Query.');
          }
        })
      )
    );
  }

  // Same as above but for mutations.
  fetchMutation<T>(mutation: GQLDocument, variables?: GQLVariables): Promise<T> {
    return lastValueFrom(
      this.mutate<T>({ mutation, variables }).pipe(
        map((response) => {
          if (response.data) {
            return { ...response.data };
          } else {
            throw new Error('No data returned from Aliquot Server Mutation');
          }
        })
      )
    );
  }

  public query<T>(options: GQLRequestOptions): Observable<GQLResponse<T>> {
    if (!options.query) {
      throw new Error('Query document is required');
    }

    return this.apollo
      .query<T>({
        query: options.query,
        variables: options.variables,
        context: { isPublic: options.isPublic },
      })
      .pipe(
        takeUntil(this.unsubscribe),
        map((result) => {
          if (result.errors) {
            throw result.errors;
          }
          // console.log('query result: ', result);
          return { data: result.data } as GQLResponse<T>;
        }),
        catchError((err) => {
          console.log('GQL QUERY ERRORS->', err);
          throw err;
        }),
        finalize(() => {
          // Finalize logic here
        })
      );
  }

  public mutate<T>(options: GQLRequestOptions): Observable<GQLResponse<T>> {
    // console.log('DO MUTATION', options);
    if (!options.mutation) {
      throw new Error('Valid GQL Document is Required');
    }

    return this.apollo
      .mutate<T>({
        mutation: options.mutation,
        variables: options.variables,
        context: { isPublic: options.isPublic },
      })
      .pipe(
        map((result) => {
          // console.log('MUTATE RESULT->', result);
          if (result.errors) {
            throw result.errors;
          }
          return { data: result.data } as GQLResponse<T>;
        }),
        catchError((err) => {
          console.log('MUTATE HARD ERROR->', err);
          throw err;
        }),
        finalize(() => {
          // console.log('MUTATE FINALIZE');
          // Finalize logic here
        })
      );
  }
}
