import { Component, Input, OnInit } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { TrendsDataRow } from 'src/app/feature-modules/trends-report/models/trends-data-row.model';

@Component({
  selector: 'app-trends-graph',
  templateUrl: './trends-graph.component.html',
  styleUrls: ['./trends-graph.component.scss'],
})
export class TrendsGraphComponent implements OnInit {
  graphWidth = 300;
  graphHeight = 190;

  graphConfig = {
    chart: {
      theme: 'fusion',
      caption: 'Test Trends Over Time',
      subCaption: '',
      xAxisName: 'Test Type',
      decimals: '2',
      setAdaptiveYMin: '1',
    },
    categories: [
      {
        category: [
          {
            label: 'Mon',
          },
          {
            label: 'Tue',
          },
          {
            label: 'Wed',
          },
          {
            label: 'Thu',
          },
          {
            label: 'Fri',
          },
          {
            label: 'Sat',
          },
          {
            label: 'Sun',
          },
        ],
      },
    ],
    dataset: [
      {
        seriesname: 'PH',
        data: [
          {
            value: '6.2',
          },
          {
            value: '6.3',
          },
          {
            value: '6.3',
          },
          {
            value: '6.4',
          },
          {
            value: '6.5',
          },
          {
            value: '6.7',
          },
          {
            value: '6.8',
          },
        ],
      },
      {
        seriesname: 'TDS',
        data: [
          {
            value: '12500',
          },
          {
            value: '12800',
          },
          {
            value: '22800',
          },
          {
            value: '12400',
          },
          {
            value: '15800',
          },
          {
            value: '19800',
          },
          {
            value: '21800',
          },
        ],
      },
    ],
  };

  @Input() set title(val: string) {
    this.graphConfig.chart.subCaption = val;
  }

  @Input() set graphData(data: TrendsDataRow[]) {
    const seriesData: { seriesname: string; data: { value: string }[] }[] = [];
    const seriesDates = data.map((dataSet) => {
      if (!dataSet.records) {
        return {
          category: [],
        };
      }
      const dates = dataSet.records.map((record) => record.date as string);
      const values = dataSet.records.map((record) => record.value);

      //console.log('dataSet', dataSet.test)

      const graphDataObj = {
        seriesname: dataSet.component,
        anchorBgColor : dataSet.color,
        color : dataSet.color,
        data: values.map((val: number) => {
          return { value: val.toString() };
        }),
      };

      seriesData.push(graphDataObj);

      return {
        category: dates.map((date) => {
          return {
            label: format(parseISO(date), 'MM/dd/yyyy'),
          };
        }),
      };
    });

    this.graphConfig.categories = seriesDates;
    this.graphConfig.dataset = seriesData;
  }

  ngOnInit(): void {}
}
