<app-breadcrumbs
  *ngIf="!miniMode"
  [nodes]="breadcrumbs"
  class="aps-sticky-top no-borders"
></app-breadcrumbs>
<mat-card class="pad-top">
  <div class="d-flex">
    <h3 class="mr-auto">{{ 'titles.my-dashboards' | translate }}</h3>
    <button
      mat-icon-button
      [disabled]="true"
      matTooltip="Coming Soon"
      [routerLink]="['/app', 'dashboards', 'new']"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-list class="well clear-well">
    <mat-list-item *ngFor="let dashboard of dashboards">
      <!-- content here -->
    </mat-list-item>
    <mat-list-item *ngIf="!dashboards.length"> No Dashboards </mat-list-item>
  </mat-list>
  <!--mat-table [dataSource]="dashboards"></mat-table-->
</mat-card>
