<div class="d-flex">
    
    
    <div class="list-container">
        <h4>{{ config.inactiveLabel ?? 'Access Restricted:' }}</h4>
        <div class="pod" [ngStyle]="config.height && {height: config.height}">
            <ul cdkListbox
                cdkListboxMultiple
                [(ngModel)]="selectedInactive"
                aria-labelledby="example-fav-cuisine-label"
                class="example-listbox">
            <li *ngFor="let user of _inactiveUsers" [cdkOption]="user" class="example-option">{{ user.firstName + ' ' + user.lastName }}</li>
                <li *ngIf="!_inactiveUsers.length && !_activeUsers.length" [cdkOption]="''" class="example-option">No Users</li>
            </ul>
        </div>
        
    </div>
    <div class="list-actions">
        <button mat-mini-fab class="mb-3" color="primary" (click)="activate(selectedInactive)"><i class="fa-regular fa-arrow-right"></i></button>
        <button mat-mini-fab class="mb-3" color="primary" (click)="deactivate(selectedActive)"><i class="fa-regular fa-arrow-left"></i></button>
        <button
          mat-mini-fab class="mb-3"
          color="secondary"
          (click)="moveAll()"
          *ngIf="config.showMoveAll"
          matTooltip="Move all users"
        >
          <i class="fa-regular fa-left-right icon-left-right"></i>
        </button>
    </div>
    <div class="list-container" [ngStyle]="config.height && {height: config.height}">
        <h4>{{ config.activeLabel ?? 'Access Granted:' }}</h4>
        <div class="pod" [ngStyle]="config.height && {height: config.height}">
            <ul cdkListbox
                cdkListboxMultiple
                [(ngModel)]="selectedActive"
                aria-labelledby="example-fav-cuisine-label"
                class="example-listbox">
                <li *ngFor="let user of _activeUsers" [cdkOption]="user" class="example-option">{{ user.firstName + ' ' + user.lastName }}</li>
                <li *ngIf="!_inactiveUsers.length && !_activeUsers.length" [cdkOption]="''" class="example-option">No Users</li>
            </ul>
        </div>
        
    </div>
</div>