import { Directive, TemplateRef, ViewChild } from '@angular/core';

@Directive({
  selector: '[editMode]',
})
export class EditModeDirective {
  @ViewChild('editMode') tpl: TemplateRef<any>;

  constructor() {}
}
