import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewModeDirective } from './view-mode/view-mode.directive';
import { EditModeDirective } from './edit-mode/edit-mode.directive';
import { EditableComponent } from './editable-component/editable-component.component';

@NgModule({
  declarations: [ViewModeDirective, EditModeDirective, EditableComponent],
  imports: [CommonModule],
  exports: [ViewModeDirective, EditModeDirective, EditableComponent],
})
export class EditInPlaceModule {}
