import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { PhoneNumberPipe } from 'src/app/components/pipes/phoneNumber';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { Client } from '../models/client.model';
import { APSObject, APS_OBJECT_TOKEN } from 'src/app/types/globals.types';
import { gql } from 'apollo-angular';
import {
  ApolloService,
  GQLDocument,
  GQLResponse,
} from 'src/app/core/services/graphql/graphql.apollo.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  clients: BehaviorSubject<Client[]> = new BehaviorSubject([] as Client[]);
  clients$ = this.clients.asObservable();
  clientsFetchTime: string;

  queries: {
    listClients: GQLDocument;
    listClientsWithCustomers: GQLDocument;
    listClientsWithEntities: GQLDocument;
    getClient: GQLDocument;
    getClientWithEntities: GQLDocument;
  } = {
    listClients: gql`
      query ListClients($all: Boolean, $idList: [Int!]!) {
        clients(all: $all, idList: $idList) {
          ... on Client {
            id
            name
          }
          ... on NotFoundError {
            code
            message
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    listClientsWithCustomers: gql`
      query Clients($all: Boolean) {
        clients(all: $all) {
          ... on Client {
            id
            name
            customers {
              id
              name
              active
            }
          }
          ... on NotFoundError {
            code
            message
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    listClientsWithEntities: gql`
      query Clients($all: Boolean) {
        clients(all: $all) {
          ... on Client {
            id
            name
            customers {
              id
              name
              active
              facilities {
                id
                name
                buildings {
                  id
                  name
                }
              }
            }
          }
          ... on NotFoundError {
            code
            message
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    getClient: gql`
      query Client($clientId: Int!) {
        client(id: $clientId) {
          ... on Client {
            id
            name
            address {
              city
              region
              street1
              street2
              street3
              street4
              unit
              zipCode
            }
            addressFormat
            defaultFromAddress
            doNotReply
            language
            lat
            lon
            legalStatement
            longDateFormat
            maxNumDrafts
            orderRequestDisclaimer
            paperSize
            passwordDomain
            supportEmail
            shortDateFormat
            timeFormat
            reportLogo {
              description
              file
              id
              locationId
              locationType
            }
            unitSystem
            contactInfo {
              fax
              faxExt
              phoneFormat
              phoneNumber
              phoneNumberExt
            }
            timezone {
              id
              name
              offset
            }
            customers {
              id
              name
              active
            }
          }
        }
      }
    `,
    getClientWithEntities: gql`
      query ClientWithEntities($clientId: Int!) {
        client(id: $clientId) {
          ... on Client {
            id
            name
            customers {
              active
              id
              name
              facilities {
                id
                name
                buildings {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
  };

  mutations: { createClient: GQLDocument; modifyClient: GQLDocument } = {
    createClient: gql`
      mutation CreateClient(
        $file: FileInput
        $data: CreateClientInput!
        $addressData: AddressInput
      ) {
        manageClient(file: $file, data: $data, addressData: $addressData) {
          ... on Client {
            id
            name
          }
        }
      }
    `,
    modifyClient: gql`
      mutation ManageClient(
        $id: Float
        $file: FileInput
        $data: CreateClientInput!
        $addressData: AddressInput
      ) {
        manageClient(id: $id, file: $file, data: $data, addressData: $addressData) {
          ... on Client {
            id
            name
          }
        }
      }
    `,
  };

  static getReportFooterText(client: Client): string {
    const numberPipe = new PhoneNumberPipe();
    let response = '';

    if (client && client.address && client.name) {
      response = `${client?.name} ${client?.address?.street1 || ''}
      ${client?.address?.street2 || ''} ${client?.address?.city || ''}
      ${client?.address?.country || ''} ${client?.address?.zipCode || ''} ${' - '}`;

      if (client.contactInfo && client.contactInfo.phoneNumber) {
        response += ` T: ${numberPipe.transform(+client.contactInfo.phoneNumber)}`;
      }
    }

    return response;
  }

  constructor(
    //@ts-ignore
    @Inject(APS_OBJECT_TOKEN) private REMOVE_AFTER_REFACTOR: APSObject,
    private graphService: GraphqlService,
    private apolloService: ApolloService
  ) {}

  async getClients(): Promise<Client[]> {
    return this.apolloService
      .fetchQuery<{ clients: Client[] }>(this.queries.listClients, {
        all: true,
        idList: [],
      })
      .then((data) => {
        return data.clients || [];
      });
  }

  async getClientsWithCustomers(): Promise<Client[]> {
    return this.graphService
      .fetchQuery<{ clients: Client[] }>(this.queries.listClientsWithCustomers, {
        all: true,
        idList: [],
      })
      .then((data) => data.clients || []);
  }

  async getClientsWithEntities(): Promise<Client[]> {
    return this.graphService
      .fetchQuery<{ clients: Client[] }>(this.queries.listClientsWithEntities, {
        all: true,
      })
      .then((data) => data.clients || []);
  }

  async getClientWithEntities(id: number): Promise<Client> {
    return this.graphService
      .fetchQuery<{ client: Client }>(this.queries.getClientWithEntities, {
        clientId: id,
      })
      .then((data) => data.client);
  }

  async getClient(id: number): Promise<Client> {
    return this.graphService
      .fetchQuery<{ client: Client }>(this.queries.getClient, { clientId: id })
      .then((data) => data.client);
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteClient: any }>({
          mutation: gql`
            mutation DeleteClient($deleteClientId: Float!) {
              deleteClient(id: $deleteClientId)
            }
          `,
          variables: {
            deleteClientId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ deleteClient: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteClient) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
