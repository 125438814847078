import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/feature-modules/users/services/user.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { GQLResponse } from 'src/app/core/services/graphql/graphql.apollo.service';

@Component({
  selector: 'app-password-reset-required',
  templateUrl: './password-reset-required.component.html',
  styleUrls: ['./password-reset-required.component.scss'],
})
export class PasswordResetRequiredComponent implements OnInit {
  passwordLengthInvalidMin: boolean;
  passwordLengthInvalidMax: boolean;
  passwordMismatch: boolean;

  passwordResetRequiredForm: FormGroup = new FormGroup({
    password: new FormControl('', Validators.required),
    passwordConfirmation: new FormControl('', Validators.required),
    passwordCurrent: new FormControl('', Validators.required),
  });

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    public userService: UserService,
    public graphService: GraphqlService,
    private router: Router,
    public toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.passwordLengthInvalidMin = true;
    this.passwordLengthInvalidMax = false;
    this.passwordMismatch = false;
    this.passwordResetRequiredForm
      .get('password')
      ?.setValidators([Validators.required, this.passwordValidator()]);
    this.passwordResetRequiredForm
      .get('passwordConfirmation')
      ?.setValidators([Validators.required, this.passwordValidator()]);
    this.passwordResetRequiredForm
      .get('passwordCurrent')
      ?.setValidators([Validators.required]);
  }

  onInputChange() {
    this.passwordResetRequiredForm.get('password')?.updateValueAndValidity();
    this.passwordResetRequiredForm.get('passwordConfirmation')?.updateValueAndValidity();
  }

  passwordValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      let isInvalid = false;

      const password = this.passwordResetRequiredForm.get('password');
      const passwordConfirmation =
        this.passwordResetRequiredForm.get('passwordConfirmation');
      const validationErrors = {
        passwordLengthInvalidMax: this.passwordLengthInvalidMax,
        passwordLengthInvalidMin: this.passwordLengthInvalidMin,
        passwordMismatch: this.passwordMismatch,
      };

      //MinLen Check
      if (password?.value.length < 8) {
        isInvalid = true;
        this.passwordLengthInvalidMin = true;
        validationErrors.passwordLengthInvalidMin = true;
      } else {
        this.passwordLengthInvalidMin = false;
      }

      //MaxLen Check
      if (password?.value.length > 36) {
        isInvalid = true;
        this.passwordLengthInvalidMax = true;
        validationErrors.passwordLengthInvalidMax = true;
      } else {
        this.passwordLengthInvalidMax = false;
      }

      //Password Confirmed Check
      if (password?.value !== passwordConfirmation?.value) {
        isInvalid = true;
        this.passwordMismatch = true;
        validationErrors.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }

      return isInvalid ? validationErrors : null;
    };
  }

  submit() {
    lastValueFrom(
      this.graphService.mutate<{
        resetPassword?: {
          success?: boolean;
        };
      }>({
        mutation: this.userService.mutations.resetPassword,
        variables: {
          currentPassword: this.passwordResetRequiredForm.get('passwordCurrent')?.value,
          newPassword: this.passwordResetRequiredForm.get('password')?.value,
        },
      })
    )
      .then(
        (
          response: GQLResponse<{
            resetPassword?: {
              code?: number;
              message?: string;
              success?: boolean;
            };
          }>
        ) => {
          if (response.data?.resetPassword?.success) {
            this.authService.unsetPasswordResetRequired();
            this.toaster.success('Password updated successfully');
            this.router.navigate(['/app/home']);
          } else if (response.data?.resetPassword?.code) {
            throw new Error(`${response.data?.resetPassword?.message}`);
          } else {
            throw new Error('Error saving password');
          }
        }
      )
      .catch((error: string) => {
        this.errorService.log(error);
      });
  }
}
