import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app/app.service';

import { BreadcrumbNode } from './breadcrumb-node.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  defaultNodes: BreadcrumbNode[] = [];

  @Input() showPaths = false;
  @Input() hideBG = false;

  constructor(
    private router: Router,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    if (this.showPaths) {
      this.appService.getBreadcrumbs().subscribe((breadcrumbs) => {
        this.defaultNodes = [...breadcrumbs]; //, ...this.defaultNodes];
      });
    }
  }

  @Input()
  set nodes(val: BreadcrumbNode[] | null) {
    if (val) {
      this.defaultNodes = [...this.defaultNodes, ...val];
    }
  }

  onHomeSelect() {
    //this.dialog.open(SpeedBumpDialog).afterClosed().subscribe((accepted: boolean)=>{
    //if(accepted){
    this.router.navigate(['/app/home']);
    //}
    //});
  }
}
