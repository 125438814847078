import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { ReportType } from 'src/app/core/constants/report-type/report-type.enum';
import { QPTestsList } from '../../component-tests/queries/component-test.query';
import { SystemConfig } from '../models/system-config.model';
import { System } from '../models/system.model';
import {
  ApolloService,
  GQLResponse,
  GQLDocument,
} from 'src/app/core/services/graphql/graphql.apollo.service';
import { gql } from 'apollo-angular';

const QueryParamsSystem = `
  accountNumber
  id
  name
  componentSortOrder
  testTypeSortOrder
`;

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  queries: {
    list: GQLDocument;
    getSystem: GQLDocument;
    getSystemAndParents: GQLDocument;
    getUserPermission: GQLDocument;
  } = {
    list: gql`query Systems($buildingId: Int, $idList: [Int!]) {
      systems(buildingId: $buildingId, idList: $idList) {
        ... on System {
          ${QueryParamsSystem}
          createdBy
          building {
            id
          }
        }
        ... on InvalidParametersError {
          code
          message
        }
        ... on NotFoundError {
          code
          message
        }
        ... on ConflictError {
          code
          message
        }
      }
    }
    `,
    getSystem: gql`query System($systemId: Int!) {
      system(id: $systemId) {
        ... on System {
          ${QueryParamsSystem}
          building {
            accountNumber
            id
          }
        }
        ... on InvalidParametersError {
          code
          message
        }
        ... on NotFoundError {
          code
          message
        }
        ... on ConflictError {
          code
          message
        }
      }
    }`,
    getSystemAndParents: gql`query System($systemId: Int!) {
      system(id: $systemId) {
        ... on System {
          ${QueryParamsSystem}
          building {
            name
            id
            facility {
              name
              id
              customer {
                name
                id
                client {
                  name
                  id
                }
              }
            }
          }
        }
        ... on InvalidParametersError {
          code
          message
        }
        ... on NotFoundError {
          code
          message
        }
        ... on ConflictError {
          code
          message
        }
      }
    }`,
    getUserPermission: gql`
      query User($userId: Int!) {
        user(id: $userId) {
          ... on User {
            permission {
              navigation {
                systemAccess
              }
            }
          }
        }
      }
    `,
  };

  mutations: { manageSystem: GQLDocument } = {
    manageSystem: gql`
      mutation ManageSystem($data: ManageSystemInput!) {
        manageSystem(data: $data) {
          ... on System {
            id
            name
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on NotAllowed {
            message
            code
          }
        }
      }
    `,
  };

  constructor(
    private http: HttpClient,
    private apolloService: ApolloService
  ) {}

  getSystem(id: number): Promise<System> {
    return lastValueFrom(
      this.apolloService
        .query<{ system: System }>({
          query: this.queries.getSystem,
          variables: {
            systemId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ system: System }>) => {
            //console.log('system response: ', res);
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.system) {
              ret = res.data.system;
            }
            return ret;
          })
        )
    );
  }

  getAll(): Promise<System[]> {
    return lastValueFrom(
      this.apolloService
        .query<{ systems: System[] }>({
          query: gql`
            query System($all: Boolean) {
              systems(all: $all) {
                ... on System {
                  id
                  name
                  building {
                    id
                    name
                    facility {
                      name
                      id
                      customer {
                        name
                        id
                      }
                    }
                  }
                }
                ... on InvalidParametersError {
                  code
                  message
                }
                ... on NotFoundError {
                  code
                  message
                }
                ... on ConflictError {
                  code
                  message
                }
              }
            }
          `,
          variables: {
            all: true,
          },
        })
        .pipe(
          map((res: GQLResponse<{ systems: System[] }>) => {
            //console.log('system response: ', res);
            let ret: any = [];
            if (res.data && res.data.systems) {
              ret = res.data.systems;
            }
            return ret;
          })
        )
    );
  }

  getConfig(id: string) {
    return this.http
      .get(`/api/system-configs/system/${id}`)
      .pipe
      //map((val) => val  as System[])
      ();
  }

  getConfigType(system: System, type: ReportType): SystemConfig | undefined {
    const res = system.configs?.find((config) => (config.type as ReportType) === type);
    return res;
  }

  applySystemType(systemId: number, systemTypeId: number) {
    return lastValueFrom(
      this.apolloService
        .mutate<{ applySystemType: any }>({
          mutation: gql`mutation Mutation($systemTypeId: Float!, $systemId: Float!) {
            applySystemType(systemTypeId: $systemTypeId, systemId: $systemId) {
              ... on System {
                name
                id
                components {
                  active
                  id
                  longName
                  shortName
                  testSortOrder
                  ${QPTestsList}
                }
              }
            }
          }`,
          variables: {
            systemId: systemId,
            systemTypeId: systemTypeId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ applySystemType: any }>) => {
            //console.log('system response: ', res);
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.applySystemType) {
              ret = res.data.applySystemType;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.apolloService
        .mutate<{ deleteSystem: any }>({
          mutation: gql`
            mutation DeleteSystem($deleteSystemId: Float!) {
              deleteSystem(id: $deleteSystemId)
            }
          `,
          variables: {
            deleteSystemId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ deleteSystem: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteSystem) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
