import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular';
import { MaterialCssVarsModule } from 'angular-material-css-vars';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { LayoutModule } from './layouts/layout.module';
import { PublicModule } from './public/public.module';
// import { AppRequestInterceptor } from './core/interceptors/app-request.interceptor';
import { CoreModule } from './core/core.module';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import { CKEditorModule } from 'ckeditor4-angular';
import { GraphsModule } from './graphs/graphs.module';
import { MockEditorComponent } from './test/mock-editor/mock-editor.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CypressServices } from './cypress-services';
import { APS_OBJECT_TOKEN, apsObjectFactory } from './types/globals.types';
import { GraphApolloModule } from './core/services/graphql/graphql.apollo.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ShortFormatForDatePicker } from './core/services/app/shortDateFormatDataPicker.service';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

@NgModule({
  declarations: [AppComponent, MockEditorComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    CoreModule,
    HttpClientModule,
    LayoutModule,
    PublicModule,
    GraphsModule,
    CKEditorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    //SocketIoModule.forRoot(socketConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    BrowserAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    GraphApolloModule,
    MaterialCssVarsModule.forRoot({
      isAutoContrast: false,
      primary: '#ff0000',
    }),
  ],
  providers: [
    ShortFormatForDatePicker,
    { provide: APS_OBJECT_TOKEN, useFactory: apsObjectFactory },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppRequestInterceptor,
    //   multi: true,
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, CypressServices],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'never' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-icons-sharp');
  }
}
