<div class="login-container-parent" id="main-login-container">
  <div class="login-container-left" [ngClass]="{ 'logged-in': isLoggedIn }"></div>
  <div class="login-container-right" [ngClass]="{ 'logged-in': isLoggedIn }">
    <mat-card class="small-card disable-mat-effects no-border">
      <div class="login-container">
        <img class="login-logo" [src]="defaultTheme.loginLogo" />
      </div>
      <h2 class="login-caption-header">
        {{ 'titles.login' | translate }} to your Account
      </h2>

      <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
        <div class="m-top-form-input">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'labels.email' | translate }}</mat-label>
            <input #emailInput autocomplete="on" matInput formControlName="email" />
          </mat-form-field>
        </div>
        <div class="m-top-form-input">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'labels.password' | translate }}</mat-label>
            <input
              autocomplete="on"
              matInput
              formControlName="password"
              minlength="5"
              type="password"
            />
          </mat-form-field>
        </div>
        <div class="m-top-form-input">
          <app-error-list></app-error-list>
        </div>
        <div class="login-footer">
          <div class="forgot-password-caption">
            <a href [routerLink]="'/password-reset'">{{
              'actions.forgot-password' | translate
            }}</a>
          </div>

          <div class="text-right">
            <button
              class="login-btn action-button"
              type="submit"
              mat-raised-button
              [disabled]="!loginForm.valid || buttonDisable"
            >
              {{ 'Login' | translate }}
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
