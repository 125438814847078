import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ckeditor',
  templateUrl: './mock-editor.component.html',
  styleUrls: ['./mock-editor.component.scss'],
})
export class MockEditorComponent {
  @Input() config: any; //test
  @Input() content: any;
  @Input() context: any;

  @Output() contentChanged = new EventEmitter<string>();
  @Output() contentChange = new EventEmitter<string>();
}
