<div
  class="d-flex side-wrap"
  [ngClass]="[isExpanded ? 'expanded' : '', isCollapsed ? 'collapsed' : '']"
>
  <div class="menu">
    <button
      *ngFor="let item of navItems"
      [ngClass]="{ active: activeItem === item, 'main-navigation': true }"
      mat-button
      (click)="itemSelect(item)"
    >
      <i class="fa fa-sharp" [ngClass]="item.icon"></i>
      <span class="label">{{ item.label | translate }}</span>
      <span class="indicator"></span>
    </button>
  </div>
  <div class="menu sub">
    <div>SUB TOOL NAVIGATION</div>
    <button
      *ngFor="let subItem of subMenuData"
      mat-button
      (click)="collapse()"
      [routerLink]="subItem.path"
    >
      <i class="fa fa-sharp" [ngClass]="subItem.icon"></i>
      <span class="label">{{ subItem.label | translate }}</span>
      <span class="indicator"></span>
    </button>
  </div>
</div>

<div
  style="display: none"
  class="collapse-toggle"
  [ngClass]="isCollapsed ? 'rotate' : ''"
>
  <p (click)="toggle(); $event.stopPropagation()">
    <fa-icon style="height: 2em !important" [icon]="menuIcon"></fa-icon>
  </p>
</div>
