type SortFunction<T> = (a: T, b: T) => number;
type MapFunction<T, U> = (value: T, index: number, array: T[]) => U;
type SortOrderMap = Map<number, number>;

// Dot notation because we have a lot of freekin dynamic props going
// on here. so getDotNotation({name: {first: 'fred'}}, 'name.first') would return 'fred' or null
export const getDotNotation = <T>(obj: T, path: string): any => {
  return path.split('.').reduce((acc, part) => {
    return acc && (acc as any)[part] ? (acc as any)[part] : null;
  }, obj as any);
};

// Creates a sort order map for sorting components
// incoming items looks like this [44,23,1,56,2]
// result looks like this {'44': 0, '23': 1, '1': 2, '56': 3, '2': 4}
export const createSortOrderMap = <T extends object>(
  items: T[],
  key: keyof T
): SortOrderMap => {
  const map = new Map<number, number>();
  items.forEach((item, index) => {
    const keyValue = item[key] as unknown as number;
    map.set(keyValue, index);
  });
  return map;
};

// Sort an array by prop and direction
// example const fred = sortArray(['test', 'booboo'], undefined, 'asc') returns ['booboo', 'test']
// example const fred = sortArray([{name: 'test'}, {name: 'booboo'}], 'name', 'desc') returns [{name: 'test'}, {name: 'booboo'}]
export const sortArray = <T extends object>(
  value: T[],
  prop?: string,
  direction: 'asc' | 'desc' = 'asc'
): T[] => {
  if (!prop) return value;

  return [...value].sort((a, b) => {
    const aValue = getDotNotation(a, prop);
    const bValue = getDotNotation(b, prop);

    const aStr = String(aValue).toLowerCase();
    const bStr = String(bValue).toLowerCase();

    return direction === 'asc' ? aStr.localeCompare(bStr) : bStr.localeCompare(aStr);
  });
};

// Same as above but for dates.
export const sortArrayByDate = <T extends { [key: string]: any }>(
  value: T[],
  prop: string,
  direction: 'asc' | 'desc' = 'asc'
): T[] => {
  return [...value].sort((a, b) => {
    const aValue = new Date(a[prop]).getTime();
    const bValue = new Date(b[prop]).getTime();

    return direction === 'asc' ? aValue - bValue : bValue - aValue;
  });
};

// This will create a map from an array and sort it using a sort function
// this is used for normal sorting
export const normalMapWithSortFn = <T, U>(
  array: T[],
  mapFn: MapFunction<T, U>,
  sortFn?: SortFunction<U>
): U[] => {
  const mappedArray = array.map(mapFn);
  return sortFn ? [...mappedArray].sort(sortFn) : mappedArray;
};

// This will create a map and sort it by a incoming sort order map
// this is used in results that contain sort orders like componentSortOrder
export const normalMapWithSortOrder = <T, U>(
  array: T[],
  mapFn: MapFunction<T, U>,
  sortOrderMap: SortOrderMap,
  sortProp: keyof U
): U[] => {
  const mappedArray = array.map(mapFn);
  return mappedArray.sort((a, b) => {
    const aSortOrder = sortOrderMap.get(a[sortProp] as unknown as number) || 999;
    const bSortOrder = sortOrderMap.get(b[sortProp] as unknown as number) || 999;
    return aSortOrder - bSortOrder;
  });
};

/*
// Example of using componentSortOrder and then a normalMapWithSortOrder
map((res: GQLResponse<{ system: System }>) => {
    if (res.data && res.data.system) {
        const system = {...res.data.system};
        if (system.componentSortOrder) { 
        const sortOrderMap = createSortOrderMap(
            system.componentSortOrder.map(id => ({ id })), 'id'
        );
 
        system.components = normalMapWithSortOrder(
            system.components,
            component => ({ ...component, sortOrder: sortOrderMap.get(component.id) || 999 }),
            sortOrderMap,
            'sortOrder'
        );
        }

        return system;
    } else {
        throw new Error('No system data found');
    }
})


// Example of using createSortOrderMap and normalMapWithSortOrder
sortComponents(components: SystemComponent[]): SystemComponent[] {
    if (this.system.componentSortOrder) { 
      const sortOrderMap = createSortOrderMap(
        this.system.componentSortOrder.map(id => ({ id })), 'id'
      );
 
      return normalMapWithSortOrder(
        components,
        component => component, 
        sortOrderMap,
        'id'  
      );
    }
    return components;
  }
}
*/
