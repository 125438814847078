import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { BuildingService } from 'src/app/features/buildings/services/building.service';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { ClientService } from 'src/app/feature-modules/clients/services/client.service';
import { FacilityService } from 'src/app/features/facilities/services/facility.service';
import { SystemService } from 'src/app/features/systems/services/system.service';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { AppService } from 'src/app/core/services/app/app.service';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { CustomerService } from 'src/app/feature-modules/customers/services/customer.service';
import { EntitySelections } from './models/entity-selections.model';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { Facility } from 'src/app/features/facilities/models/facility.model';
import { Building } from 'src/app/features/buildings/models/building.model';
import { System } from 'src/app/features/systems/models/system.model';
import { DialogSystemNavComponent, SystemNavModes, } from './dialog-system-nav/dialog-system-nav.component';
import { NavigationOverview } from 'src/app/core/models/permissions/permissions.model';
import { EntitySelectService } from './services/entity-select.service';
import { FavoritesService } from 'src/app/core/services/favorites/favorites.service';
import { StorageKeySystemHistory } from 'src/app/core/constants/cookies/storage-system-history.constant';
import { User } from 'src/app/feature-modules/users/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { ApolloService, GQLResponse, } from 'src/app/core/services/graphql/graphql.apollo.service';
import { sortArray } from 'src/app/helpers/string.helpers';
import { LocalStorageService } from 'src/app/core/services/app/localStorage.service';

/**
 * @description The Entity Select component arranges multiple EntityList components
 * and coordinates quick navigation in the site.
 */

enum EntitySelectViewModes {
  Full = 'Full',
  Condensed = 'Condensed',
}
interface EntitySelectFieldControl {
  client: boolean;
  customer: boolean;
  facility: boolean;
  building: boolean;
  system: boolean;
}

export const StorageKeyEntities = 'APS-Entities';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss'],
})
export class EntitySelectComponent implements OnInit {
  @Input()
  viewMode: string = EntitySelectViewModes.Full;

  /** TODO - deprecate ?  */
  fieldControl: EntitySelectFieldControl = {
    client: true,
    customer: true,
    facility: true,
    building: true,
    system: true,
  };

  selections: EntitySelections;

  dataOptions = {
    client: <EntityOption[]>[],
    customer: <EntityOption[]>[],
    facility: <EntityOption[]>[],
    building: <EntityOption[]>[],
    system: <EntityOption[]>[],
  };

  permissions: NavigationOverview = {} as NavigationOverview;

  loadingMessage = '';

  menuToggle = false;
  inputToggle = false;
  favoriteToggle = false;

  // For the Dialog
  SystemNavModes = SystemNavModes;

  // User Type control
  preset = {
    client: false,
    customer: false,
  };

  errors: any[] = [];

  currentUser: User;
  userAccess: any;

  // Dynamic labels
  labels = {
    initial: 'labels.client',
    initialSelect: 'actions.select-client',
  };

  favorites: EntityOption[] = [];

  subs: Subscription[] = [];

  constructor(
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
    private clientService: ClientService,
    private customerService: CustomerService,
    private facilityService: FacilityService,
    private buildingService: BuildingService,
    private systemService: SystemService,
    private errorService: ErrorService,
    private location: Location,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private entitySelectService: EntitySelectService,
    private favoritesService: FavoritesService,
    private translate: TranslateService,
    private apolloService: ApolloService,
    private localStorageService : LocalStorageService
  ) {
    this.selections = {
      client: this.appService.viewState.client,
      customer: this.appService.viewState.customer,
      facility: this.appService.viewState.facility,
      building: this.appService.viewState.building,
      system: this.appService.viewState.system,
    };
  }

  ngOnInit(): void {
    this.checkUserAccess();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /*watchRouteChanges(){
   const sub1 = this.router.events
      .pipe(filter((val) => val instanceof NavigationEnd))
      .subscribe((ev) => {
        ev = ev as NavigationEnd;
        const status = this.authService.status.getValue();
        const clientCustomerPaths = [
          '/app/customer-contacts',
        ];
        const clientPaths = [
          '/app/products',
          '/app/report-phrases',
          '/app/report-phrases/categories'
        ];

        if (clientCustomerPaths.includes(ev.url)) {
          this.fieldControl = {
            client: status.user?.client?.id ? false : true,
            customer: status.user?.customer?.id ? false : true,
            system: false,
            building: false,
            facility: false,
          };
        } else if (clientPaths.includes(ev.url)) {
          this.fieldControl = {
            client: status.user?.client?.id ? false : true,
            customer: false,
            system: false,
            building: false,
            facility: false,
          };
        } else {
          this.resetFieldControls();
          // TODO - Can this be conditional?
        }
      });
      this.subs.push(sub1);
  }*/

  checkUserAccess() {
    const notifier = new Subject();
    this.authService.status$.pipe(takeUntil(notifier)).subscribe((status: AuthStatus) => {
      if (status.user?.userAccess) {
        this.userAccess = status.user.userAccess;
      }
      if (!status.user) {
        this.resetClient();
        this.fieldControl = {
          client: true,
          customer: true,
          facility: true,
          building: true,
          system: true,
        };
        this.dataOptions.client = [];
        return;
      }

      if (!status.hasSession) {
        return;
      }

      notifier.next(false);
      this.currentUser = status.user;
      this.permissions =
        status.user?.permission?.navigation || ({} as NavigationOverview);

      if (status.user && status.user.customer?.id) {
        this.preset.client = true;
        this.preset.customer = true;
        this.setUserClient(status);
        this.setUserCustomer(status);
      } else if (status.user && status.user.client?.id) {
        this.preset.client = true;
        this.preset.customer = false;
        this.setUserClient(status);
      } else {
        this.preset.client = false;
        this.preset.customer = false;
        this.fieldControl.client = true;
        this.fieldControl.customer = true;
        this.loadClients();
      }

      /*
      this.configService.config$.subscribe((config: AppConfig) => {
        if (config.regionBased) {
          this.labels.initial = 'titles.regions.list';
          this.labels.initialSelect = 'actions.select-region';
        }
      });
      */
    });

    this.enableResetTrigger();
    this.enableDirectSystemNavigation();
    this.enableClientLoadTrigger();
    this.enableCustomerLoadTrigger();
    this.enableFacilityLoadTrigger();
    this.enableBuildingLoadTrigger();
    this.enableSystemLoadTrigger();
  }

  checkCookiesSelection() {
    const selections = JSON.parse(localStorage.getItem(StorageKeyEntities) || '{}');

    if (selections) {
      if (selections.system) {
        // load System
        this.loadingMessage = '...restoring previous System selection.';

        this.entitySelectService
          .getSystemAndParents(+selections.system)
          .then((system: System | null) => {
            this.loadingMessage = '';
            if (system) {
              const chain = AppService.deconstructSystem(system);

              this.onClientSelect(chain.client);
              this.onCustomerSelect(chain.customer);
              this.onFacilitySelect(chain.facility);
              this.onBuildingSelect(chain.building);

              Object.keys(chain).forEach((key) => {
                this.appService.subjects[key].next(chain[key]);
              });
            }
          });
      } else if (selections.building) {
        // load building with parent objects,
        this.loadingMessage = '...restoring previous Building selection.';
        this.buildingService
          .getBuildingWithParents(selections.building)
          .then((building: Building) => {
            const facility = building.facility;
            let customer: Customer;
            let client: Client;

            this.loadingMessage = '';

            if (facility && facility.customer) {
              customer = facility.customer;
            } else {
              return;
            }

            if (customer && customer.client) {
              client = customer.client as Client;
              if (client) {
                this.onClientSelect(client);
                this.onCustomerSelect(customer);
                this.onFacilitySelect(facility);
                this.onBuildingSelect(building);
              }
            }
          });
      } else if (selections.facility) {
        this.loadingMessage = '...restoring previous Facility selection.';
        this.facilityService
          .getFacilityWithParents(selections.facility)
          .then((facility: Facility) => {
            this.loadingMessage = '';
            if (facility) {
              const customer = facility.customer;
              let client;

              if (customer) {
                client = customer.client as Client;
              } else {
                return;
              }

              this.onClientSelect(client);
              this.onCustomerSelect(customer);
              this.onFacilitySelect(facility);
            }
          });
      } else if (selections.customer) {
        this.loadingMessage = '...restoring previous Customer selection.';
        this.customerService
          .getCustomerWithEntities(selections.customer)
          .then((customer: Customer) => {
            this.loadingMessage = '';
            if (customer) {
              const client = customer.client as Client;
              if (client) {
                this.onClientSelect(client);
                this.onCustomerSelect(customer);
              }
            }
          });
      } else if (selections.client) {
        this.loadingMessage = '...restoring previous Client selection.';
        this.loadClient(selections.client).then(() => {
          this.loadingMessage = '';
        });
      }
    }
  }

  // Allow external components to reset the component values via AppService
  enableResetTrigger() {
    const sub1 = this.appService.quickNavReset
      .asObservable()
      .subscribe((keys: string[]) => {
        this.reset(keys);
      });
    this.subs.push(sub1);
  }

  enableDirectSystemNavigation() {
    this.appService.systemNavigationState.subscribe((system: System | null) => {
      if (system) {
        const chain = AppService.deconstructSystem(system);

        this.onClientSelect(chain.client);
        this.onCustomerSelect(chain.customer);
        this.onFacilitySelect(chain.facility);
        this.onBuildingSelect(chain.building);

        Object.keys(chain).forEach((key) => {
          if (chain[key]) {
            const val = chain[key];
            this.appService.subjects[key].next(val);
          }
        });

        this.checkSystemNavigation();
        this.appService.saveEntities();
      }
    });
  }

  // Allow external components to load Clients via AppService
  enableClientLoadTrigger() {
    const sub1 = this.appService.clientRefresh.subscribe(() => {
      this.loadClients();
    });
    this.subs.push(sub1);
  }

  enableCustomerLoadTrigger() {
    const sub1 = this.appService.customerRefresh.subscribe(() => {
      const client = this.appService.subjects.client.getValue();
      if (client && client.id) {
        this.loadClient(client.id); // Customers loaded as children
      }
    });
    this.subs.push(sub1);
  }

  enableFacilityLoadTrigger() {
    const sub1 = this.appService.facilityRefresh.subscribe(() => {
      const customer = this.appService.subjects.customer.getValue();
      if (customer && customer.id) {
        this.loadFacilities(customer.id);
      }
    });
    this.subs.push(sub1);
  }

  enableBuildingLoadTrigger() {
    const sub1 = this.appService.buildingRefresh.subscribe(() => {
      const facility = this.appService.subjects.facility.getValue();
      if (facility && facility.id) {
        this.loadBuildings(facility.id);
      }
    });
    this.subs.push(sub1);
  }

  enableSystemLoadTrigger() {
    const sub1 = this.appService.systemRefresh.subscribe(() => {
      const building = this.appService.subjects.building.getValue();
      if (building && building.id) {
        this.loadSystems(building.id);
      }
    });
    this.subs.push(sub1);
  }

  loadClients(): void {
    this.clientService
      .getClients()
      .then((clients: Client[]) => {
        this.dataOptions.client = clients;
        if (clients.length === 1) {
          this.onClientSelect(clients[0]);
        }
        this.checkCookiesSelection();
      })
      .catch(() => {
        // nothing
      });
  }

  setUserClient(status: AuthStatus): void {
    const id = status.user?.client?.id;
    if (id) {
      this.fieldControl.client = false;
      setTimeout(() => {
        this.loadClient(id).then(() => {
          this.checkCookiesSelection();
        });
      });
    }
  }

  setUserCustomer(status: AuthStatus): void {
    const id = status.user?.customer?.id;
    if (id) {
      this.fieldControl.customer = false;
      this.fieldControl.client = false;
      setTimeout(() => {
        this.loadCustomer(id).then(() => {
          this.checkCookiesSelection();
        });
      });
    }
  }

  resetFieldControls(): void {
    const status = this.authService.status.getValue();
    this.fieldControl = {
      // Extra ternary added, Customer should not exist without Client
      client: status.user?.customer?.id ? false : status.user?.client?.id ? false : true,
      customer: status.user?.customer?.id ? false : true,
      facility: true,
      building: true,
      system: true,
    };
  }

  /* --- Data Loading functions --- */

  loadClient(id: number) {
    return this.clientService
      .getClient(id)
      .then((_client: Client) => {
        //clear local storage if key/value is available.
        this.localStorageService.removeStorage('client_selected_long_short_Date_Format');
        
        const client = _client;
        
        //Add Short_Long Date formate which is client selected in localStorage.              
        this.localStorageService.setStorage('client_selected_long_short_Date_Format', {"short" : client.shortDateFormat, "long" : client.longDateFormat});                
        
        let customers = client.customers || [];

        // Sort customers by name
        customers = customers.map((cust: Customer) => {
          return {
            ...cust,
          };
        });
        // .sort((a: Customer, b: Customer) =>
        //   a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        // );

        customers = sortArray(customers, 'name', 'asc');
        this.dataOptions.customer = customers.filter((c) => c.active);
        this.appService.setClient(client);
        this.appService.saveEntities();
        this.appService.entityStore.addCustomers(client.id, customers);
        if (customers.length === 1 && this.fieldControl.customer) {
          this.onCustomerSelect(customers[0]);
        }

        return client;
      })
      .catch((e: any) => {
        this.errors.push(e);
      });
  }

  loadCustomer(id: number) {
    return this.customerService
      .getCustomerWithFacilities(id)
      .then((customer: Customer) => {
        if (customer) {
          this.appService.setCustomer(customer);
          this.appService.saveEntities();
          let facilities = (customer.facilities || []) as Facility[];
          // Apply user access
          if (this.userAccess) {
            facilities = facilities.filter(
              (facility) =>
                this.userAccess.facilityIds.includes(facility.id) ||
                (this.userAccess.customerIds.includes(customer.id) &&
                  !customer.facilities
                    ?.map((fac) => fac.id)
                    .some((id) => this.userAccess.facilityIds.includes(id))) ||
                (facility.createdBy && facility.createdBy === this.currentUser.id)
            );
          }
          // Sort facilities by name
          facilities = facilities.map((fac: Facility) => {
            return {
              ...fac,
            };
          });
          facilities = sortArray(facilities, 'name', 'asc');
          // .sort((a: Facility, b: Facility) =>
          //   a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          // );
          this.appService.entityStore.addFacilities(customer.id, facilities);
          this.dataOptions.facility = facilities as EntityOption[];
          if (facilities.length === 1 && this.fieldControl.facility) {
            this.onFacilitySelect(facilities[0] as EntityOption);
          }
        }
      })
      .catch((e: any) => {
        this.errors.push(e);
      });
  }

  loadFacilities(customerId: number): void {
    lastValueFrom(
      this.apolloService.query<{ facilitys: Facility[] }>({
        query: this.facilityService.queries.list,
        variables: {
          customerId: customerId,
          idList: [],
        },
      })
    )
      .then((res: GQLResponse<{ facilitys: Facility[] }>) => {
        let facilities = res.data.facilitys;
        if (this.userAccess) {
          facilities = facilities.filter(
            (facility) =>
              this.userAccess.facilityIds.includes(facility.id)  ||
              (facility.createdBy && facility.createdBy === this.currentUser.id)
          );
        }
        // Sort facilities by name
        facilities = facilities.map((fac: Facility) => {
          return {
            ...fac,
          };
        });
        facilities = sortArray(facilities, 'name', 'asc');
        // .sort((a: Facility, b: Facility) =>
        //   a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        // );

        this.dataOptions.facility = facilities;
        this.appService.entityStore.addFacilities(customerId, facilities);
        if (facilities.length === 1 && this.fieldControl.facility) {
          this.onFacilitySelect(facilities[0]);
        }
      })
      .catch(() => {
        // Nothing
      });
  }

  loadBuildings(facilityId: number): void {
    lastValueFrom(
      this.apolloService.query<{ buildings: any[] }>({
        query: this.buildingService.queries.list,
        variables: {
          facilityId: facilityId,
          idList: [],
        },
      })
    )
      .then((res: GQLResponse<{ buildings: any }>) => {
        let buildings = res.data.buildings || [];
        // Apply user access
        if (this.userAccess) {
          buildings = buildings.filter(
            (building: any) =>
              this.userAccess.buildingIds.includes(building.id) ||
              ((this.userAccess.facilityIds.includes(facilityId) ||
                this.userAccess.customerIds.includes(building.facility.customer.id)) &&
                !buildings
                  ?.map((build: any) => build.id)
                  .some((id: number) => this.userAccess.buildingIds.includes(id))) ||
                (building.createdBy && building.createdBy === this.currentUser.id)
          );
        }
        // Sort buildings by name
        buildings = buildings.map((build: Building) => {
          return {
            ...build,
          };
        });
        buildings = sortArray(buildings, 'name', 'asc');
        // .sort((a: Building, b: Building) =>
        //   a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        // );
        this.dataOptions.building = buildings;
        this.appService.entityStore.addBuildings(facilityId, buildings);
        if (buildings.length === 1 && this.fieldControl.building) {
          this.onBuildingSelect(buildings[0]);
        }
      })
      .catch(() => {
        // Nothing
      });
  }

  loadSystems(buildingId: number): Promise<System[]> {
    return (
      lastValueFrom(
        this.apolloService.query<{ systems: System[] }>({
          query: this.systemService.queries.list,
          variables: {
            buildingId: buildingId,
            idList: [],
          },
        })
      )
        //this.systemService.getSystem(id)
        .then((res: GQLResponse<{ systems: System[] }>) => {
          let systems = res.data.systems;

          // Sort systems by name
          systems = systems.map((sys: System) => {
            return {
              ...sys,
            };
          });
          systems = sortArray(systems, 'name', 'asc');
          // .sort((a: System, b: System) =>
          //   a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          // );

          this.dataOptions.system = systems;
          this.appService.entityStore.addSystems(buildingId, systems);
          if (systems.length === 1 && this.fieldControl.system) {
            this.onSystemSelect(systems[0]);
          }
          return systems;
        })
    );
  }

  /* --- Dropdown Select Handlers --- */

  onClientSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetClient();
      return;
    }
    const current = this.appService.subjects.client.getValue();
    if (current) {
      this.reset([
        'customer',
        'facility',
        'building',
        'system',
      ]);
    }
    const store = this.appService.entityStore.lookupCustomers[value.id];
    if (store) {
      this.appService.setClient(value as Client);
      this.dataOptions.customer = store.filter((customer) => customer.active);
      if (store.length === 1 && this.fieldControl.customer) {
        this.onCustomerSelect(store[0]);
      }
      this.loadClient(value.id);
    } else {
      this.loadClient(value.id);
    }
  }

  onCustomerSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetCustomer();
      return;
    }

    const current = this.appService.subjects.customer.getValue();
    if (current) {
      this.reset(['facility', 'building', 'system']);
    }

    const store = this.appService.entityStore.lookupFacilities[value.id];
    if (store) {
      this.appService.setCustomer(value as Customer);
      this.dataOptions.facility = store;
      if (store.length === 1 && this.fieldControl.facility) {
        this.onFacilitySelect(store[0]);
      }
    } else {
      this.loadCustomer(value.id);
    }
  }

  onFacilitySelect(value: Facility | null): void {
    if (!value) {
      this.resetFacility();
      return;
    }
    const current = this.appService.subjects.facility.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['building', 'system']);
    }

    this.appService.setFacility(value as Facility);
    this.appService.saveEntities();

    const store = this.appService.entityStore.lookupBuildings[value.id];
    if (store) {
      this.dataOptions.building = store;
      if (store.length === 1 && this.fieldControl.building) {
        this.onBuildingSelect(store[0]);
      }
    } else {
      this.loadBuildings(value.id);
    }
  }

  onBuildingSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetBuilding();
      return;
    }

    const current = this.appService.subjects.building.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['system']);
    }

    this.appService.setBuilding(value as Building);
    this.appService.saveEntities();

    const store = this.appService.entityStore.lookupSystems[value.id];
    if (store) {
      this.dataOptions.system = store;
      if (store.length === 1 && this.fieldControl.system) {
        this.onSystemSelect(store[0]);
      }
    } else {
      this.loadSystems(value.id);
    }
  }

  onSystemSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetSystem();
    } else {
      this.appService.setSystem(value as System);

      this.appService.saveEntities();
      this.checkSystemNavigation();
      const history = this.appService.trackHistory(value as System);
      this.appService.setCookie(StorageKeySystemHistory, history);
    }
  }

  checkSystemNavigation() {
    if (this.location.isCurrentPathEqualTo('/app/home')) {
      this.router.navigate(['/app/system']);
    }
  }

  /* --- State Control functions --- */

  reset(keys: string[]) {
    keys.forEach((key) => {
      this.appService.subjects[key]?.next(null);
    });
    this.appService.saveEntities();
  }

  openDashboard() {
    this.router.navigate(['/app/system']);
  }

  resetClient() {
    this.reset([
      'client',
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetCustomer() {
    // let path = ['app', 'client'];
    // if (this.preset.client) {
    //   path = ['app', 'home'];
    // }
    this.reset([
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetFacility() {
    // let path = ['app', 'customer'];
    // if (this.preset.customer) {
    //   path = ['app', 'home'];
    // }
    this.reset(['facility', 'building', 'system']);
  }

  resetBuilding() {
    this.reset(['building', 'system']);
  }

  resetSystem() {
    this.reset(['system']);
  }

  openSystemNavigation(mode: SystemNavModes) {
    this.favoritesService.getFavorites().then((favorites: EntityOption[]) => {
      const dialogRef = this.dialog.open(DialogSystemNavComponent, {
        data: {
          mode: mode,
          favorites: favorites,
        },
        minWidth: '50rem',
        maxWidth: '336px',
      });
      dialogRef.beforeClosed().subscribe(() => {});
    });
  }

  removeClient(selection: EntityOption) {
    this.clientService
      .delete(selection.id)
      .then((res: boolean) => {
        if (res) {
          this.resetClient();
          this.loadClients();
          this.showDeleteSuccess('labels.client');
        }
      })
      .catch((e: any) => {
        this.errorService.log(e, true);
      });
  }

  removeCustomer(selection: EntityOption) {
    this.customerService
      .delete(selection.id)
      .then((res: boolean) => {
        if (res) {
          const client = this.appService.subjects.client.getValue();
          if (client) {
            const clientCustomers =
              this.appService.entityStore.lookupCustomers[client.id] ?? [];
            const customerIndex = clientCustomers.findIndex(
              (cu) => cu.id == selection.id
            );
            if (customerIndex > -1) {
              clientCustomers.splice(customerIndex, 1);
            }
            this.onClientSelect(client);
          }
          this.showDeleteSuccess('labels.customer');
        }
      })
      .catch((e) => {
        this.errorService.log(e, true);
      });
  }

  removeFacility(selection: EntityOption) {
    this.facilityService
      .delete(selection.id)
      .then((res: boolean) => {
        if (res) {
          const customer = this.appService.subjects.customer.getValue();
          if (customer) {
            const customerFacilities =
              this.appService.entityStore.lookupFacilities[customer.id] ?? [];
            const facilityIndex = customerFacilities.findIndex(
              (fac) => fac.id == selection.id
            );
            if (facilityIndex > -1) {
              customerFacilities.splice(facilityIndex, 1);
            }
            this.onCustomerSelect(customer);
          }
          this.showDeleteSuccess('labels.facility');
        }
      })
      .catch((e) => {
        this.errorService.log(e, true);
      });
  }

  removeBuilding(selection: EntityOption) {
    this.buildingService
      .delete(selection.id)
      .then((res: boolean) => {
        if (res) {
          const facility = this.appService.subjects.facility.getValue();
          if (facility) {
            const facilityBuildings =
              this.appService.entityStore.lookupBuildings[facility.id] ?? [];
            const facilityIndex = facilityBuildings.findIndex(
              (bu) => bu.id == selection.id
            );
            if (facilityIndex > -1) {
              facilityBuildings.splice(facilityIndex, 1);
            }
            this.onFacilitySelect(facility);
          }
          this.showDeleteSuccess('labels.building');
        }
      })
      .catch((e) => {
        this.errorService.log(e, true);
      });
  }

  removeSystem(selection: EntityOption) {
    this.systemService
      .delete(selection.id)
      .then((res: boolean) => {
        if (res) {
          const building = this.appService.subjects.building.getValue();
          if (building) {
            const buildingSystems =
              this.appService.entityStore.lookupSystems[building.id] ?? [];
            const systemIndex = buildingSystems.findIndex(
              (sys) => sys.id == selection.id
            );
            if (systemIndex > -1) {
              buildingSystems.splice(systemIndex, 1);
            }
            this.loadSystems(building.id);
          }
          this.showDeleteSuccess('labels.system');
        }
      })
      .catch((e) => {
        this.errorService.log(e, true);
      });
  }

  showDeleteSuccess(typeKey: string) {
    const type = this.translate.instant(typeKey);
    const message = this.translate.instant('messages.record-success-delete', {
      type: type,
    });
    this.toaster.success(message);
  }
}
