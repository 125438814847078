import {
  differenceInDays,
  endOfDay,
  formatISO,
  startOfYear,
  subDays,
  subYears,
  startOfMonth,
  startOfDay,
} from 'date-fns';

interface TimeFilterOption {
  label: string;
  value: number | string;
  fromDate?: string;
  toDate?: string;
}

const TimeFilterOptions: TimeFilterOption[] = [
  {
    label: 'Today',
    value: 1,
    fromDate: formatISO(startOfDay(new Date())),
    toDate: formatISO(endOfDay(new Date())),
  },

  {
    label: 'This Month',
    value: 28,
    fromDate: formatISO(startOfMonth(new Date())),
    toDate: formatISO(endOfDay(new Date())),
  },

  {
    label: 'Past 30 Days',
    value: 30,
    fromDate: formatISO(startOfDay(subDays(new Date(), 30))),
    toDate: formatISO(endOfDay(new Date())),
  },

  {
    label: 'Past 90 Days',
    value: 90,
    fromDate: formatISO(startOfDay(subDays(new Date(), 90))),
    toDate: formatISO(endOfDay(new Date())),
  },
  {
    label: 'Past 180 Days',
    value: 180,
    fromDate: formatISO(startOfDay(subDays(new Date(), 180))),
    toDate: formatISO(endOfDay(new Date())),
  },
  {
    label: 'Past 365 Days',
    value: 365,
    fromDate: formatISO(startOfDay(subDays(new Date(), 365))),
    toDate: formatISO(endOfDay(new Date())),
  },

  {
    label: 'This Year',
    value: calculateDaysToYearStart(),
    fromDate: formatISO(startOfYear(new Date())),
    toDate: formatISO(endOfDay(new Date())),
  },
  {
    label: 'Last 5 Years',
    value: 1825,
    fromDate: formatISO(startOfDay(subYears(new Date(), 5))),
    toDate: formatISO(endOfDay(new Date())),
  },
  {
    label: 'Custom',
    value: 0,
    fromDate: formatISO(startOfDay(new Date())),
    toDate: formatISO(endOfDay(new Date())),
  },
];

function calculateDaysToYearStart() {
  const yearStart = startOfYear(new Date());
  const numberOfDays = differenceInDays(new Date(), yearStart);
  return numberOfDays;
}

export { TimeFilterOptions, TimeFilterOption };
