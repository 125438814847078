import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Subject } from 'rxjs';
import { Facility, FacilityFull } from '../models/facility.model';
import { User } from 'src/app/feature-modules/users/models/user.model';
import { gql } from 'apollo-angular';
import {
  ApolloService,
  GQLDocument,
  GQLResponse,
} from 'src/app/core/services/graphql/graphql.apollo.service';

export const QPFacilityWithParents = `
  id
  name
  customer {
    id
    name
    client {
      id
      name
    }
  }
  `;

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  facilities: Subject<Facility[]> = new Subject();

  facilities$ = this.facilities.asObservable();

  queries: {
    list: GQLDocument;
    getFacility: GQLDocument;
    getCustomer: GQLDocument;
  } = {
    list: gql`
      query Facilities($customerId: Int, $idList: [Int!]) {
        facilitys(customerId: $customerId, idList: $idList) {
          ... on Facility {
            name
            id
            createdBy
            buildings {
              id
              name
            }
          }
          ... on ConflictError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `,
    getFacility: gql`
      query Facility($facilityId: Int!) {
        facility(id: $facilityId) {
          ... on Facility {
            id
            name
            notes
            contactInfo {
              fax
              faxExt
              phoneFormat
              phoneNumber
              phoneNumberExt
            }
            timezone {
              abbreviation
              custom
              id
              name
              offset
            }
            address {
              street1
              street2
              street3
              street4
              unit
              city
              region
              zipCode
              country
              id
            }
            addressFormat
            accountNumber
            lat
            lon
            showCountryOnRep
            uomSystem
            customer {
              id
              name
              timezone {
                abbreviation
                custom
                id
                name
                offset
              }
              client {
                id
                name
                timezone {
                  abbreviation
                  custom
                  id
                  name
                  offset
                }
              }
            }
          }
        }
      }
    `,
    getCustomer: gql`
      query Customer($customerId: Int!) {
        customer(id: $customerId) {
          ... on Customer {
            id
            address {
              city
              region
              street1
              street2
              street3
              street4
              unit
              zipCode
            }
            addressFormat
            contactInfo {
              phoneFormat
            }
            name
            timezone {
              id
            }
            contactInfo {
              phoneFormat
            }
          }
        }
      }
    `,
  };

  mutations: { manageFacility: GQLDocument } = {
    manageFacility: gql`
      mutation ManageFacility($data: ManageFacilityInput!, $addressData: AddressInput) {
        manageFacility(data: $data, addressData: $addressData) {
          ... on Facility {
            id
            name
          }
          ... on InvalidParametersError {
            message
            code
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on NotAllowed {
            message
            code
          }
        }
      }
    `,
  };

  constructor(
    private http: HttpClient,
    private apolloService: ApolloService
  ) {}

  getFacilities(customerId: number): Promise<Facility[]> {
    const res = this.http.get(`/api/customer/${customerId}/facilities`).pipe(
      map((val) => {
        // const facilities = val as EntityOption[];
        //this.facilities.next(facilities);
        return val as Facility[];
      })
    );
    return lastValueFrom(res);
  }

  getFacility(id: number): Promise<FacilityFull> {
    return lastValueFrom(
      this.apolloService
        .query<{ facility: FacilityFull }>({
          query: this.queries.getFacility,
          variables: {
            facilityId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ facility: FacilityFull }>) => {
            let ret: any;
            if (res.data && res.data.facility) {
              const facility = res.data.facility;
              ret = facility;
            }
            return ret;
          })
        )
    );
  }

  getFacilityWithParents(id: number): Promise<Facility> {
    return lastValueFrom(
      this.apolloService
        .query<{ facility: Facility }>({
          query: gql`query Facility($facilityId: Int!) {
            facility(id: $facilityId) {
              ... on Facility {
                ${QPFacilityWithParents}
              }
            }
          }`,
          variables: {
            facilityId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ facility: Facility }>) => {
            let ret: any;
            if (res.data && res.data.facility) {
              const facility = res.data.facility;
              ret = facility;
            }
            return ret;
          })
        )
    );
  }

  getFacilityAccess(customerId: number, facilityId: number): Promise<User[]> {
    return lastValueFrom(
      this.apolloService
        .query<any>({
          query: gql`
            query Query($customerId: Float!, $facilityId: Float!) {
              facilityAccess(customerId: $customerId, facilityId: $facilityId) {
                ... on User {
                  id
                  firstName
                  lastName
                  status
                }
                ... on InvalidParametersError {
                  message
                  code
                }
                ... on ForbiddenError {
                  message
                  code
                }
                ... on NotFoundError {
                  message
                  code
                }
                ... on ConflictError {
                  message
                  code
                }
                ... on UnprocessableEntityError {
                  message
                  code
                }
              }
            }
          `,
          variables: {
            customerId: customerId,
            facilityId: facilityId,
          },
        })
        .pipe(
          map((res: GQLResponse<{ facilityAccess: User[] }>) => {
            let ret: any;
            if (res.data && res.data.facilityAccess) {
              ret = res.data.facilityAccess;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.apolloService
        .mutate<{ deleteFacility: any }>({
          mutation: gql`
            mutation DeleteFacility($deleteFacilityId: Float!) {
              deleteFacility(id: $deleteFacilityId)
            }
          `,
          variables: {
            deleteFacilityId: id,
          },
        })
        .pipe(
          map((res: GQLResponse<{ deleteFacility: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteFacility) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
