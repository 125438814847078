import { ReportType } from '../report-type/report-type.enum';

const AppActions: any = {
  reports: {
    ReportsCreate: {
      label: 'features.reports.create',
      icon: 'fa-pen',
      path: '/app/reports/new',
    },
    ReportsSetup: {
      label: 'features.reports.setup',
      icon: 'fa-wrench',
      path: '/app/report-templates',
    },
    ReportsView: {
      label: 'features.reports.view',
      icon: 'fa-list',
      path: '/app/reports',
    },
    ReportsFlex: {
      label: 'features.reports.flex',
      icon: '',
      path: '/app/reports/flex-templates',
    },
    ServiceReport: {
      label: 'Service Report',
      icon: 'fa-file-signature',
      path: '/app/reports/new/service-report',
    },
    VisitReport: {
      label: 'Visit Report',
      icon: 'fa-file-invoice',
      path: '/app/reports/new/visit-report',
    },
    OperatorLogs: {
      label: 'Operator Logs',
      icon: 'fa-file-lines',
      path: '/app/reports/new/operator-logs',
    },
    LabReport: {
      label: 'Lab Report',
      icon: 'fa-flask-vial',
      path: '/app/reports/new/lab-report',
    },
    SetupServiceReport: {
      label: 'Service Report',
      icon: 'fa-file-signature',
      path: '/app/reports/setup/service-report',
      type: ReportType.ServiceReport,
    },
    SetupVisitReport: {
      label: 'Visit Report',
      icon: 'fa-file-invoice',
      path: '/app/reports/setup/visit-report',
      type: ReportType.VisitReport,
    },
    SetupOperatorLogs: {
      label: 'Operator Logs',
      icon: 'fa-file-lines',
      path: '/app/reports/setup/operator-logs',
      type: ReportType.OperatorLog,
    },
    SetupLabReport: {
      label: 'Lab Report',
      icon: 'fa-flask-vial',
      path: '/app/reports/setup/lab-report',
      type: ReportType.LabReport,
    },
    SetupControllers: {
      label: 'features.controllers.title',
      icon: 'fa-robot',
      path: '/app/controllers',
    },
  },
  tools: {
    SystemComponents: {
      label: 'features.tools.test-components',
      icon: 'fa-flask-vial',
      path: '/app/components',
    },
    SystemTypes: {
      label: 'features.tools.system-types',
      icon: 'fa-hard-drive',
      path: '/app/system-types',
    },
    CouponTypes: {
      label: 'features.tools.coupon-types',
      icon: 'fa-tags',
      path: '/app/coupon-types',
    },
    Inventory: {
      label: 'features.tools.products-and-inventory',
      icon: 'fa-shelves',
      path: 'app/inventory',
    },
    ManageChecklists: {
      label: 'features.tools.manage-checklists',
      icon: 'fa-list-check',
      path: '/app/checklists',
    },
    AttachmentManager: {
      label: 'features.tools.attachment-manager',
      icon: 'fa-paperclip',
      path: '/app/attachment-manager',
    },
    OrderRequest: {
      label: 'features.tools.order-request',
      icon: 'fa-cart-plus',
      path: '/app/order-request',
    },
    CustomerProductsLocator: {
      label: 'features.tools.customer-products-locator',
      icon: 'fa-cart-flatbed',
      path: '/app/customer-products-locator',
    },
    CloneCustomer: {
      label: 'features.tools.clone-customer',
      icon: 'fa-people-arrows',
      path: '/app/clone-customer',
    },
    NotificationCenter: {
      label: 'features.tools.notification-center',
      icon: 'fa-bell',
      path: '/app/notification-center',
    },
    CustomerContacts: {
      label: 'features.tools.customer-contacts',
      icon: 'fa-contact-card',
      path: '/app/customer-contacts',
    },
    ListProducts: {
      label: 'features.tools.list-products',
      icon: 'fa-list-squares',
      path: '/app/products',
    },
    GlobalAttachments: {
      label: 'features.tools.global-attachments',
      icon: 'fa-paperclip',
      path: '/app/global-attachments',
    },
    CustomTags: {
      label: 'features.tools.custom-tags',
      icon: 'fa-tags',
      path: '/app/custom-tags',
    },
    ManageTypes: {
      label: 'features.tools.manage-types',
      icon: '',
      path: '/app/manage-types',
    },
    TestTypes: {
      label: 'features.tools.test-types',
      icon: 'fa-vial',
      path: '/app/test-types',
    },
    InternationalSettings: {
      label: 'features.tools.international-settings',
      icon: 'fa-globe',
      path: '/app/settings/international',
    },
    ApplicationSettings: {
      label: 'features.tools.application-settings',
      icon: 'fa-sliders',
      path: '/app/settings',
    },
    AliquotPro: {
      label: 'features.tools.aliquot-pro',
      icon: '',
      path: '',
    },
    ReportPhrases: {
      label: 'features.tools.report-phrases',
      icon: 'fa-comment-lines',
      path: '/app/report-phrases',
    },
    QuestionnaireList: {
      label: 'features.tools.questionnaire-list',
      icon: 'fa-comments-question-check',
      path: '/app/questionnaires',
    },
  },
  graphs: {
    TrendsReport: {
      label: 'features.graphs.trends',
      icon: 'fa-chart-line',
      path: '/app/trends-report',
    },
    SystemHistory: {
      label: 'features.graphs.system',
      icon: 'fa-history',
      path: '/app/system-history',
    },
    InventorySummary: {
      label: 'features.graphs.inventory',
      icon: 'fa-boxes-packing',
      path: '/app/inventory-summary',
    },
    ComponentReview: {
      label: 'features.graphs.component',
      icon: 'fa-boxes-alt',
      path: '/app/component-review',
    },
    TimeManagement: {
      label: 'features.graphs.time-management',
      icon: 'fa-business-time',
      path: '/app/time-management-report',
    },
    CustomerReviewReport: {
      label: 'features.graphs.customer-review',
      icon: 'fa-users-rectangle',
      path: '/app/customer-review',
    },
    OpenDraftReport: {
      label: 'features.graphs.open-drafts',
      icon: 'fa-file-circle-exclamation',
      path: '/app/draft-reports',
    },
    MasterControllerReport: {
      label: 'features.graphs.master-controller',
      icon: 'fa-network-wired',
      path: '/app/master-controller-report',
    },
    TestScheduler: {
      label: 'features.graphs.test-schedule',
      icon: '',
      path: '/app/test-scheduler',
    },
    ReportScheduler: {
      label: 'features.graphs.report-scheduler',
      icon: 'fa-calendar-days',
      path: '/app/report-scheduler',
    },
    CustomerCompliance: {
      label: 'features.graphs.customer-compliance',
      icon: 'fa-user-check',
      path: '/app/customer-compliance',
    },
  },
  users: {
    ManageUsers: {
      label: 'features.users.manage',
      icon: 'fa-users-gear',
      path: '/app/users',
    },
    ManageClientUsers: {
      label: 'features.users.manage',
      icon: '',
      path: '/app/users/client/$clientId',
    },
    ManageCustomerUsers: {
      label: 'features.users.manage',
      icon: '',
      path: '/app/users/customer/$customerId',
    },
    UserPermissions: {
      label: 'features.users.permissions',
      icon: 'fa-user-shield',
      path: '/app/user-templates',
    },
  },
  other: {
    Home: {
      label: 'titles.home',
      path: '/app/home',
      icon: 'fa-home',
    },
    SetupReports: {
      label: 'features.reports.report-setup',
      icon: 'fa-wrench',
      path: '/app/reports/setup',
    },
    AdminCenter: {
      label: 'titles.admin-center',
      icon: 'fa-users',
      path: '/app/admin-center',
    },
    Graphs: {
      label: 'titles.graphs',
      path: '/app/graphs',
      icon: 'fa-chart-pie',
    },
    Tools: {
      label: 'titles.tools',
      path: '/app/tools',
      icon: 'fa-screwdriver-wrench',
    },
    UserManagement: {
      label: 'titles.user-management',
      path: '/app/user-management',
      icon: 'fa-user',
    },
  },
};

export { AppActions };
