import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryGraphComponent } from './inventory-graph.component';
import { FusionChartsModule } from 'angular-fusioncharts';

@NgModule({
  declarations: [InventoryGraphComponent],
  imports: [CommonModule, FusionChartsModule],
  exports: [InventoryGraphComponent],
})
export class InventoryGraphModule {}
