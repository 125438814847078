import { Directive } from '@angular/core';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { Customer } from '../../feature-modules/customers/models/customer.model';
import { AppService } from '../services/app/app.service';

@Directive({
  selector: '[customer-node]',
})
class CustomerNode {
  constructor(
    private component: BreadcrumbsComponent,
    private appService: AppService
  ) {
    this.appService.viewState.customer.subscribe((customer: Customer | null) => {
      if (customer) {
        // Add Customer Breadcrumb
        this.component.defaultNodes.unshift({
          label: customer.name,
          path: '/app/customer',
        });
      }
    });
  }
}

export { CustomerNode };
