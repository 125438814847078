<div class="d-flex">
  <mat-form-field appearance="outline" class="mr-2">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-select [(ngModel)]="selection" (selectionChange)="dateRangeSelected($event)">
      <mat-option *ngFor="let option of timeFilterOptions" [value]="option">{{
        option.label | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngIf="toggleCustomDates"
    class="reportDateSelect"
    appearance="outline"
    [style.width.rem]="width"
  >
    <mat-label>Date Range</mat-label>
    <mat-date-range-input [rangePicker]="dateFormPicker">
      <input matStartDate placeholder="Start date" [(ngModel)]="from" />
      <input matEndDate placeholder="End date" [(ngModel)]="to" />
    </mat-date-range-input>
    <!--mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint//-->
    <mat-datepicker-toggle matSuffix [for]="dateFormPicker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #dateFormPicker
      (closed)="dateSelected()"
    ></mat-date-range-picker>
  </mat-form-field>
</div>
