import { Injectable } from "@angular/core";
import { ShortlongDateFormatClientService } from "./shortlongDateFormatClient.service";

@Injectable({
  providedIn: 'root'
})

  export class ShortFormatForDatePicker {
    returnFormate: string | null;
    constructor(private shortRangeDatePickerFormat : ShortlongDateFormatClientService) 
    {}
    get display() {
      this.returnFormate = this.shortRangeDatePickerFormat.getShortDateFormatAsPerClientSelection();
      return  {
            dateInput: this.returnFormate != null ? this.returnFormate : "MM/dd/yyyy",
            monthYearLabel: 'MMM yyyy',
            dateA11yLabel: this.returnFormate,
            monthYearA11yLabel: 'MMMM yyyy',
          }
    }
    get parse() {
      return {
        dateInput: this.returnFormate != null ? this.returnFormate : "MM/dd/yyyy",
      }
    }
  }