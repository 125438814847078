import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ComponentsModule } from '../components/components.module';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { UserMenuComponent } from './nav/user-menu/user-menu.component';
import { NotifyMenuComponent } from './nav/notify-menu/notify-menu.component';
import { ListDashboardsModule } from '../features/dashboards/list-dashboards/list-dashboards.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LangChangeEvent, TranslateService, TranslateStore } from '@ngx-translate/core';
import { LanguagesSupported } from '../core/constants/languages/languages.constant';
import { AppService } from '../core/services/app/app.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    SidenavComponent,
    FullLayoutComponent,
    UserMenuComponent,
    NotifyMenuComponent,
    LoadingSpinnerComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ListDashboardsModule,
    HttpClientModule,
  ],
  providers: [TranslateStore],
  exports: [NavComponent, FooterComponent, SidenavComponent],
})
export class LayoutModule {
  constructor(
    private translate: TranslateService,
    private appService: AppService
  ) {
    translate.addLangs(LanguagesSupported);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      translate.use(event.lang);
    });
    translate.setDefaultLang('en_us');

    this.appService.languageChange.subscribe((languageCode) => {
      translate.use(languageCode);
    });
  }
}
