<div>
  <button mat-icon-button [matMenuTriggerFor]="userMenu" class="user-profile-icon-button">
    <mat-icon>person</mat-icon>
  </button>
</div>
<div style="position: relative">
  <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu">
    <div class="user-info">
      <div class="circle">
        <img [src]="'/assets/images/guestavatar.webp'" />
      </div>
      <div class="text-center">
        <span>{{ user.firstName + ' ' + user.lastName }}</span>
      </div>
    </div>
    <button
      mat-menu-item
      *ngFor="let item of options"
      class="text-center"
      [routerLink]="[item.path, item.pathVariables || {}]"
    >
      <i class="fa {{ item.icon }} mr-2"></i>
      <span>{{ item.label | translate }}</span>
    </button>
    <button mat-menu-item class="text-center" [routerLink]="'/logout'">
      <i class="fa fa-right-from-bracket mr-2"></i>
      <span>{{ 'actions.logout' | translate }}</span>
    </button>
    <div class="version-container">
      <img src="assets/images/aliquot-logo.png" alt="Aliquot Logo" class="aliquot-logo" />
      <p class="version">v: {{ version }}</p>
    </div>
  </mat-menu>
</div>
