import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

interface NotifyMessages {
  messages: any[];
}

@Component({
  selector: 'app-notify-menu',
  templateUrl: './notify-menu.component.html',
  styleUrls: ['./notify-menu.component.scss'],
})
export class NotifyMenuComponent implements OnInit {
  messages$: Observable<NotifyMessages>;
  messages: any[] = [];
  counter = 0;

  constructor() {
    //private notificationsService: NotificationsService
    //this.messages$ = this.notificationsService.messages$;
    //this.notificationsService.subscribe();
  }

  ngOnInit(): void {}

  sendMessage(_msg: string) {}
}
