<div [style.width.px]="_config.width ?? 490" class="file-upload">
    <div class="alert alert-dismissable alert-danger" *ngIf="errors.length" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <p *ngFor="let error of errors">{{ error }}</p>
    </div>
    <div class="uploadContainer" appDragAndDrop (filesDropped)="onFilesDropped($event)">
        <h3 class="header">
            {{ _config.multiple ? ('messages.add-attachment.drag-and-drop-files' | translate) : ('messages.add-attachment.drag-and-drop-file' | translate)}}
        </h3>
        <p class="text">
            {{ _config.multiple ? ('messages.add-attachment.select-files' | translate) : ('messages.add-attachment.select-file' | translate) }}
        </p>
        <button mat-stroked-button type="button" color="primary" (click)="fileInput.click(); errors = []">
            {{ _config.multiple ? ('actions.add-files' | translate) : ('actions.add-file' | translate) }}
        </button>
        <input
            [accept]="acceptKeys" 
            class="fileInput" #fileInput hidden (change)="generateFileURI($event)" [multiple]="_config.multiple" type="file">
    </div>
</div>
