import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumbNode } from 'src/app/components/breadcrumbs/breadcrumb-node.model';
import { AppResponse } from 'src/app/core/models/app-response/app-response.model';

@Component({
  selector: 'app-list-dashboards',
  templateUrl: './list-dashboards.component.html',
  styleUrls: ['./list-dashboards.component.scss'],
})
export class ListDashboardsComponent implements OnInit {
  dashboards$: Observable<AppResponse>;

  @Input()
  miniMode = false;

  dashboards = [];

  breadcrumbs: BreadcrumbNode[] = [
    {
      label: 'titles.my-dashboards',
    },
  ];

  constructor() {
    //this.dashboards$ = this.dashboardService.getDashboards();
  }

  ngOnInit(): void {}
}
