import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, fromEvent, lastValueFrom, map } from 'rxjs';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';

import { environment } from 'src/environments/environment';
import {
  AppService,
  StorageKeyToken,
  StorageKeyUserPermissions,
} from './core/services/app/app.service';
import { ErrorService } from './core/services/error/error.service';
import { LoadingSpinnerComponent } from './layouts/loading-spinner/loading-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './core/auth/auth/auth.service';
import { AuthStatus } from './core/auth/auth-status/auth-status.model';
import { AccountService } from './public/account/services/account-service/account.service';

import { EntitySelectComponent } from './components/entity-select/entity-select.component';
import { User } from './feature-modules/users/models/user.model';
import { APSObject, CustomThemes } from './types/globals.types';
import {
  DefaultLoginLogo,
  DefaultLogo,
} from 'src/app/core/constants/default-logo/default-logo.constant';
import {
  DOMAIN_VALIDATION_QUERY,
  DomainValidation,
  defaultThemeObject,
} from './gql/auth/domain.queries';
import { GQLResponse } from './core/services/graphql/graphql.apollo.service';
import { writeCustomTheme } from './helpers/dom.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'esr-app-test';

  menuToggle = false;
  menuMinimize = false;
  menuExpand = false;
  loadingToggle = false;

  selectedTheme = 0;
  selectedText = '';
  alertText = '';
  css = '';
  curPage = '';
  accountId = 0;

  hasMenu = false;
  logoPath = '';
  loggedIn = false;
  sessionExtensionDebounceMs = 5000;

  version = environment.version || '';

  @ViewChild(EntitySelectComponent) entitySelectComponent: EntitySelectComponent;

  showEntitySelect = false;
  entitySelectPaths = [
    '/app/attachment-manager',
    '/app/home',
    '/app/client',
    '/app/reports',
    '/app/reports/new',
    '/app/controllers',
    '/app/customer-review',
    '/app/customer-review/new',
    '/app/reports/setup',
    '/app/report-phrases',
    '/app/report-phrases/categories',
    '/app/report-templates',
    '/app/draft-reports',
    '/app/customer',
    '/app/checklists',
    '/app/facility',
    '/app/building',
    '/app/report-scheduler',
    '/app/system',
    '/app/trends-report',
    '/app/system-history-report',
    '/app/components',
    '/app/components-layout',
    '/app/system',
    '/app/system-history',
    '/app/inventory',
    '/app/products',
    '/app/notification-center',
    '/app/questionnaires',
  ];
  domain = '';
  domainTheme: APSObject;
  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private overlay: Overlay,
    private errorService: ErrorService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private accountService: AccountService,
    private renderer: Renderer2,
    public materialCssVarsService: MaterialCssVarsService,
    private graphService: GraphqlService
  ) {
    const currentURI = window.location;
    if (currentURI && currentURI.hostname) {
      this.domain =
        currentURI.hostname.toLowerCase() === 'localhost'
          ? 'staging.aliquot.live'
          : currentURI.hostname.toLowerCase();
    }

    (async () => {
      this.domainTheme = await this.getDomainTheme();
      if (this.domainTheme && typeof this.domainTheme.themes === 'string') {
        if (this.domainTheme && this.domainTheme.themes?.indexOf('{') > -1) {
          try {
            this.domainTheme.themes = JSON.parse(this.domainTheme.themes) as CustomThemes;
          } catch {
            /** */
          }
        } else {
          this.domainTheme.themes = JSON.parse(defaultThemeObject.themes) as CustomThemes;
        }
      }

      if (this.domainTheme && this.domainTheme.accountImages.length === 0) {
        this.domainTheme.accountImages = [...defaultThemeObject.accountImages];
      }
      this.domainTheme.mainLogo =
        this.domainTheme.accountImages.find((item: APSObject) => {
          return item.description.toLowerCase() === 'logocompany';
        })?.file || DefaultLogo;

      this.domainTheme.loginLogo =
        this.domainTheme.accountImages.find((item: APSObject) => {
          return item.description.toLowerCase() === 'logologin';
        })?.file || DefaultLoginLogo;
      writeCustomTheme(this.domainTheme.themes);
      this.setLoadingColor();

      localStorage.setItem('aps-theme', JSON.stringify(this.domainTheme));
      this.materialCssVarsService.isAutoContrast = false;
      this.materialCssVarsService.setDarkTheme(false);
      this.materialCssVarsService.setPrimaryColor(this.domainTheme.themes.primary);
      this.materialCssVarsService.setAccentColor(this.domainTheme.themes.secondary);

      this.setDefaultLogo();

      this.setTranslations();
      this.watchSidenav();
      this.watchRoutes();
      this.createSpinner();
      this.watchErrors();
      this.watchLogin();
      this.checkExistingSession();
      this.watchLanguageChange();
    })();
  }

  getDomainTheme(): Promise<DomainValidation> {
    return lastValueFrom(
      this.graphService
        .query<{ domainValidation: DomainValidation }>({
          query: DOMAIN_VALIDATION_QUERY,
          variables: {
            domain: this.domain,
          },
          isPublic: true,
        })
        .pipe(
          map((res: GQLResponse<{ domainValidation: DomainValidation }>) => {
            if (!res.data || !res.data.domainValidation) {
              return defaultThemeObject;
            }

            if (res.data && !res.data.domainValidation.themes) {
              return defaultThemeObject;
            }

            return res.data.domainValidation;
          })
        )
    )
    .catch(() => {
      return defaultThemeObject;
    });
  }

  setDefaultLogo() {
    this.logoPath = DefaultLogo;
  }

  watchLanguageChange() {
    this.appService.languageChange.subscribe((languageCode: string) => {
      this.translate.use(languageCode);
    });
  }

  // Refresh cookies
  watchClicks() {
    const clicks = fromEvent(document, 'click');
    const result = clicks.pipe(debounceTime(this.sessionExtensionDebounceMs));
    const delayMs = 5000;

    // Delay subscribe to avoid interferring with the Login procedure.
    setTimeout(() => {
      result.subscribe((_x) => {
        const token: APSObject = localStorage.getItem(StorageKeyToken);
        const permissionString: APSObject = localStorage.getItem(
          StorageKeyUserPermissions
        );

        if (token !== 'undefined') {
          this.authService.setCookie(StorageKeyToken, token);
        }
        if (permissionString !== 'undefined') {
          this.authService.setCookie(StorageKeyUserPermissions, permissionString);
        }
      });
    }, delayMs);
  }

  checkExistingSession() {
    this.authService.checkEnv();
  }

  applyAppSettings() {
    const accountSettings = this.accountService.accountSettings.getValue();
    const appSettings = accountSettings.appSettings;
    const style = this.renderer.createElement('style');

    this.selectedTheme = appSettings.customization?.alertType || 0;

    this.alertText = appSettings.customization?.alertMessage || '';
    this.css = appSettings.customization?.css || '';

    style.type = 'text/css';
    style.appendChild(this.renderer.createText('/* Custom CSS */'));
    style.appendChild(this.renderer.createText(this.css));
    style.appendChild(this.renderer.createText('/* Custom CSS */'));
    this.renderer.appendChild(document.head, style);
  }

  getImages() {
    // Subscribe - values arrive late
    // Destroy the subscription? The component is only destroyed on reload.
    this.accountService.companyLogos.subscribe((imageObj) => {
      if (imageObj && imageObj['logoCompany']) {
        this.logoPath = imageObj['logoCompany'].file || DefaultLogo;
      } else {
        this.setDefaultLogo();
      }
    });
  }

  watchLogin() {
    this.authService.status.pipe(debounceTime(300)).subscribe((status: AuthStatus) => {
      const user = status.user;
      this.hasMenu =
        !!status.hasSession && !user?.passwordResetRequired && !!user?.accountId;
      this.menuToggle =
        !!status.hasSession && !user?.passwordResetRequired && !!user?.accountId;

      if (status.hasSession && user) {
        if (user.accountId) {
          this.loggedIn = true;
          this.getImages();
          this.accountService.getAccountSettings(user.accountId).then(() => {
            this.applyAppSettings();
          });
          this.accountService.getAccountImages(user.accountId);
          if (status.intercomHash) {
            this.setIntercom(user, status.intercomHash);
          }
          this.watchClicks();
          if (this.entitySelectComponent) {
            this.entitySelectComponent.checkUserAccess();
          }
        } else {
          this.loggedIn = false;
          this.setDefaultLogo();
        }
      } else {
        this.loggedIn = false;
        this.setDefaultLogo();
      }
    });
  }

  setIntercom(user: User, intercomHash: string) {
    const intercomSettings = window['intercomSettings'];
    if (!intercomSettings) {
      return;
    }
    intercomSettings.user_id = user.id || '';
    intercomSettings.user_hash = intercomHash;

    window['Intercom']('boot', {
      app_id: 'cjw9ii1v',
      email: user.email || '',
      user_id: user.id || '',
      name: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : '',
      phone: user.phoneNumber || '',
      company: { company_id: '1', name: 'Test Company' }, // This is going to be addressed in a later ticket
    });
  }

  watchErrors() {
    this.errorService.displayErrors.asObservable().subscribe((errors: any[]) => {
      if (errors) {
        errors.forEach((er) => {
          this.toastrService.error(er);
        });
      }
    });
  }

  watchRoutes() {
    if (this.router.url.toLowerCase() === '/login') {
      this.loggedIn = false;
    }

    this.router.events
      .pipe(filter((val) => val instanceof NavigationEnd))
      .subscribe((ev) => {
        ev = ev as NavigationEnd;
        this.curPage = ev.url;
        this.showEntitySelect = !!this.entitySelectPaths.includes(ev.url);
        this.errorService.clear();
        if (ev.url.split('/').includes('app')) {
          this.appService.sidenavToggle.emit(true);
        }
      });
  }

  watchSidenav() {
    this.appService.sidenavToggle.asObservable().subscribe((val) => {
      if (val) {
        this.menuToggle = val;
      }
    });
  }

  setTranslations() {
    this.translate.addLangs(['en_us']);
    this.translate.setDefaultLang('en_us');
    //this.translate.use('en_us');
  }

  createSpinner() {
    const overlayRef = this.overlay.create();
    const spinner = new ComponentPortal(LoadingSpinnerComponent);
    overlayRef.attach(spinner);
  }

  onMenuExpand(val: boolean) {
    this.menuExpand = val;
  }

  onMenuToggle(val: boolean) {
    this.menuMinimize = val;
  }

  onInit() {}

  ngAfterViewInit() {
    this.hideLoadingScreen();
  }

  setLoadingColor(): void {
    const loadingScreen = this.renderer.selectRootElement('#main_loading_screen', true);

    if (loadingScreen) {
      if (this.domainTheme) {
        this.renderer.addClass(loadingScreen, 'use-theme-colors');
      }
    }
  }

  hideLoadingScreen(): void {
    const loadingScreen = this.renderer.selectRootElement('#main_loading_screen', true);
    if (loadingScreen) {
      setTimeout(() => {
        this.renderer.setStyle(loadingScreen, 'display', 'none');
      }, 1000);
    }
  }
}
