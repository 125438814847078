<mat-card class="small-card">
  <h2>{{ 'titles.password-reset' | translate }}</h2>

  <div>
    <form class="password-reset-form" (ngSubmit)="submit()">
      <p>
        <mat-form-field appearance="outline">
          <input
            matInput
            email
            autocomplete="on"
            placeholder="{{ 'labels.email' | translate }}"
            [formControl]="email"
            name="email"
          />
        </mat-form-field>
      </p>

      <div class="text-center">
        <a href [routerLink]="'/login'">{{ 'actions.return-to-login' | translate }}</a>
      </div>

      <div class="text-right">
        <button
          mat-raised-button
          type="submit"
          [disabled]="!email.valid || submitted"
          color="primary"
        >
          {{ 'actions.submit' | translate }}
        </button>
      </div>
    </form>
  </div>
</mat-card>
