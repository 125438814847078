import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[setFocus]',
})
export class FocusDirective implements OnInit {
  @Input('myFocus') isFocused: boolean;

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.isFocused) {
      this.hostElement.nativeElement.focus();
    }
  }
}
