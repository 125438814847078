import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss'],
})
export class StickyHeaderComponent implements OnInit {
  stickyActive = false;

  constructor() {}

  ngOnInit(): void {
    this.setClass();
  }

  @HostListener('window:scroll', ['$event']) onScroll(_event: Event) {
    this.setClass();
  }

  setClass() {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (verticalOffset > 65) {
      this.stickyActive = true;
    } else {
      this.stickyActive = false;
    }
  }
}
