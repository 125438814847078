import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectedTime } from 'src/app/core/models/selected-time/selected-time.model';
import { Timezone } from 'src/app/core/models/timezones/timezone.model';

export type Time = {
  hour: string | number;
  minute: string | number;
  anteMeridiem: string;
};

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit {
  @Input() time = '';
  @Input() imperial = false;
  @Input() showTimezone = true;
  @Output() changedTime = new EventEmitter<Time>();

  _timezone: Timezone | undefined;
  timeForm: FormGroup;
  hours: string[];
  minutes: string[];

  private changesSub: Subscription;
  // Match time strings in the following format: hh:mm[ AM|PM|am|pm]
  private timeRegex = /[0-9]{2}:[0-9]{2}[ ]{0,1}[PpAa]{0,1}[Mm]{0,1}/;

  constructor(private fb: FormBuilder) {
    this.createForm();
    this.watchFormChanges();
  }

  ngOnInit(): void {}

  @Input()
  set timezone(val: Timezone) {
    this._timezone = val;
  }

  @Input()
  set selectedTime(val: string) {
    this.validateTime(val, true);
  }

  ngOnChanges() {
    const validatedTime = this.validateTime(this.time, this.imperial);

    this.timeForm.patchValue(validatedTime);
  }

  ngOnDestroy() {
    if (this.changesSub) {
      this.changesSub.unsubscribe();
    }
  }

  watchFormChanges() {
    this.changesSub = this.timeForm.valueChanges.subscribe((values) => {
      // Handles brief init before pulling in the current time
      if (values.hour === '00') {
        return;
      }

      let hour = +values.hour;

      if (values.imperial) {
        hour = hour > 12 ? hour - 12 : hour;
        const hourValue = hour > 9 ? '' + hour : '0' + hour;

        this.timeForm.patchValue({ hour: hourValue }, { emitEvent: false });

        if (values.anteMeridiem) {
          const hour24 = hour > 12 ? hour + 12 : hour === 12 ? 0 : hour;
          const hourValue24 = hour24 > 9 ? '' + hour24 : '0' + hour24;

          this.changedTime.emit({
            hour: hourValue24,
            minute: values.minute,
            anteMeridiem: values.anteMeridiem,
          });
        } else {
          this.changedTime.emit({
            hour: values.hour,
            minute: values.minute,
            anteMeridiem: values.anteMeridiem,
          });
        }
      } else {
        this.changedTime.emit({
          hour: values.hour,
          minute: values.minute,
          anteMeridiem: values.anteMeridiem,
        });
      }

      this.hours = values.imperial
        ? [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
          ]
        : [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
          ];

      this.minutes = Array.from({ length: 60 }, (_, i) => {
        return i < 10 ? `0${i}` : `${i}`;
      });
    });
  }

  private createForm() {
    const selectedTime = this.validateTime(this.time, this.imperial);
    this.timeForm = this.fb.group(selectedTime);
  }

  private validateTime(time: string, imperial: boolean): SelectedTime {
    const selectedTime = {
      imperial,
      hour: '00',
      minute: '00',
      anteMeridiem: false,
    } as SelectedTime;

    // Check if 'time' is defined
    if (time) {
      // Remove any spaces and convert to lowercase
      const cleanedTime = time.replace(/\s+/g, '').toLowerCase();

      const timeValues = this.timeRegex.exec(cleanedTime);
      if (timeValues && timeValues.length > 0) {
        const timeValue = timeValues[0];
        selectedTime.hour = timeValue.slice(0, 2);
        const splitIndex = timeValue.indexOf(':');
        let minutePart = timeValue.slice(splitIndex + 1, splitIndex + 3);

        // Check if the minute part is single digit and prepend a leading zero
        if (minutePart.length === 1) {
          minutePart = `0${minutePart}`;
        }

        selectedTime.minute = minutePart;

        // Check if "a" or "p" is present in the time input
        if (timeValue.includes('a')) {
          selectedTime.anteMeridiem = true;
        } else if (timeValue.includes('p')) {
          selectedTime.anteMeridiem = false;
        }
      }
    }

    return selectedTime;
  }
}
