<mat-icon>filter_list</mat-icon>
<mat-button-toggle-group #group="matButtonToggleGroup">
    <mat-button-toggle value="all" >
      <!--mat-icon>format_align_left</mat-icon-->
      {{ 'titles.all' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="reports">
      {{ 'titles.reports.list' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="graphs">
      {{ 'titles.graphs' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="tools" >
      {{ 'titles.tools' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="users" >
        {{ 'titles.users.list' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="controllers" >
        {{ 'titles.controllers' | translate }}
      </mat-button-toggle>
</mat-button-toggle-group>