import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordResetRoutingModule } from './password-reset-routing.module';
import { PasswordResetComponent } from './password-reset.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [PasswordResetComponent],
  imports: [CommonModule, ComponentsModule, PasswordResetRoutingModule],
})
export class PasswordResetModule {}
