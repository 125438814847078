import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./features/features.module').then((m) => m.FeaturesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/client',
    loadChildren: () =>
      import('./feature-modules/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/customer',
    loadChildren: () =>
      import('./feature-modules/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/trends-report',
    loadChildren: () =>
      import('./feature-modules/trends-report/trends-report.module').then(
        (m) => m.TrendsReportModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/users',
    loadChildren: () =>
      import('./feature-modules/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/questionnaires',
    loadChildren: () =>
      import('./feature-modules/questionnaire-list/questionnaire-list.module').then(
        (m) => m.QuestionnaireListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/controllers',
    loadChildren: () =>
      import('./feature-modules/controllers/controllers.module').then(
        (m) => m.ControllersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/reports',
    loadChildren: () =>
      import('./feature-modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/components',
    loadChildren: () =>
      import('./feature-modules/system-components/system-components.module').then(
        (m) => m.SystemComponentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/customer-review',
    loadChildren: () =>
      import('./feature-modules/customer-review/customer-review.module').then(
        (m) => m.CustomerReviewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
