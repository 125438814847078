import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  allErrors: Subject<any[]> = new Subject<any[]>();
  displayErrors: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  errors$ = this.displayErrors.asObservable();
  allErrors$ = this.allErrors.asObservable();

  constructor() {}

  log(e: any, display?: boolean) {
    let errors: string[] = [];
    if (typeof e === 'string') {
      errors.push(e);
    } else if (e instanceof Array) {
      errors = e.map((err) => err.message);
    } else if (e instanceof HttpErrorResponse) {
      if (e.error.errors) {
        errors = [...e.error.errors.map((er: any) => er.message)];
      } else if (e.error) {
        errors = [e.error.message];
      }
    } else {
      errors = [e.message];
    }
    this.allErrors.next(errors);
    if (display) {
      this.displayErrors.next(errors);
    }
    errors.forEach(() => {
      //throw new Error(er);
    });
  }

  clear() {
    this.displayErrors.next([]);
  }
}
