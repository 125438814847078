import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

import { map, Observable, startWith } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import {
  BlanketAccess,
  EditViewAccess,
} from 'src/app/core/models/permissions/permissions.model';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';

/**
 * @description The Entity List component displays a select box for selecting a given Entity, along with
 * a search box and a button for adding a new Entity. It communicates to and from the parent component, EntitySelect.
 */

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class EntityListComponent implements OnInit {
  _options: EntityOption[] = [];
  _label = '';
  _createPerm: BlanketAccess;
  _accessPerm: EditViewAccess;
  _selected: EntityOption | null = null;

  @Input() selectLabel = '';
  @Input() path = '';
  @Input() disabled = false;
  @Input() readonly = false;

  @Output() onSelect = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @ViewChild(MatSelect, { static: true }) select: MatSelect | undefined;

  searchControl = new FormControl();
  previous: EntityOption | null = null;

  filteredOptions$: Observable<EntityOption[]>;

  dashIcon = faRightToBracket;

  isSelected = false;
  showItemId = false;
  dialogOpenState = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isSelected = !!this.selected;
    if (this.select) {
      this.select._overlayPanelClass = 'dropdown-panel';
    }
    this.watchSearchControl();
  }

  @Input()
  set createPerm(val: BlanketAccess) {
    this._createPerm = val;
  }

  @Input()
  set accessPerm(val: EditViewAccess) {
    this._accessPerm = val;
  }

  @Input()
  set options(val: EntityOption[]) {
    this._options = val;
    this.searchControl.patchValue('');
  }

  get options() {
    return this._options;
  }

  @Input()
  set label(val: string) {
    this._label = val;
    if (val === 'labels.system') {
      this.showItemId = true;
    }
  }

  get label() {
    return this._label;
  }

  @Input()
  set selected(val: any) {
    this._selected = val;
    if (val) {
      if (['ADD', 'EDIT', 'DELETE'].indexOf(val) >= 0) {
        // Wait a cycle to reset the value
        setTimeout(() => {
          this.selected = this.previous ? ({ ...this.previous } as EntityOption) : null;
        });
      } else {
        this.isSelected = true;
        this.previous = this.selected;
      }
    } else {
      this.isSelected = false;
      this.previous = null;
    }
  }

  get selected() {
    return this._selected;
  }

  ngAfterViewInit() {
    if (this.select && this.label === 'labels.system') {
      this.select._positions = [
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ];
    }
  }

  watchSearchControl() {
    this.filteredOptions$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((val) => this.filterOptions(val))
    );
  }

  filterOptions(val: string): EntityOption[] {
    if (val) {
      let results: any = [];
      const filterValue = val.toLowerCase();
      results = this.options.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
      return results;
    }
    return this.options;
  }

  onSelectToggle(opened: boolean) {
    if (opened === false) {
      this.searchControl.patchValue('');
    }
  }

  selectCompare(c1: EntityOption, c2: EntityOption): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  doSelect() {
    // Three options are not actual selections, ignore them
    // They are mat-options for keyboard access
    if (['ADD', 'EDIT', 'DELETE'].indexOf(this.selected) == -1) {
      this.onSelect.emit(this.selected);
    }
  }

  close() {
    this.selected = null;
    this.onSelect.emit(null);
  }

  delete() {
    const label = this.translate.instant(this._label || '');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        message: `Are you sure you want to delete this ${label}? `,
      },
      maxWidth: '60rem',
    });

    let sub = dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.onRemove.emit(this.selected);
      }
      sub.unsubscribe();
    });
    //this.isSelected = false;
    //this._options = this._options.filter((option) => option.id !== this.selected?.id);
  }

  openDashboard($event: any) {
    this.select?.close();
    $event.stopPropagation();
    this.router.navigate([`/app/${this.path}`]);
  }

  createSelect(_$event: any) {
    if (this.select?.panelOpen) {
      this.select?.close();
      this.router.navigate([`/app/${this.path}/new`]);
    }
  }

  modifySelect(_$event: any) {
    if (this.select?.panelOpen) {
      this.select?.close();
      this.router.navigate([`/app/${this.path}/${this.selected?.id}/edit`]);
    }
  }
}
