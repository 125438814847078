<div class="filter-by-entity-wrapper container-fluid">
    
    <div class="d-flex flex-wrap filter-by-entity">
        <app-entity-list [readonly]="true" *ngIf="fieldControl.client" [selected]="selections.client | async" [options]="dataOptions.client"
            [selectLabel]="labels.initialSelect" [label]="labels.initial" [path]="'client'"
            (onSelect)="onClientSelect($event)"></app-entity-list>

        <app-entity-list [readonly]="true" id="entity-select-customer" [disabled]="!(selections.client | async)" *ngIf="fieldControl.customer" [hidden]="!(selections.client | async) || !fieldControl.customer" [selected]="selections.customer | async"
            [options]="dataOptions.customer" [selectLabel]="'actions.select-customer'" [label]="'labels.customer'"
            [path]="'customer'" (onSelect)="onCustomerSelect($event)" ></app-entity-list>

        <!--app-entity-list id="entity-select-facility" [disabled]="!(selections.customer | async)" [hidden]="!(selections.customer | async) || !fieldControl.facility" [selected]="selections.facility | async"
            [options]="dataOptions.facility" [selectLabel]="'actions.select-facility'" [label]="'labels.facility'"
            [path]="'facility'" (onSelect)="onFacilitySelect($event)" ></app-entity-list>

        <app-entity-list id="entity-select-building" [disabled]="!(selections.facility | async)" [hidden]="!(selections.facility | async) || !fieldControl.building" [selected]="selections.building | async"
            [options]="dataOptions.building" [selectLabel]="'actions.select-building'" [label]="'labels.building'"
            [path]="'building'" (onSelect)="onBuildingSelect($event)" ></app-entity-list>

        <app-entity-list id="entity-select-system" [disabled]="!(selections.building | async)" [hidden]="!(selections.building | async) || !fieldControl.system" [selected]="selections.system | async"
            [options]="dataOptions.system" [selectLabel]="'actions.select-system'" [label]="'labels.system'"
            [path]="'system'" (onSelect)="onSystemSelect($event)" ></app-entity-list-->
    </div>
</div>