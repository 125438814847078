import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-graph',
  templateUrl: './inventory-graph.component.html',
  styleUrls: ['./inventory-graph.component.scss'],
})
export class InventoryGraphComponent implements OnInit {
  graphWidth = 300;
  graphHeight = 190;
  graphConfig = {
    chart: {
      caption: 'Inventory Usage - Chlorine',
      subCaption: 'In mml',
      xAxisName: 'Date',
      yAxisName: 'Reserves (MMbbl)',
      numberSuffix: 'K',
      theme: 'fusion',
    },
    data: [
      { label: '02/12', value: '290' },
      { label: '02/14', value: '260' },
      { label: '02/19', value: '220' },
      { label: '02/23', value: '200' },
      { label: '02/28', value: '195' },
    ],
  };

  constructor() {}

  ngOnInit(): void {}
}
