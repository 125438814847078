<form [formGroup]="timeForm" class="time-fields-container">
  <mat-form-field appearance="outline" class="time-input">
    <mat-select
      formControlName="hour"
      disableOptionCentering
      panelClass="time-select-panel"
    >
      <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="time-input">
    <mat-select formControlName="minute" disableOptionCentering>
      <mat-option *ngFor="let minute of minutes" [value]="minute">{{
        minute
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="timeForm.value.imperial" appearance="outline" class="time-input">
    <mat-select formControlName="anteMeridiem" disableOptionCentering>
      <mat-option [value]="true">AM</mat-option>
      <mat-option [value]="false">PM</mat-option>
    </mat-select>
  </mat-form-field>
  <!--mat-checkbox formControlName="imperial"> 12h</mat-checkbox-->
  <span *ngIf="showTimezone" class="p-1 am-pm">{{ _timezone?.abbreviation }}</span>
</form>
